/*
* pages/admin/quizzes/QuestionsController.jsx
* Author: Rushy Panchal
* Date: August 1st, 2019
* Description: Question collection editing controller.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromPairs, cloneDeep } from 'lodash';

import { Question } from 'lib/api';
import { actions, selectors, types } from 'state/resources';
import { getErrorMsg } from 'lib/utils';
import GenericCollectionEditor from 'components/GenericCollectionEditor';
import QuestionEditor from 'components/models/QuestionEditor';

const QUESTION_LOAD_KEY = 'admin-load-question';
const QUESTION_CREATE_KEY = 'admin-create-question';
const QUESTION_REORDER_KEY = 'admin-reorder-questions';

class QuestionsController extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    quiz_id: PropTypes.number.isRequired,
    };

  state = {
    pendingQuestion: null,
    reorderSaved: true,
    };

  componentDidMount() {
    const { quiz_id } = this.props;
    const action = actions.loadResourceList(
      Question,
      QUESTION_LOAD_KEY,
      {quiz_id: quiz_id});
    this.props.dispatch(action);
    }

  render() {
    const { dispatch, ...other } = this.props;
    return (<GenericCollectionEditor
      editor={QuestionEditor}
      header='Add Question'
      reorderable

      onUpdate={this.onUpdate}
      onSave={this.onSave}
      onDelete={this.onDelete}

      onSaveOrder={this.onSaveOrder}
      reorderSaved={this.state.reorderSaved}
      onReorder={this.onReorder}

      pendingItem={this.state.pendingQuestion}
      onCreate={this.onCreate}
      {...other}
    />);
    }

  onUpdate = (item, create) => {
    if (create) {
      this.setState({pendingQuestion: item});
      return;
      }

    const action = actions.editResource(
      Question,
      item.id,
      item);

    return this.props.dispatch(action);
    }

  onSave = (item) => {
    const action = actions.updateResource(
      Question,
      item.id,
      item);
    return this.props.dispatch(action);
    }

  onDelete = (id) => {
    const action = actions.deleteResource(Question, id);
    return this.props.dispatch(action);
    }

  onCreate = () => {
    let question = cloneDeep(this.state.pendingQuestion);
    question.id = null;
    question.index = Math.max(0, ...this.props.items.map(x => x.index)) + 1;
    question.course_id = this.props.course_id;
    question.quiz_id = this.props.quiz_id;

    const action = actions.createResource(
      Question,
      QUESTION_CREATE_KEY,
      question);

    return this.props.dispatch(action)
      .tap(() => this.setState({pendingQuestion: null}));
    }

  onSaveOrder = () => {
    const resource = new Question();
    const itemOrder = fromPairs(this.props.items.map(q => [q.id, q.index]));

    const p = resource.reorder(
      this.props.course_id, this.props.quiz_id, itemOrder);
    const action = actions.resolvePromise(
      p,
      resource.type,
      QUESTION_REORDER_KEY,
      types.ACTIONS.BULK_UPDATE_RESOURCE);

    return this.props.dispatch(action)
      .tap(() => this.setState({reorderSaved: true}));
    }

  onReorder = (itemIds) => {
    // Dispatch the edits.
    itemIds.forEach((id, index) => {
      const action = actions.editResource(
        Question,
        Number(id),
        {index: index+1});
      this.props.dispatch(action);
      });

    this.setState({reorderSaved: false});
    }
  }

function mapStateToProps(state, ownProps) {
  const { quiz_id } = ownProps;
  const questions = selectors.getResource(state, Question);
  const pending = selectors.getPending(questions, QUESTION_LOAD_KEY);

  const quiz_questions = selectors
    .filterResource( questions, {quiz_id: Number(quiz_id)})
    .sort((a, b) => a.index - b.index);
  const question_saved = fromPairs(
    quiz_questions.map(q => [q.id, selectors.isSaved(questions, q.id)]));

  const createPending = selectors.getPending(questions, QUESTION_CREATE_KEY);
  const reorderPending = selectors.getPending(questions, QUESTION_REORDER_KEY);

  return {
    items: quiz_questions,
    saved: question_saved,
    loading: pending.loading,
    error: getErrorMsg(pending.error || reorderPending.error),

    createLoading: createPending.loading,
    createError: getErrorMsg(createPending.error),

    reorderLoading: reorderPending.loading,
    };
  }

export default connect(mapStateToProps)(QuestionsController);
