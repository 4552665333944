/*
* pages/Dashboard/CourseCard.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Displays a single course.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HiddenComponent from 'components/HiddenComponent.jsx';

function CourseCard(props) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          {props.department} {props.number}&nbsp;
          ({props.term_display} {props.year})
        </Card.Title>
        <Card.Subtitle>{props.title}</Card.Subtitle>
      </Card.Body>
      
      <Card.Footer>
        <Row>
          <Col xs={12} md={props.is_admin ? 6: 12}>
            <Link to={props.link}>
              <Button variant="primary">Open</Button>
            </Link>
          </Col>

          <Col xs={12} md={6}>
            <HiddenComponent hidden={!props.is_admin}>
              <Link to={props.manageLink}>
                <Button variant="dark">Manage</Button>
              </Link>
            </HiddenComponent>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
    );
  }

CourseCard.propTypes = {
  department: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  term_display: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,

  is_admin: PropTypes.bool,
  manageLink: PropTypes.string
  };

CourseCard.defaultProps = {
  is_admin: false,
  manageLink: '',
  };

export default React.memo(CourseCard);
