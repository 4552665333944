/*
* core/static/core/react/core/components/TableList.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Generic list in a table.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class TableList extends React.Component {
	/*
	* A TableList component displays a list of structured data in a table.
	* Note that the table is not just a basic HTML table - it is composed of
	* Bootstrap rows and columns to allow for flexibility.
	* Warning: Using more than 12 columns may lead to undesirable column layout,
	* 	due to Bootstrap's 12-column grid layout.
	*
	* Required Props
	*		Object specification - mapping of fields to display names
	*		[Object] data - data to display
	*
	* Optional Props
	*		[String] order - order of specifications to use
	*		String key - key column to use (overridden by spec. options)
	*		Object options - additional options for table
	*		Object specificationOptions - mapping of fields to options
	*
	* For full options, see:
	*	http://allenfang.github.io/react-bootstrap-table/example.html.
	*/
	render() {
		/* Render the component. */
		var order = this.props.order || Object.keys(this.props.specification),
			keySpec = this.props.key || order[0],
			props = this.props;

		return (
			<div className="table-list"><Row><Col xs={12}>
				<BootstrapTable {...props.options} data={this.props.data}>
					{order.map(function(spec) {
						var options = props.specificationOptions[spec] || {};
						if (options.isKey === null || options.isKey === 'undefined') {
							options.isKey = (keySpec === spec);
							}

						return (<TableHeaderColumn
							dataField={spec}
							key={spec}
							{...options}>
							{props.specification[spec]}
							</TableHeaderColumn>);
						})}
				</BootstrapTable>
			</Col></Row></div>);
		}
	}

TableList.propTypes = {
	/* Required Props */
	specification: PropTypes.object.isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,

	/* Optional Props */
	order: PropTypes.arrayOf(PropTypes.string),
	key: PropTypes.string,
	options: PropTypes.object,
	specificationOptions: PropTypes.object,
	};

TableList.defaultProps = {
	specificationOptions: {},
	};

export default TableList;
