/*
* core/static/react/core/components/LoaderButton.jsx
* Quizzera
* Author: Rushy Panchal
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Loader from './Loader.jsx';

/*
* The LoaderButton component is a composite of a ReactBootstrap.Button and a
* Loader. All non-required props are passed to the button.
*
* Required Props
* 	Boolean loading - whether or not loading is occurring
*/
function LoaderButton(props) {
	let { loading, children, ...other } = props;
	return (
		<Button {...other}>
			{children}
			<Loader loading={loading}/>
		</Button>
		);
	}

LoaderButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  }

export default React.memo(LoaderButton);
