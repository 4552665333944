/*
* pages/admin/CourseEditor.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: Edit the details of a course.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import update from 'immutability-helper';

import GenericModelEditor from 'components/GenericModelEditor.jsx';

const TERMS = {
  Fall: 1,
  Spring: 2,
  };

function CourseEditor(props) {
  const value = props.value === null ? empty() : props.value;

  const onChange = (e) => {
    e.preventDefault();
    let valueUpdate = {},
        receivedValue = e.target.value;

    if (e.target.type === "number" || e.target.type === "select-one") {
      receivedValue = Number(receivedValue);
      }

    valueUpdate[e.target.name] = {$set: receivedValue};
    props.onChange(update(value, valueUpdate));
    };

  return (<GenericModelEditor {...props}>
    <Form>
      <Row><Col>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            type="text"
            value={value.title}
            onChange={onChange}
            readOnly={props.readOnly} />
        </Form.Group>
      </Col></Row>

      <Row>
        <Col xs={6} md={3}>
          <Form.Group controlId="department">
            <Form.Label>Department</Form.Label>
            <Form.Control
              name="department"
              type="text"
              value={value.department}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>

        <Col xs={6} md={3}>
          <Form.Group controlId="number">
            <Form.Label>Number</Form.Label>
            <Form.Control
              name="number"
              type="number"
              value={value.number}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>

        <Col xs={6} md={3}>
          <Form.Group controlId="term">
            <Form.Label>Term</Form.Label>
            <Form.Control
              as="select"
              name="term"
              type="number"
              value={value.term}
              onChange={onChange}
              readOnly={props.readOnly}>
            {Object.keys(TERMS).map(name =>
              <option value={TERMS[name]} key={name}>
              {name}
              </option>
            )}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xs={6} md={3} >
          <Form.Group controlId="year">
            <Form.Label>Year</Form.Label>
            <Form.Control
              name="year"
              type="number"
              value={value.year}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>
      </Row>
    </Form>
    </GenericModelEditor>);
  }

function empty() {
  return {
    title: '',
    department: '',
    number: 0,
    term: 1,
    year: 0,
    };
  };

CourseEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    title: PropTypes.string,
    department: PropTypes.string,
    number: PropTypes.number,
    term: PropTypes.number,
    year: PropTypes.number,
    }),
  readOnly: PropTypes.bool,
  };

CourseEditor.defaultProps = {
  value: null,
  readOnly: false,
  };

export default React.memo(CourseEditor);
