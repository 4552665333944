/*
* pages/admin/GenericModelEditor.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 18th, 2019
* Description: General editor for database-based models.
*/

import "styles/custom.scss";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Alert, Intent } from '@blueprintjs/core';

import LoaderButton from 'components/LoaderButton.jsx';
import HiddenComponent from 'components/HiddenComponent.jsx';

class GenericModelEditor extends React.PureComponent {
  static propTypes = {
    saved: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    create: PropTypes.bool,

    allowDelete: PropTypes.bool,
    onDelete: PropTypes.func,
    deleteWarning: PropTypes.node,
    };

  static defaultProps = {
    readOnly: false,
    create: false,
    allowDelete: false,
    onDelete: () => {},
    deleteWarning: (<p>
      This will <i>permanently</i> delete the object, as well as all child
      objects. <b>Are you sure?</b>
      </p>),
    };

  state = {
    requestedDelete: false,
    };

  render() {
    // Number of available actions.
    const showDelete = this.props.allowDelete && ! this.props.create;

    let saveButtonSize = 12;
    if (showDelete) {
      saveButtonSize = 8;
      }

    return (<div>
      {this.props.children}

      <Alert
        isOpen={this.state.requestedDelete}
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        intent={Intent.DANGER}
        onCancel={() => this.setState({requestedDelete: false})}
        onConfirm={this.props.onDelete}>
        {this.props.deleteWarning}
      </Alert>

      <Row>
        <Col xs={12} md={saveButtonSize}>
          <LoaderButton
            variant={this.props.saved ? 'success' : 'warning'}
            loading={this.props.readOnly}
            disabled={this.props.readOnly || this.props.saved}
            onClick={this.props.onSave}>
            {this.getButtonText()}
          </LoaderButton>
        </Col>

        <Col xs={12} md={12 - saveButtonSize}>
          <HiddenComponent hidden={! showDelete}>
            <LoaderButton
              variant='danger'
              loading={this.state.requestedDelete}
              disabled={this.props.readOnly || this.state.requestedDelete}
              onClick={() => this.setState({requestedDelete: true})}>
              Delete
            </LoaderButton>
            </HiddenComponent>
        </Col>
      </Row>
    </div>);
    }

  getButtonText() {
    let unsavedText = 'Save',
        savedText = 'Saved';

    if (this.props.create) {
      unsavedText = 'Create';
      savedText = 'Created';
      }

    return (this.props.saved ? savedText: unsavedText);
    }
  }

export default GenericModelEditor;
