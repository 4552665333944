/*
* pages/student/course/Course.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Single course page display.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Link } from 'react-router-dom';

import TableList from 'components/TableList.jsx';
import Loader from 'components/Loader.jsx';
import APIErrorAlert from 'components/APIErrorAlert.jsx';
import Icon from 'components/Icon.jsx';
import HoverTooltip from 'components/HoverTooltip.jsx';
import LabelGroup from 'components/LabelGroup.jsx';
import { urljoin } from 'lib/utils';

function CoursePage({match, ...other}) {
  return (<div className="container-fluid">
  	<div className="aside">
  		<p>The quizzes for this course are listed below. If a quiz does not have
		a link, it is inactive.</p>
			<p>Even if a quiz has not started or the end time has passed, a quiz may
		be active if you have been granted an extension by an instructor.</p>
			<p>If you were granted an extension, it will be listed next to the quiz
		name. You can hover over the extension to see how far it extends.</p>
  	</div>
    <QuizList baseUrl={match.url} {...other} />
    </div>);
  }

class QuizList extends React.PureComponent {
	static propTypes = {
		baseUrl: PropTypes.string.isRequired,
		quizzes: PropTypes.arrayOf(PropTypes.object).isRequired,
		extensions: PropTypes.object.isRequired,
		error: PropTypes.string,
		loading: PropTypes.bool,
		};

	static defaultProps = {
		baseUrl: '',
		error: '',
		loading: false
		};

	constructor(props) {
		super(props);

		this.getTitleComponent = this.getTitleComponent.bind(this);
		this.getDurationFormat = this.getDurationFormat.bind(this);
		this.formatTime = this.formatTime.bind(this);
		}

	getTitleComponent(cell, row, extra, index) {
		/* Get the component for the title. */
		var tags = [];

		if (row.read_only) tags.push(LabelGroup.Warning("read-only"));
		else if (! row.active) tags.push(LabelGroup.Danger("unavailable"));
		if (this.props.extensions[row.id]) {
			var ext = this.props.extensions[row.id];
			tags.push(LabelGroup.Success(
				<HoverTooltip tooltip={moment(ext.end_time).toString()}>
					<span>extension <Icon i="ios-calendar-outline" /></span>
				</HoverTooltip>
				));
			}

		var nodes = <LabelGroup labels={tags} />;

		if (row.active || row.read_only) {
			const url = urljoin(this.props.baseUrl, 'quiz', row.id.toString());

			return (<span>
				{index+1}.&nbsp;
				<Link to={url}>{cell}</Link>&nbsp;{nodes}
				</span>);
			}
		else {
			return (<span>{index+1}. {cell}{nodes}</span>);
			}
		}

	getDurationFormat(cell, row) {
		/* Get the data format for the duration. */
		return (cell == null? (<div>
			<span className='d-lg-none'>N/A</span>
			<span className='d-none d-lg-block'>No Time Limit</span>
			</div>): cell);
		}

	formatTime(cell, row) {
		/* Format a time field. */
		var momentDate = moment(cell);

		return (<HoverTooltip tooltip={momentDate.toString()}>
			<span>{momentDate.calendar()} <Icon i="ios-calendar-outline" /></span>
			</HoverTooltip>);
		}

	render() {
		/* Render the component. */
		var spec = {
			'id': 'id',
			'title': 'Title',
			'score': 'Score',
			'start_time': 'Start Time',
			'end_time': 'End Time',
			'duration': 'Duration',
			};

		return (<div>
			<APIErrorAlert msg={this.props.error} />
			<Loader loading={this.props.loading} />
			<TableList
				specification={spec}
				specificationOptions={{id: {isKey: true, hidden: true},
					title: {dataFormat: this.getTitleComponent},
					duration: {dataFormat: this.getDurationFormat},
					start_time: {dataFormat: this.formatTime},
					end_time: {dataFormat: this.formatTime}}}
				data={this.props.quizzes}
				options={{hover: true, striped: true, clearSearch: true,
					search: true}} />
			</div>);
		}
	}

export default CoursePage;
