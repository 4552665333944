/*
* pages/student/question/LoadedProvider.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Load the provider and render it with the provided props.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import load_module from 'lib/loader.jsx';

class LoadedProvider extends React.PureComponent {
  static propTypes = {
    provider: PropTypes.string.isRequired,
    };

  render() {
    const { provider, ...other } = this.props;
    const Provider = this.loadProvider(provider);
    return <Provider {...other} />;
    }

  loadProvider(provider) {
    const comp = load_module(provider);
    if (comp === null) {
      throw new TypeError(`Could not find provider ${provider}.`);
      }

    return comp;
    }
  }

export default LoadedProvider;
