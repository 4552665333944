/*
* core/static/react/core/components/LabelGroup.jsx
* Quizzera
* Author: Rushy Panchal
* Date: December 6th, 2016
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

/*
* A group of labels that are rendered at once.
*
* Required Props
* 	Array[Label] labels - list of labels to show. Each Label should be taken
* 		as a LabelGroup child class, accessed via LabelGroup.{Class}.
* 		The available classes are:
* 			- Default
* 			- Primary
* 			- Success
* 			- Info
* 			- Warning
* 			- Danger
*/
class LabelGroup extends React.Component {
	static Default = (content) => ({variant: "dark", content: content});
	static Primary = (content) => ({variant: "primary", content: content});
	static Success = (content) => ({variant: "success", content: content});
	static Info = (content) => ({variant: "info", content: content});
	static Warning = (content) => ({variant: "warning", content: content});
	static Danger = (content) => ({variant: "danger", content: content});

	render() {
		/* Render the component. */
		return (
			<span>
			{this.props.labels.map((e) => (
				<Badge variant={e.variant} key={e.content} className="padded">
					{e.content}
				</Badge>))}
			</span>
			)
		}
	}

LabelGroup.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.object)
	};

export default LabelGroup;
