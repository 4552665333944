/*
* pages/student/question/CurrentAttempt.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Display an ongoing attempt.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Alert } from 'react-bootstrap';

import HiddenComponent from 'components/HiddenComponent.jsx';
import IgnorableWarnings from 'components/IgnorableWarnings.jsx';
import LoadedProvider from './LoadedProvider';

class CurrentAttempt extends React.PureComponent {
  static propTypes = {
    provider: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    };

  static defaultProps = {
    loading: false
    };

  state = {
    input: '',
    user_errors: [],
    };

  render() {
    return (<div>
      <LoadedProvider
        provider={this.props.provider}
        data={this.props.data}
        value={this.state.input}
        onError={es => this.setState({user_errors: es})}
        onChange={e => this.setState({input: e})}
      />
      <IgnorableWarnings
        messages={this.state.user_errors}
        header="Validation Error"
        hideOnIgnore
        onTotalIgnore={() => this.setState({user_errors: []})}
        ignoreMsg="Continue Anyway"
      />
      <HiddenComponent hidden={this.state.user_errors.length === 0}>
        <Row><Col xs={12}><Alert variant="danger">
          <p>Please fix the validation warnings shown or ignore all
          of them. If all warnings are ignored, you will be able to
          submit again.</p>
        </Alert></Col></Row>
      </HiddenComponent>
      <br/>
      <Button onClick={() => this.props.onSubmit(this.state.input)}
              disabled={this.state.user_errors.length !== 0
                        || this.props.loading}>
        Submit
      </Button>
    </div>);
    }
  }

export default CurrentAttempt;
