/*
* state/resources/index.ts
* Author: Rushy Panchal
* Date: July 22nd, 2019
*/

import reducer from "./reducers";
import * as types from "./types";
import * as selectors from "./selectors";
import * as actions from "./actions";

export {
  types,
  selectors,
  actions,
  reducer,
};

export default reducer;
