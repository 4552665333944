/*
* ProblemIdentificationExercise/ProblemIdentificationExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function ProblemIdentificationExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default ProblemIdentificationExercise;
