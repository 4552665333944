/*
* pages/admin/enrollments/BulkEnrollmentEditor.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Bulk-enroll users into a course. This component simply provides
*              the view for editing that data.
*/

import "styles/custom.scss";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import { MenuItem } from "@blueprintjs/core";
import { Suggest, MultiSelect } from "@blueprintjs/select";
import { DatePicker } from '@blueprintjs/datetime';

import LoaderButton from 'components/LoaderButton';

const QuizShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  });

class BulkEnrollmentEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      quiz: PropTypes.number.isRequired,
      users: PropTypes.arrayOf(PropTypes.string).isRequired,
      end_time: PropTypes.string.isRequired,
      }).isRequired,

    users: PropTypes.arrayOf(PropTypes.string).isRequired,
    quizzes: PropTypes.arrayOf(QuizShape.isRequired).isRequired,

    onExtend: PropTypes.func.isRequired,

    loading: PropTypes.bool,
    error: PropTypes.string,
    };

  static defaultProps = {
    loading: false,
    error: '',
    };

  constructor(props) {
    super(props);

    this.onExtend = this.onExtend.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onSelectQuiz = this.onSelectQuiz.bind(this);
    this.onSelectUser = this.onSelectUser.bind(this);
    this.onDeselectUser = this.onDeselectUser.bind(this);
    this.renderUser = this.renderUser.bind(this);
    }

  render() {
    return (<div>
      <p>
        To grant students extensions, select a quiz, a set of users, and a new
        end date. Those users will be given until the new end date to finish
        the selected quiz.
      </p>
      <hr/>

      <h4>Quiz</h4>
      <Suggest
        items={this.props.quizzes}
        activeItem={this.props.value.quiz}
        onActiveItemChange={this.onSelectQuiz}
        itemRenderer={this.renderQuiz}
        inputValueRenderer={q => q.title}
        itemsEqual='id'
        onItemSelect={this.onSelectQuiz}
        itemPredicate={this.filterQuiz}
        noResults='No Results'
        fill
      />

      <br/>
      <h4>Students</h4>
      <MultiSelect
        items={this.props.users}
        selectedItems={this.props.value.users}
        itemRenderer={this.renderUser}
        tagRenderer={item => item}
        onItemSelect={this.onSelectUser}
        tagInputProps={{onRemove: this.onDeselectUser}}
        itemPredicate={(query, user) => user.match(new RegExp(query, 'i'))}
        noResults='No Results'
        fill
      />

      <br/>
      <h4>Extension Date</h4>
      <DatePicker
        value={new Date(this.props.value.end_time)}
        onChange={this.onDateChange}
        timePickerProps={{useAmPm: true}}
        timePrecision='seconds'
        minDate={moment().subtract({years: 1}).toDate()}
        maxDate={moment().add({years: 1}).toDate()}
      />

      <br/>
      <LoaderButton loading={this.props.loading} onClick={this.onExtend}>
        Grant Extensions
      </LoaderButton>
    </div>);
    }

  renderQuiz(quiz, { handleClick, modifiers }) {
    return <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={moment(quiz.end_time).calendar()}
      key={quiz.id}
      onClick={handleClick}
      text={quiz.title}
      />;
    }

  filterQuiz(query, quiz) {
    const re = new RegExp(query, "i");
    return quiz.title.match(re) || quiz.end_time.match(re);
    }

  onSelectQuiz(quiz) {
    if (quiz === null || quiz === undefined) return;
    let updated = update(this.props.value, {quiz: {$set: quiz.id}});
    this.props.onChange(updated);
    }

  renderUser(user, { modifiers, handleClick }) {
    return <MenuItem
      active={modifiers.active}
      icon={this.props.value.users.indexOf(user) !== -1 ? 'tick': 'blank'}
      key={user}
      onClick={handleClick}
      text={user}
      shouldDismissPopover={false}
    />;
    }

  onSelectUser(user) {
    const index = this.props.value.users.indexOf(user);

    if (index !== -1) {
      return this.onDeselectUser(user, index);
      }
    else {
      let updated = update(this.props.value, {users: {$push: [user]}});
      this.props.onChange(updated);
      }
    }

  onDeselectUser(user, index) {
    let updated = update(this.props.value, {users: {$splice: [[index, 1]]}});
    this.props.onChange(updated);
    }

  onDateChange(date) {
    let dateStr = date.toISOString();
    let updated = update(this.props.value, {end_time: {$set: dateStr}});
    this.props.onChange(updated);
    }

  onExtend() {
    this.props.onExtend(this.props.value);
    }
  }

export default BulkEnrollmentEditor;
