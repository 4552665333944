/*
* core/static/react/core/APIErrorAlert.jsx
* Quizzera
* Author: Rushy Panchal
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import ErrorAlert from './ErrorAlert.jsx';

/*
* An alert that shows an API error. It is only displayed
* when the error is available.
*
* Optional Props
*   String msg - message to show
*
*/
function APIErrorAlert(props) {
  return (<ErrorAlert header="API Error" msg={props.msg} />);
  }

APIErrorAlert.propTypes = {
  msg: PropTypes.string,
  };

APIErrorAlert.defaultProps = {
  msg: null
  };

export default React.memo(APIErrorAlert);
