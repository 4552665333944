/*
* questions/static/react/quizgen/ArrayMatchingQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import ArrayQuestion from './ArrayQuestion.jsx';

/* Currently, Array classification input is the same is regular array input.
However, modifying this implementation will allow for flexibility in the
future. */
const ArrayMatchingQuestion = ArrayQuestion;

export default ArrayMatchingQuestion;
