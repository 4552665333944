/*
* state/resources/reducers.ts
* Author: Rushy Panchal
* Date: July 22nd, 2019
* Description: Primary reducers for working with resources.
*/

import update from 'immutability-helper';
import { isEqual } from 'lodash';

import * as types from './types';
import { initialResourceState, dataToState }  from './initial';
import getInitialState from './initial';
import { Id, Error } from 'types/base';
import Model from 'types/abstract/Model';

/*
State Shape:
------------
{
  [resource_type]: {
    pending: {
      
      },
    objects: {
      1: Resource1...
      2: Resource2...
      }
    }
  }
*/

function asyncReducer<T extends Model>(
    state: types.ResourceState<T>,
    payload: types.ResourcePendingActionPayload<T>)
    : types.ResourceState<T> {
  const { key } = payload;

  const pending = {
    [key]: {loading: true, completed: false}
    };

  return update(state, {
    pending: {$merge: pending},
    });
  }

function finishRequestReducer<T extends Model>(
    state: types.ResourceState<T>,
    payload: types.ResourceFinishedActionPayload<T>)
    : types.ResourceState<T> {
  const { action_intent, key } = payload;
  let object_update = {};

  switch (action_intent) {
    case types.ACTIONS.LOAD_RESOURCE:
      // falls through
    case types.ACTIONS.CREATE_RESOURCE:
      // falls through
    case types.ACTIONS.UPDATE_RESOURCE: {
      let result = payload.result as T;

      // Add the newly-loaded object.
      object_update = {$merge: {
        [result.id]: dataToState(result)
        }};
      break;
    }

    case types.ACTIONS.BULK_CREATE_RESOURCE:
      // falls through

    case types.ACTIONS.BULK_UPDATE_RESOURCE:
      // falls through

    case types.ACTIONS.LOAD_RESOURCE_LIST: {
      let results = payload.result as Array<T>;

      // Add the loaded objects.
      let resultsAsDict: {[index:number]: types.ResourceObject<T>} = {};
      results.forEach(data => resultsAsDict[data.id] = dataToState(data));

      object_update = {$merge: resultsAsDict};
      break;
    }

    case types.ACTIONS.DELETE_RESOURCE: {
      let result = payload.result as Id;

      // Remove the object from the list.
      object_update = {$unset: [result]};
      break;
    }
    default: break;
    }

  // TODO: should this return an update spec instead? The update would be
  // much leaner then (i.e. updating parts of the tree instead of the whole
  // resource tree).
  return update(state, {
    pending: {
      [key]: {$set: {loading: false, completed: true}}
      },
    objects: object_update,
    });
  }

function failRequestReducer<T extends Model>(
    state: types.ResourceState<T>,
    payload: types.ResourceFinishedActionPayload<T>)
    : types.ResourceState<T> {
  const { key, result } = payload;

  return update(state, {pending: {
    [key]: {$set: {loading: false, error: result as Error, completed: true}}
    }});
  }

function editReducer<T extends Model>(
    state: types.ResourceState<T>,
    payload: types.ResourceEditActionPayload<T>)
    : types.ResourceState<T> {
  let obj = state.objects[payload.id];

  // If initial is not set, the object has not changed.
  let initial = obj.initial !== undefined ? obj.initial : obj.data;

  // The new object must be computed separately because of the type-casts
  // required.
  const updated_object = update(
    obj.data as object,
    {$merge: payload.update}) as T;
  const changed = ! isEqual(updated_object, initial);

  // If the data is the same as the initial, then there is no need to store
  // the initial data.
  const initialUpdate = changed ? initial: undefined;

  return update(state, {
    objects: {[payload.id]: {
      data: {$set: updated_object},
      initial: {$set: initialUpdate},
    }}});
  }

export default function<T extends Model>(
    state: types.GlobalResourceState, action: types.Action<T>)
    : types.GlobalResourceState {

  if (state === undefined) {
    return getInitialState();
    }

  let new_resource_state = {} as types.ResourceState<T>;

  switch (action.type) {
    case types.ACTIONS.LOAD_RESOURCE:
      // falls through

    case types.ACTIONS.LOAD_RESOURCE_LIST:
      // falls through

    case types.ACTIONS.CREATE_RESOURCE:
      // falls through

    case types.ACTIONS.BULK_CREATE_RESOURCE:
      // falls through

    case types.ACTIONS.BULK_UPDATE_RESOURCE:
      // falls through

    case types.ACTIONS.UPDATE_RESOURCE:
      // falls through

    case types.ACTIONS.DELETE_RESOURCE:
      new_resource_state = asyncReducer(
        getResourceState(state, action),
        action.payload);
      break;

    case types.ASYNC_STATUS_ACTIONS.FINISH_REQUEST:
      new_resource_state = finishRequestReducer(
        getResourceState(state, action),
        action.payload);
      break;

    case types.ASYNC_STATUS_ACTIONS.FAIL_REQUEST:
      new_resource_state = failRequestReducer(
        getResourceState(state, action),
        action.payload);
      break;

    case types.LOCAL_ACTIONS.EDIT_RESOURCE:
      new_resource_state = editReducer(
        getResourceState(state, action),
        action.payload);
      break;

    default: return state;
    }

  const resource_type = action.payload.resource_type; 
  return update(state, {[resource_type]: {$set: new_resource_state}});
  }

function getResourceState<T extends Model>(
    state: types.GlobalResourceState, action: types.Action<T>)
    : types.ResourceState<T> {
  const resource_type = action.payload.resource_type;
  // The error is that because resource_type is a string, TypeScript
  // cannot infer that state[resource_type] has the same type as the parameter
  // T from the function. However, this is because we do not have a mapping
  // between the enumeration and models. Instead, there is an external
  // constraint (albeit, one that is not currently checked) that if the
  // resource_type is 'course', it solely refers to the model.Course type;
  // if it is 'quiz', it refers to model.Quiz, and so forth.
  // @ts-ignore
  const resource_state = state[resource_type] as types.ResourceState<T>;
  return resource_state || initialResourceState();
  }
