/*
* pages/admin/GenericCollectionEditor.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: Edit a collection of generic items from a parent.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import DraggableCardCollection from './DraggableCardCollection.jsx';
import APIErrorAlert from './APIErrorAlert.jsx';
import LoaderButton from './LoaderButton.jsx';
import PureDraggableEditor from './PureDraggableEditor.jsx';
import GenericCreationCard from './GenericCreationCard.jsx';

const ItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  })

class GenericCollectionEditor extends React.PureComponent {
  static propTypes = {
    editor: PropTypes.elementType.isRequired,
    header: PropTypes.string.isRequired,

    items: PropTypes.arrayOf(ItemShape).isRequired,
    saved: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,

    onReorder: PropTypes.func.isRequired,
    onSaveOrder: PropTypes.func.isRequired,
    reorderLoading: PropTypes.bool.isRequired,
    reorderSaved: PropTypes.bool.isRequired,

    pendingItem: ItemShape,
    createLoading: PropTypes.bool.isRequired,
    createError: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,

    reorderable: PropTypes.bool,
    disabled: PropTypes.bool,
    };

  static defaultProps = {
    reorderable: true,
    pendingItem: null,
    disabled: false,
    }

  render() {
    let ongoingRequest = this.props.loading || this.props.reorderLoading;

    return (<div>
      <APIErrorAlert msg={this.props.error} />
      <Row>
        <Col xs={12} md={6} />
        <Col xs={12} md={6}>
          <LoaderButton
            loading={ongoingRequest}
            disabled={this.props.reorderSaved || this.props.disabled}
            onClick={this.props.onSaveOrder}
            variant={this.props.reorderSaved ? 'success': 'warning'}>
            {this.props.reorderSaved ? 'Order Saved': 'Save Order'}
          </LoaderButton>
        </Col>
      </Row>

      <br/>
      <DraggableCardCollection
        onDragEnd={this.onReorder}
        disabled={!this.props.reorderable
            || ongoingRequest
            || this.props.disabled}
      >
        {Object.values(this.props.items)
          .sort((a, b) => a.index - b.index)
          .map(item =>
            <PureDraggableEditor
              key={`editor-${item.id}`}
              editor={this.props.editor}
              item={{data: item, saved: this.props.saved[item.id]}}
              onUpdate={this.props.onUpdate}
              onSave={this.props.onSave}
              onDelete={this.props.onDelete}
              disabled={this.props.disabled}
              allowDelete
            />
         )}
      </DraggableCardCollection>

      <GenericCreationCard
        header={this.props.header}
        editor={this.props.editor}
        item={{
          data: this.props.pendingItem,
          request: this.props.createLoading,
          }}
        error={this.props.createError}
        onChange={val => this.props.onUpdate(val.data, true)}
        onCreate={this.props.onCreate}
        disabled={this.props.disabled}
      />
      </div>);
    }

  onReorder = ({ source, destination }) => {
    if (destination === null || source.index === destination.index) return;

    // Original item IDs, ordered by their current position.
    let itemIds = this.props.items.map(q => q.id);

    // Remove the item at source.index...
    const removedId = itemIds.splice(source.index, 1)[0];

    // ...and then add it in at destination.index.
    itemIds.splice(destination.index, 0, removedId);

    this.props.onReorder(itemIds);
    }
  }

export default GenericCollectionEditor;