/*
* src/pages/admin/AdminController.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Course administrator dashboard controller.
*/

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Course } from 'lib/api';
import { actions, selectors } from 'state/resources';
import Loader from 'components/Loader';
import AdminDashboard from './AdminDashboard';

class AdminController extends React.Component {
  componentDidMount() {
    const { course } = this.props.match.params;
    const action = actions.loadResource(Course, course);
    this.props.dispatch(action);
    }

  render() {
    const { loading, course, completed, ...other } = this.props;

    if (completed) {
      if (course !== null && course !== undefined && course.is_admin) {
        return <AdminDashboard {...other} />;
        }

      return <Redirect to='/' />;
      }

    return <Loader loading={loading} />;
    }
  }

function mapStateToProps(state, ownProps) {
  const { course } = ownProps.match.params;
  const courses = selectors.getResource(state, Course);
  const pending = selectors.getPending(courses, course.toString());

  return {
    course: selectors.getSingleResource(courses, course),
    loading: pending.loading,
    completed: pending.completed,
    };
  }

export default connect(mapStateToProps)(AdminController);
