/*
* ProblemIdentificationStringExercise/ProblemIdentificationStringExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function ProblemIdentificationStringExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default ProblemIdentificationStringExercise;
