/*
* pages/admin/QuizEditor.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: Edit the details of a course.
*/

import "styles/custom.scss"
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { DatePicker, TimePrecision } from '@blueprintjs/datetime';
import update from 'immutability-helper';
import moment from 'moment';

import GenericModelEditor from 'components/GenericModelEditor.jsx';

function QuizEditor(props) {
  const value = props.value === null ? empty() : props.value;

  const start_time = new Date(value.start_time),
        end_time = new Date(value.end_time);

  const onChangeValue = React.useCallback((key, receivedValue) => {
    let valueUpdate = {};
    valueUpdate[key] = {$set: receivedValue};

    props.onChange(update(value, valueUpdate));
    },
    [props, value]);

  const onChange = React.useCallback((e) => {
    e.preventDefault();

    let receivedValue = e.target.value;
    if (e.target.type === "number") {
      receivedValue = Number(receivedValue);
      }

    onChangeValue(e.target.name, receivedValue);
    },
    [onChangeValue]);

  return (<GenericModelEditor {...props}>
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              value={value.title}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="quiz_time">
            <Form.Label>Start Time</Form.Label>
            <Form.Row>
              <DatePicker
                timePickerProps={{
                  useAmPm: true,
                  precision: TimePrecision.SECOND,
                  }}
                onChange={React.useCallback(
                  val => onChangeValue('start_time', val.toISOString()),
                  [onChangeValue])}
                minDate={moment(start_time).subtract({years: 10}).toDate()}
                maxDate={moment(start_time).add({years: 10}).toDate()}
                value={start_time}
                disabled={props.readOnly}
                shortcuts={false}
                showActionsBar />
            </Form.Row>
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group controlId="quiz_time">
            <Form.Label>End Time</Form.Label>
            <Form.Row>
              <DatePicker
                timePickerProps={{
                  useAmPm: true,
                  precision: TimePrecision.SECOND,
                  }}
                onChange={React.useCallback(
                  val => onChangeValue('end_time', val.toISOString()),
                  [onChangeValue])}
                minDate={moment(end_time).subtract({years: 10}).toDate()}
                maxDate={moment(end_time).add({years: 10}).toDate()}
                value={end_time}
                disabled={props.readOnly}
                shortcuts={false}
                showActionsBar />
            </Form.Row>
          </Form.Group>
        </Col>
      </Row>
    </Form>
    </GenericModelEditor>);
  }

function empty() {
  return {
    id: null,
    title: '',
    start_time: moment().toISOString(),
    end_time: moment().add(7, 'days').toISOString(),
    };
  };

QuizEditor.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    }),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  };

QuizEditor.defaultProps = {
  value: null,
  readOnly: false,
  };

export default React.memo(QuizEditor);
