/*
* LinearProgrammingEnteringVariableExercise/LinearProgrammingEnteringVariableExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function LinearProgrammingEnteringVariableExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default LinearProgrammingEnteringVariableExercise;
