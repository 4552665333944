/*
* questions/static/react/DefaultQuestionExplanation.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import HiddenComponent from 'components/HiddenComponent.jsx';

/*
* Displays the question score, correct answer, and explanation.
*	The explanation and correct answer field names can be overridden.
*
* Required Props
*		Object data - data returned from API. Should contain 'score', 'max_score',
*			and 'data' fields. The 'data' field should be an object which contains
*			the specified explanation field and correct answer field.
*
* Optional Props
*		String explanationField - field to use for the explanation. Defaults to
*			'explanation'.
*		String answerField - field to use for the correct answer. Defaults to
*			'correct_answer'.
*
*/
function DefaultQuestionExplanation(props) {
	if (! _.isEmpty(props.data)) {
		return (<div className='result'>
			<HiddenComponent hidden={! props.data[props.answerField]}>
				<div className="correct-answer">
					<h5>Correct Answer</h5>
					<pre className="word-wrapped">
						{props.data[props.answerField]}
					</pre>
				</div>
			</HiddenComponent>

			<HiddenComponent hidden={! props.data[props.explanationField]}>
				<div className="explanation">
					<h5>Explanation</h5>
					<pre className="word-wrapped">
						{props.data[props.explanationField]}
					</pre>
				</div>
			</HiddenComponent>
			</div>);
		}

	/* If the data prop is empty, then return 'nothing'. Because React 0.14
	does not allow a return of null or false, a fake 'nothing' must be
	returned instead. */
	return (<div style={{display: 'none'}}></div>);
	}

DefaultQuestionExplanation.propTypes = {
	data: PropTypes.object.isRequired,
	};

DefaultQuestionExplanation.defaultProps = {
	explanationField: 'explanation',
	answerField: 'correct_answer',
	data: null,
	};

export default DefaultQuestionExplanation;
