/*
* lib/env.ts
* Author: Rushy Panchal
* Date: July 26th, 2019
* Description: Environment variables used by the Quizzera front-end.
*/

import { ensure_url, urljoin } from 'lib/utils';

export const API_URL = ensure_url(getenv('API_URL'));

export const LOGIN_URL = ensure_url(
  getenv('LOGIN_URL', urljoin(API_URL, 'accounts', 'login')));

export const LOGOUT_URL = ensure_url(
  getenv('LOGOUT_URL', urljoin(API_URL, 'accounts', 'logout')));

export const ENABLE_QUESTION_MODULES = Boolean(Number(
  getenv('ENABLE_QUESTION_MODULES', '1')));

function getenv(name: string, default_value: string = ''): string {
  const value = process.env[`REACT_APP_${name}`];

  if (value === null || value === undefined) return default_value;
  return value;
  }
