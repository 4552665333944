/*
* pages/student/quiz/CourseController.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Controller for quiz detail view.
*/

import React from 'react';
import { connect } from 'react-redux';

import { Question } from 'lib/api';
import { actions, selectors } from 'state/resources';
import QuizPage from './QuizPage';

const QUESTION_LIST_KEY = 'student-question-list';

class QuizController extends React.Component {
  render() {
    return <QuizPage {...this.props} />
    }

  componentDidMount() {
    const quiz_id = this.props.match.params.quiz;
    this.props.dispatch(actions.loadResourceList(
      Question, QUESTION_LIST_KEY, {quiz_id: quiz_id},
      ));
    }
  }

function mapStateToProps(state, ownProps) {
  const quiz_id = Number(ownProps.match.params.quiz);
  const questions = selectors.getResource(state, Question);
  const pending = selectors.getPending(questions, QUESTION_LIST_KEY);

  return {
    questions: selectors.filterResource(questions, {quiz_id: quiz_id}),
    loading: pending.loading,
    error: pending.error || '',
    };
  }

export default connect(mapStateToProps)(QuizController);
