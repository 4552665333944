/*
* pages/Dashboard/DashboardController.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Controller for dashboard.
*/

import React from 'react';
import { connect } from 'react-redux';

import { Course, CurrentUser } from 'lib/api';
import { actions, selectors } from 'state/resources';
import DashboardPage from './DashboardPage';

const FILTER_PENDING_KEY = 'user-courses';
const CREATE_PENDING_KEY = 'user-create-course';

class DashboardController extends React.Component {
  constructor(props) {
    super(props);
    this.createCourse = this.createCourse.bind(this);
    }

  render() {
    return <DashboardPage
      onCreate={this.createCourse}
      {...this.props} />;
    }

  componentDidMount() {
    const user = new CurrentUser();

    this.props.dispatch(actions.loadResourceList(
      Course, FILTER_PENDING_KEY, {user: user.id}
      ));
    }

  createCourse(course) {
    return this.props.dispatch(actions.createResource(
      Course, CREATE_PENDING_KEY, course
      ));
    }
  }

function mapStateToProps(state) {
  const courses = selectors.getResource(state, Course);
  const loadPending = selectors.getPending(courses, FILTER_PENDING_KEY);
  const createPending = selectors.getPending(courses, CREATE_PENDING_KEY);

  return {
    courses: selectors.filterResource(courses),
    loading: loadPending.loading,
    error: loadPending.error || '',
    createLoading: createPending.loading,
    createError: createPending.error || '',
    };
}

export default connect(mapStateToProps)(DashboardController);

