/*
* BurrowsWheelerTransformExercise/BurrowsWheelerTransformExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ArrayQuestion from '../../quizgen/web/questions/ArrayQuestion.jsx';

function BurrowsWheelerTransformExercise(props) {
  return (<ArrayQuestion {...props} />);
}

export default BurrowsWheelerTransformExercise;
