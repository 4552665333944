/*
* princeton/quizgen/web/input/StringInput.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import BasicInput from './BasicInput.jsx';

/*
* The StringInput component is used for inputting strings. Currently, it is
* simply a wrapper around a text input.
*/
function StringInput(props) {
	return <BasicInput type='text' className='input string' {...props} />;
	}

export default StringInput;
