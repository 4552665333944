/*
* PropertiesOfMergesortExercise/PropertiesOfMergesortExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function PropertiesOfMergesortExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default PropertiesOfMergesortExercise;
