/*
* OrderOfGrowthExercise/OrderOfGrowthExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import SingleChoiceQuestion from '../../quizgen/web/questions/SingleChoiceQuestion.jsx';

function OrderOfGrowthExercise(props) {
  return (<SingleChoiceQuestion {...props} />);
}

export default OrderOfGrowthExercise;
