/*
* pages/student/question/QuestionPage.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Single course page display.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader.jsx';
import Attempt from './Attempt';

class QuestionPage extends React.PureComponent {
  static propTypes = {
    attempt: PropTypes.object,
    onNewAttempt: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    };

  static defaultProps = {
    attempt: null,
    loading: false,
    };

  render() {
    const { match, attempt, loading, ...other } = this.props;

    if (attempt === null) {
      return null;
      }

    // Pass through *all* the props! (except those that we don't).
    return (<div className="container-fluid">
      <Loader loading={loading} />
      <Attempt
        {...other}
        {...attempt} />
      </div>);
    }
}

export default QuestionPage;
