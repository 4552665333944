/*
* Q226CollaborationCode/Q226CollaborationCode.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import SingleChoiceQuestion from '../../quizgen/web/questions/SingleChoiceQuestion.jsx';

function Q226CollaborationCode(props) {
  return (<SingleChoiceQuestion {...props} />);
}

export default Q226CollaborationCode;
