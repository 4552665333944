/*
* core/static/react/core/ErrorAlert.jsx
* Quizzera
* Author: Rushy Panchal
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { getErrorMsg } from 'lib/utils';

/*
* An alert that shows an error. It is only displayed when the error is 
* available.
*
* Optional Props
*   String header - header for error
*   String msg - message to show
*/
function ErrorAlert(props) {
  if (props.msg) {
    return (
      <Alert variant='danger'>
        <h4>{props.header}</h4>
        <p>{getErrorMsg(props.msg)}</p>
      </Alert>
      );
    }
  
  return (<span></span>);
  }

ErrorAlert.propTypes = {
  header: PropTypes.node,
  msg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    ]),
  };

ErrorAlert.defaultProps = {
  header: "Error",
  msg: null,
  };

export default React.memo(ErrorAlert);
