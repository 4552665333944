/*
* SetAnalysisExercise/SetAnalysisExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ArrayMatchingQuestion from '../../quizgen/web/questions/ArrayMatchingQuestion.jsx';

function SetAnalysisExercise(props) {
  return (<ArrayMatchingQuestion {...props} />);
}

export default SetAnalysisExercise;
