/*
* questions/static/react/questions/input/validators.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Collection of validation functions for question input.
* 	Each validator should take in the input and if the input is not valid,
* 	throw an error with the validation message to display.
*/

function runValidators(input, validators) {
	/*
	* Run the validators on the input and aggregate the error messages.
	*
	* Parameters
	* 	String input - input to validate
	* 	[function] validators - array of validators to run
	*
	* Returns
	* 	([Object]) errors from running validators
	*/
	var messages = [],
		input_obj = null;

	try {
		input_obj = JSON.parse(input);
		}
	catch (err) {
		input_obj = input;
		}

	validators.forEach((v) => {
		if (! v) return;

		try {
			v(input_obj);
			}
		catch (err) {
			messages.push(err.message);
			}
		});

	return messages;
	}

export default runValidators;
