/*
* Q226CollaborationHelp/Q226CollaborationHelp.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import SingleChoiceQuestion from '../../quizgen/web/questions/SingleChoiceQuestion.jsx';

function Q226CollaborationHelp(props) {
  return (<SingleChoiceQuestion {...props} />);
}

export default Q226CollaborationHelp;
