/*
* pages/admin/settings/SettingsPage.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Controller for settings page.
*/

import 'styles/custom.scss';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { Drawer, Classes as BlueprintClasses } from '@blueprintjs/core';

import CourseEditor from 'components/models/CourseEditor';
import Loader from 'components/Loader';
import LoaderButton from 'components/LoaderButton';
import APIErrorAlert from 'components/APIErrorAlert';

class SettingsPage extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClone: PropTypes.func.isRequired,
    value: PropTypes.object,
    saved: PropTypes.bool.isRequired,

    loading: PropTypes.bool.isRequired,
    cloneLoading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    };

  static defaultProps = {
    value: null,
    };

  state = {
    drawerOpen: false,
    };

  render() {
    const { error, loading, ...other } = this.props;

    return (<div className='container-fluid'>
      <Row>
        <Col xs={12} md={9}>
          <h3>Course Settings</h3>
        </Col>
        <Col xs={12} md={3}>
          <Button variant='dark' onClick={this.onDrawerToggle}>Clone</Button>
        </Col>
      </Row>
      
      <APIErrorAlert msg={error} />
      <Loader loading={loading} />
      <CourseEditor
        {...other}
        allowDelete
        deleteWarning={
          <p>
            This will <i>permanently</i> delete the course, as well as all
            child objects (quizzes, questions, enrollments, question attempts,
            and so forth). In particular, all student data for this course will
            be lost.

            Are you sure you wish to delete the course?
          </p>
          }
      />

      <Drawer
        onClose={this.onDrawerToggle}
        isOpen={this.state.drawerOpen}
        title='Clone Course'
      >
      <div className={BlueprintClasses.DRAWER_BODY}>
        <div className={BlueprintClasses.DIALOG_BODY}>
          <p>
            Cloning the course duplicates all of its content into a fresh, new
            course. This means that all of the quizzes (and all of the
            questions for those quizzes), along with their existing settings,
            will be copied to the new course. All student data (such as the
            list of enrolled users and their attempts on questions) is&nbsp;
            <i>not</i> copied.
          </p>
          <p>
            You will be automatically added as an administrator to the new
            course, and redirected once the cloning is complete. The new course
            can be managed as any other course&mdash;the details and content
            can be modified without affecting the original.
          </p>
          <p>
            Note that only <i>saved</i> course settings will be propagated to
            the cloned course. If there are any current, unedited changes, they
            will not appear on the new course.
          </p>

          <LoaderButton
            loading={this.props.cloneLoading}
            onClick={this.props.onClone}
            variant='dark'
          >
            Clone
          </LoaderButton>
        </div>
      </div>
      </Drawer>

      </div>);
    }

  onDrawerToggle = () => this.setState({drawerOpen: !this.state.drawerOpen});
  }

export default SettingsPage;
