/*
* pages/admin/PureDraggableEditor.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: A pure editor card that can be dragged.
*/
import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleCard from 'components/CollapsibleCard.jsx';

function PureDraggableEditor(props) {
  const { item, allowDelete } = props;
  const Editor = props.editor;

  const onSave = React.useCallback(
    () => props.onSave(item.data),
    [props, item]
    );

  const onDelete = React.useCallback(() => {
    props.onDelete(item.data.id);
    },
    [props, item]);

  return (<CollapsibleCard
    header={item.data.title}
    openText={item.saved ? 'Close Editor': 'Close Editor (unsaved)'}
    closedText={item.saved ? 'Edit': 'Edit (unsaved)'}
    variant={item.saved ? 'dark': 'warning'}>
    <Editor
      item={item}
      readOnly={item.request || props.disabled}
      saved={item.saved}
      value={item.data}
      onChange={props.onUpdate}
      onSave={onSave}
      onDelete={onDelete}
      allowDelete={allowDelete}
      />
  </CollapsibleCard>);
  }

PureDraggableEditor.propTypes = {
  editor: PropTypes.elementType.isRequired,
  item: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      }).isRequired,
    saved: PropTypes.bool.isRequired,
    request: PropTypes.bool,
    }).isRequired,  
  onUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  };

PureDraggableEditor.defaultProps = {
  disabled: false,
  };

export default React.memo(PureDraggableEditor);
