/*
* BurrowsWheelerInverseTransformExercise/BurrowsWheelerInverseTransformExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import TypedArrayQuestion from '../../quizgen/web/questions/TypedArrayQuestion.jsx';

function BurrowsWheelerInverseTransformExercise(props) {
  return (<TypedArrayQuestion type={TypedArrayQuestion.types.char} {...props} />);
}

export default BurrowsWheelerInverseTransformExercise;
