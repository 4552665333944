/*
* pages/auth/Logout.jsx
* Author: Rushy Panchal
* Date: July 11th, 2019
* Description: Logout page.
*/

import React from 'react';

import { APIClient } from 'lib/api';
import { absolute_url } from 'lib/utils';
import { LOGOUT_URL } from 'lib/env';

function LogoutPage(props) {
  let client = new APIClient();
  var next = {pathname: '/'};

  if (props.location.state !== null && props.location.state !== undefined) {
    next = props.location.state.from;
    }

  client.logout();

  const redirectUrl = `${LOGOUT_URL}?next=${absolute_url(next.pathname)}`;
  window.location = redirectUrl;
  return <div></div>;
  }

export default LogoutPage;
