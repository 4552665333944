/*
* pages/admin/GenericCreationCard.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: A pure card that can be dragged.
*/
import "styles/custom.scss"
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Drawer, Classes as BlueprintClasses } from '@blueprintjs/core';
import update from 'immutability-helper';

import APIErrorAlert from 'components/APIErrorAlert';

class GenericCreationCard extends React.PureComponent {
  static propTypes = {
    header: PropTypes.string.isRequired,
    editor: PropTypes.elementType.isRequired,
    item: PropTypes.shape({
      request: PropTypes.bool.isRequired,
      data: PropTypes.object,
      }).isRequired,
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    description: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    };

  static defaultProps = {
    description: null,
    error: '',
    disabled: false,
    };

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      };

    this.onDrawerToggle = this.onDrawerToggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    }

  onDrawerToggle() {
    this.setState({drawerOpen: !this.state.drawerOpen})
    }

  onChange(val) {
    let updateSpec = {data: {$set: val}, saved: {$set: false}};
    this.props.onChange(update(this.props.item, updateSpec));
    }

  onCreate() {
    this.props
      .onCreate()
      .then(() => this.onDrawerToggle());
    }

  render() {
    const Editor = this.props.editor;

    return (<Card border='info'>
      <Card.Body>
        <APIErrorAlert msg={this.props.error} />
        <Card.Title>{this.props.header}</Card.Title>
        <Card.Text>{this.props.description}</Card.Text>

        <Drawer
          onClose={this.onDrawerToggle}
          title={this.props.header}
          isOpen={this.state.drawerOpen}>
          <div className={BlueprintClasses.DRAWER_BODY}>
            <div className={BlueprintClasses.DIALOG_BODY}>

              {this.props.children}

              <Editor
                item={this.props.item}
                readOnly={this.props.item.request || this.props.disabled}
                value={this.props.item.data || null}
                onChange={this.onChange}
                onSave={this.onCreate}
                saved={false}
                create={true}
                />
            </div>
          </div>
        </Drawer>
      </Card.Body>

      <Card.Footer>
        <Button
          variant='info'
          onClick={this.onDrawerToggle}
          disabled={this.props.disabled}
        >
          Create
        </Button>
      </Card.Footer>
    </Card>);
    }
  }

export default GenericCreationCard;
