/*
* core/static/core/react/core/components/CountdownClock.jsx
* Quizzera
* Author: Rushy Panchal
* Date: September 19th, 2016
* Description: A clock (as text) that counts down when the timer is less than
* 	an hour.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/*
* A CountdownClock component is a clock, displayed as text, then begins to
* count down when the time remaining is less than an hour.
*
* Required Props
* 	Date end_time - time to end the countdown
* 
* Optional Props
* 	String future_msg - message for when time is upcoming
* 	String past_msg - message for when the time has passed
*
*/
class CountdownClock extends React.Component {
	constructor(props) {
		super(props);
		this.__interval_id = null;

		this.state = {
			remaining_time: null,
			past: false,
			};
		}

	static calculateRemainingTime(time) {
		/* Get the remaining time as an object of time units. */
		var time_diff = moment(time).diff(new Date(Date.now())),
			duration = moment.duration(time_diff, 'milliseconds');

		return duration;
		}

	static getDerivedStateFromProps(props, state) {
		/* This must be done after initializing state as calculateRemainingTime
		relies on state for the computations. */
		let remainder = CountdownClock.calculateRemainingTime(props.end_time);
		return {remaining_time: remainder};
		}

	componentDidMount() {
		/* Set an interval to repeatedly update the remaining time. */
		this.__interval_id = setInterval(() => {
			let remainder = CountdownClock.calculateRemainingTime(this.props.end_time);

			this.setState({remaining_time: remainder});

			if (remainder <= 0) {
				this.setState({past: true});
				}
			}, 1000);
		}

	componentWillUnmount() {
		/* Clear the interval before the component unmounts.. */
		clearInterval(this.__interval_id);
		}

	render() {
		/* Render the component. */
		var rem = this.state.remaining_time,
			rem_display = null;

		if (rem === null) {
			rem = moment.duration(0);
			}

		if (rem.days() > 0 || rem.hours() > 0) {
			rem_display = moment(this.props.end_time).fromNow(true);
			}
		else {
			rem_display = (rem.minutes() + ':' +
 				(rem.seconds() >= 10 ? rem.seconds(): '0' + rem.seconds()));
			}
	
		if (this.state.past) {
			return (<span>{this.props.past_msg}</span>);
			}

		var prefix = this.props.future_msg;
		if (prefix) prefix += ' ';

		return (<span>{prefix}<span {...this.props}>{rem_display}</span></span>);
		}
	}

CountdownClock.propTypes = {
	future_msg: PropTypes.string,
	past_msg: PropTypes.string,
	}

CountdownClock.defaultProps = {
	future_msg: '',
	past_msg: '',
	}

export default CountdownClock;
