/*
* RedBlackTreeColoringExercise/RedBlackTreeColoringExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ArrayInputTree from '../../quizgen/web/questions/ArrayInputTree.jsx';

function RedBlackTreeColoringExercise(props) {
  return (<ArrayInputTree {...props} />);
}

export default RedBlackTreeColoringExercise;
