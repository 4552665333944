/*
* pages/admin/quizzes/QuizzesController.jsx
* Author: Rushy Panchal
* Date: July 31st, 2019
* Description: Quiz editing controller.
*/

import React from 'react';
import { connect } from 'react-redux';
import { fromPairs, find, cloneDeep } from 'lodash';

import { Quiz } from 'lib/api';
import { actions, selectors } from 'state/resources';
import { getErrorMsg } from 'lib/utils';
import QuizzesPage from './QuizzesPage';

const QUIZ_LOAD_KEY = 'admin-load-quiz';
const QUIZ_CREATE_KEY = 'admin-create-quiz';

class QuizzesController extends React.Component {
  componentDidMount() {
    const { id } = this.props;
    const action = actions.loadResourceList(
      Quiz,
      QUIZ_LOAD_KEY,
      {course_id: id});
    this.props.dispatch(action);
    }

  render() {
    const { dispatch, ...other } = this.props;
    return (<QuizzesPage
      {...other}
      course_id={Number(this.props.id)}
      onQuizChange={this.onQuizChange}
      onQuizSave={this.onQuizSave}
      onQuizCreate={this.onQuizCreate}
      onQuizDelete={this.onQuizDelete}
      loadQuestions={this.loadQuestions}
    />);
    }

  onQuizChange = (id, update) => {
    const action = actions.editResource(Quiz, id, update);
    return this.props.dispatch(action);
    }

  onQuizSave = (id) => {
    const quiz = find(this.props.quizzes, q => q.id === Number(id));
    if (quiz === null || quiz === undefined) return;

    const action = actions.updateResource(Quiz, id, quiz);
    return this.props.dispatch(action);
    }

  onQuizCreate = (data) => {
    let quiz = cloneDeep(data);
    quiz.id = null;
    quiz.course_id = this.props.id;
    quiz.index = Math.max(0, ...this.props.quizzes.map(x => x.index)) + 1;

    const action = actions.createResource(
      Quiz,
      QUIZ_CREATE_KEY,
      quiz);
    return this.props.dispatch(action);
    }

  onQuizDelete = (id) => {
    const action = actions.deleteResource(Quiz, id);
    return this.props.dispatch(action);
    }
  }

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const quizzes = selectors.getResource(state, Quiz);
  const pending = selectors.getPending(quizzes, QUIZ_LOAD_KEY);

  const course_quizzes = selectors.filterResource(
    quizzes,
    {course_id: Number(id)});
  const quiz_saved = fromPairs(
    course_quizzes.map(q => [q.id, selectors.isSaved(quizzes, q.id)]));
  const quiz_pending = fromPairs(
    course_quizzes.map(q =>
      [q.id, selectors.getPending(quizzes, q.id.toString())]
    ));
  const createPending = selectors.getPending(quizzes, QUIZ_CREATE_KEY);

  return {
    quizzes: course_quizzes,
    saved: quiz_saved,
    quiz_pending: quiz_pending,

    loading: pending.loading,
    error: getErrorMsg(pending.error),

    createLoading: createPending.loading,
    createError: getErrorMsg(createPending.error),
    };
  }

export default connect(mapStateToProps)(QuizzesController);
