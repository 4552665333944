/*
* lib/store.ts
* Author: Rushy Panchal
* Date: June 19th, 2019
* Description: Local storage library.
*/

function set_json(key : string, data : object) : void {
  /*
  * Store a JSON object at key.
  */
  localStorage.setItem(key, JSON.stringify(data));
  }

function get_json(key : string) : object | null {
  /*
  * Retrieve a JSON object at key.
  */
  const stored = localStorage.getItem(key);
  if (stored !== null) {
    return JSON.parse(stored);
    }

  return null;
  }

function remove(key : string) : void {
  /*
  * Delete a key.
  */
  localStorage.removeItem(key);
  }

function keys(): string[] {
  /*
  * Return a list of all the keys present.
  */
  return Object.keys(localStorage);
  }

export {
  set_json,
  get_json,
  remove,
  keys
  };
