/*
* core/static/core/react/core/components/Loader.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Loading spinner.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import HiddenComponent from './HiddenComponent.jsx';

/*
* The Loader component is a simple spinner. It is displayed if the 'loading'
* prop is true, and hidden otherwise.
*
* Optional Props
* 	Boolean loading - whether or not to display the loading spinner
*
*/
function Loader(props) {
	if (props.loading) return (<div className={props.className}></div>);
	else return (<HiddenComponent hidden={true}></HiddenComponent>);
	}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired
  };

Loader.defaultProps = {
	loading: true,
	className: 'loader',
	};

export default React.memo(Loader);
