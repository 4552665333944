/*
* DoublingHypothesisExercise/DoublingHypothesisExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import NumericQuestion from '../../quizgen/web/questions/NumericQuestion.jsx';

function DoublingHypothesisExercise(props) {
  return (<NumericQuestion precision={0.01} {...props} />);
}

export default DoublingHypothesisExercise;
