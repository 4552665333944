/*
* TwoThreeTreeInsertionExercise/TwoThreeTreeInsertionExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ArrayQuestion from '../../quizgen/web/questions/ArrayQuestion.jsx';

function TwoThreeTreeInsertionExercise(props) {
  return (<ArrayQuestion {...props} />);
}

export default TwoThreeTreeInsertionExercise;
