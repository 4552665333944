/*
* questions/static/react/quizgen/GenericQuizgenQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import DefaultQuestionExplanation from '../DefaultQuestionExplanation.jsx';
import runValidators from '../input/validators.jsx';

/*
* The GenericQuizgenQuestion component consists of all required
* parts of a question from Quizgen: prompt, input, submit button, and
* explanation/answer/grade display.
*/
function GenericQuizgenQuestion(props) {
	var InputElement = props.input;

	var answerPrompt = null;
	if (props.data.answerPrompt) {
		answerPrompt = (<span className='aside'>{props.data.answerPrompt}</span>);
		}

	var prompt = (<pre className="word-wrapped">{props.data.prompt}</pre>);

	if (props.prompt && props.data.promptPretty) {
		var PromptElement = props.prompt;
		prompt = (<React.Fragment>
			<pre className="word-wrapped">
				{props.data.promptPretty}
			</pre>
			<PromptElement data={props.data.promptPrettyStructure} />
		</React.Fragment>);
		}

	return (<div>
		{/* Prompt display. */}
		<Row><Col xs={12}><h3>Question</h3></Col></Row>
		<Row><Col xs={12}>{prompt}</Col></Row>
		<hr/>

		{/* Answer submission. */}
		<div id ="submission">
			<Row><Col xs={12}><h3>Answer</h3></Col></Row>
			<Row><Col xs={12}>
				<InputElement
					attempt={props.inputData? props.data: null}
					answerPrompt={answerPrompt}
					value={props.value}
					readOnly={props.readOnly}
					onChange={(value, validators) => {
						let allValidators = (validators || []).concat(props.validators);
						let validationErrors = runValidators(value, allValidators);

						props.onError(validationErrors);
						props.onChange(value);
						}}
						{...props.inputProps}
						/>
			</Col></Row>
		</div>

		{/* Answer display. */}
		<Row id="answer-section"><Col xs={12}>
			<DefaultQuestionExplanation data={props.data} />
		</Col></Row>
		</div>);
	}

GenericQuizgenQuestion.propTypes = {
	data: PropTypes.object.isRequired,
	readOnly: PropTypes.bool,
	onError: PropTypes.func,
	onChange: PropTypes.func,
	};

GenericQuizgenQuestion.defaultProps = {
	readOnly: false,
	};

export default GenericQuizgenQuestion;
