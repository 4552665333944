/*
* pages/student/question/FinishedAttempt.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Display a finished attempt.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

import LoadedProvider from './LoadedProvider';

class FinishedAttempt extends React.PureComponent {
  static propTypes = {
    provider: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    answer: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    max_score: PropTypes.number.isRequired,
    };

  render() {
    return (<div>
      <LoadedProvider
        provider={this.props.provider}
        data={this.props.data}
        value={this.props.answer}
        readOnly />
      <h3>
        Score:&nbsp;
        {this.props.score}/{this.props.max_score}
      </h3>
      </div>);
    }
  }

export default FinishedAttempt;
