/*
* core/static/react/core/components/IgnorableWarnings.jsx
* Quizzera
* Author: Rushy Panchal
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Button } from 'react-bootstrap';

import StatusCheck from './StatusCheck.jsx';
import HiddenComponent from './HiddenComponent.jsx';

/*
* IgnorableWarnings is a collection of alerts (for warnings or errors) that
* can be ignored by the user.
*
* Required Props
* 	[String] messages - messages to display
*
* Optional Props
* 	String header - header for each warning
* 	String level - warning or danger levels
* 	Boolean hideOnIgnore - hide message when ignored
* 	Function onTotalIgnore - callback for when all messages are ignored
* 	String ignoreMsg - message for ignore button
* 	String unignoreMsg - message to undo ignore
*/
class IgnorableWarnings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.ignored_count = props.messages.length;
		}

	changeIgnore(index) {
		/* Change the ignore status of the given index. */
		var ignored = ! this.state[index];
		if (ignored) this.ignored_count++;
		else this.ignored_count--;

		var stateUpdate = {};
		stateUpdate[index] = ignored;
		this.setState(stateUpdate);

		if (this.ignored_count === this.props.messages.length) {
			this.props.onTotalIgnore();
			}
		}

	componentWillReceiveProps(nextProps) {
		/* Hook before the component receives new props. */
		var nextMessages = nextProps.messages,
			stateUpdate = {};

		/* For all new messages that are currently marked as true, mark as
		false. */
		for (var index = 0; index < nextMessages.length; index++) {
			if (this.state[index] &&
				nextMessages[index] !== this.props.messages[index]) {
				stateUpdate[index] = false;
				this.ignored_count--;
				}
			}

		this.setState(stateUpdate);
		}

	render() {
		/* Render the component. */
		return (<div>
			{this.props.messages.map((msg, index) => {
				return (<HiddenComponent hidden={this.props.hideOnIgnore &&
					this.state[index]} key={msg}>
					<Alert warning={this.props.level}>
						<Row>
							<Col md={8} xs={12}>
								<h4><StatusCheck success={this.state[index] || false} />
								&nbsp;{this.props.header}</h4>
								<p>{msg}</p>
							</Col>
							<Col md={4} xs={12}>
								<Button
									onClick={() => this.changeIgnore(index)}
									variant="primary"
									size="sm">
									{this.state[index]? this.props.unignoreMsg:
										this.props.ignoreMsg}
								</Button>
							</Col>
						</Row>
						</Alert>
					</HiddenComponent>);
				})}
			</div>);
		}
	}

IgnorableWarnings.propTypes = {
	messages: PropTypes.arrayOf(PropTypes.string).isRequired,
	header: PropTypes.string,
	level: PropTypes.string,
	hideOnIgnore: PropTypes.bool,
	onTotalIgnore: PropTypes.func,
	ignoreMsg: PropTypes.string,
	unignoreMsg: PropTypes.string,
	};

IgnorableWarnings.defaultProps = {
	header: '',
	level: 'warning',
	hideOnIgnore: false,
	onTotalIgnore: () => null,
	ignoreMsg: 'Ignore',
	unignoreMsg: 'Unignore',
	};

export default IgnorableWarnings;
