/*
* pages/Login.jsx
* Author: Rushy Panchal
* Date: June 19th, 2019
* Description: Login page.
*/
import "styles/custom.scss"

import React from 'react';

import { APIClient } from 'lib/api';
import { absolute_url } from 'lib/utils';
import { LOGIN_URL } from 'lib/env';
import { RECEIVE_TOKEN_PATH } from './routes';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      request: false,
      error: '',
      email: '',
      password: ''};

    this.client = new APIClient();
    this.client.setErrorHandler(e => this.setState({error: e}));
    }

  onSubmit = () => {
    this.setState({request: true});

    this.client
      .authenticate(this.state.email, this.state.password)
      .then(() => this.setState({redirect: true}))
      .finally(() => this.setState({request: false}));
    }

  handleChange = (e) => this.setState({[e.target.name]: e.target.value});

  render() {
    const { location } = this.props;
    var next = {pathname: "/"};
    if (location.state !== null && location.state !== undefined) {
      next = location.state.from;
      }

    const token_receiver = absolute_url(RECEIVE_TOKEN_PATH);
    const auth_handoff = next.pathname;
    const redirectUrl = `${LOGIN_URL}?redirect=${token_receiver}&auth_redirect=${auth_handoff}`;
    window.location = redirectUrl;
    return <div></div>;
    }
  }

export default LoginPage;
