/*
* store.ts
* Author: Rushy Panchal
* Date: July 21st, 2019
* Description: Redux store creation.
*/

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';

import resources from './resources';

export default function configureStore(initialState: object) {
  const rootReducer = combineReducers({
    resources,
    });

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunkMiddleware),
  );
}
