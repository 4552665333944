/*
* quizzera-modules.jsx
* Author: Rushy Panchal
* Date Generated: 2019-11-10T15:42:11.982888
* Description: Listing of all Quizzera modules, namespaced by their
*              institution, group, and module name.
*              The module itself exports a single function to load
*              a question module by full provider name.
*/


  import princeton_cos226_PropertiesOfQuicksortExercise from './princeton/cos226/PropertiesOfQuicksortExercise/PropertiesOfQuicksortExercise.jsx';

  import princeton_cos226_ReductionsClassifyExercise from './princeton/cos226/ReductionsClassifyExercise/ReductionsClassifyExercise.jsx';

  import princeton_cos226_Q226CollaborationCode from './princeton/cos226/Q226CollaborationCode/Q226CollaborationCode.jsx';

  import princeton_cos226_ProblemIdentificationStringExercise from './princeton/cos226/ProblemIdentificationStringExercise/ProblemIdentificationStringExercise.jsx';

  import princeton_cos226_HuffmanExercise from './princeton/cos226/HuffmanExercise/HuffmanExercise.jsx';

  import princeton_cos226_PvsNPExercise from './princeton/cos226/PvsNPExercise/PvsNPExercise.jsx';

  import princeton_cos226_PropertiesOfHeapsExercise from './princeton/cos226/PropertiesOfHeapsExercise/PropertiesOfHeapsExercise.jsx';

  import princeton_cos226_LinearProgrammingLeavingVariableExercise from './princeton/cos226/LinearProgrammingLeavingVariableExercise/LinearProgrammingLeavingVariableExercise.jsx';

  import princeton_cos226_PropertiesOfElementarySortsExercise from './princeton/cos226/PropertiesOfElementarySortsExercise/PropertiesOfElementarySortsExercise.jsx';

  import princeton_cos226_WeightedQuickUnionGenerabilityExercise from './princeton/cos226/WeightedQuickUnionGenerabilityExercise/WeightedQuickUnionGenerabilityExercise.jsx';

  import princeton_cos226_HeapConstructionExercise from './princeton/cos226/HeapConstructionExercise/HeapConstructionExercise.jsx';

  import princeton_cos226_PartialMergesortExercise from './princeton/cos226/PartialMergesortExercise/PartialMergesortExercise.jsx';

  import princeton_cos226_PropertiesOfMergesortExercise from './princeton/cos226/PropertiesOfMergesortExercise/PropertiesOfMergesortExercise.jsx';

  import princeton_cos226_WeightedQuickUnionExercise from './princeton/cos226/WeightedQuickUnionExercise/WeightedQuickUnionExercise.jsx';

  import princeton_cos226_SortingAnalysisExercise from './princeton/cos226/SortingAnalysisExercise/SortingAnalysisExercise.jsx';

  import princeton_cos226_SequenceAnalysisExercise from './princeton/cos226/SequenceAnalysisExercise/SequenceAnalysisExercise.jsx';

  import princeton_cos226_TopologicalExercise from './princeton/cos226/TopologicalExercise/TopologicalExercise.jsx';

  import princeton_cos226_IntractabilityDefinitionsExercise from './princeton/cos226/IntractabilityDefinitionsExercise/IntractabilityDefinitionsExercise.jsx';

  import princeton_cos226_RedBlackTreeColoringExercise from './princeton/cos226/RedBlackTreeColoringExercise/RedBlackTreeColoringExercise.jsx';

  import princeton_cos226_PropertiesOfMSTsExercise from './princeton/cos226/PropertiesOfMSTsExercise/PropertiesOfMSTsExercise.jsx';

  import princeton_cos226_TreeHeightExercise from './princeton/cos226/TreeHeightExercise/TreeHeightExercise.jsx';

  import princeton_cos226_MincutExercise from './princeton/cos226/MincutExercise/MincutExercise.jsx';

  import princeton_cos226_LinearProgrammingValueOfVariableExercise from './princeton/cos226/LinearProgrammingValueOfVariableExercise/LinearProgrammingValueOfVariableExercise.jsx';

  import princeton_cos226_HeapInsertExercise from './princeton/cos226/HeapInsertExercise/HeapInsertExercise.jsx';

  import princeton_cos226_LinearProbingExercise from './princeton/cos226/LinearProbingExercise/LinearProbingExercise.jsx';

  import princeton_cos226_KruskalExercise from './princeton/cos226/KruskalExercise/KruskalExercise.jsx';

  import princeton_cos226_TernarySearchTrieExercise from './princeton/cos226/TernarySearchTrieExercise/TernarySearchTrieExercise.jsx';

  import princeton_cos226_ProblemIdentificationGraphExercise from './princeton/cos226/ProblemIdentificationGraphExercise/ProblemIdentificationGraphExercise.jsx';

  import princeton_cos226_MSDExercise from './princeton/cos226/MSDExercise/MSDExercise.jsx';

  import princeton_cos226_PriorityQueueAnalysisExercise from './princeton/cos226/PriorityQueueAnalysisExercise/PriorityQueueAnalysisExercise.jsx';

  import princeton_cos226_PartialInsertionSelectionExercise from './princeton/cos226/PartialInsertionSelectionExercise/PartialInsertionSelectionExercise.jsx';

  import princeton_cos226_Q226CollaborationHelp from './princeton/cos226/Q226CollaborationHelp/Q226CollaborationHelp.jsx';

  import princeton_cos226_HibbardDeletionExercise from './princeton/cos226/HibbardDeletionExercise/HibbardDeletionExercise.jsx';

  import princeton_cos226_ReductionsLowerBoundsExercise from './princeton/cos226/ReductionsLowerBoundsExercise/ReductionsLowerBoundsExercise.jsx';

  import princeton_cos226_LinearProgrammingEnteringVariableExercise from './princeton/cos226/LinearProgrammingEnteringVariableExercise/LinearProgrammingEnteringVariableExercise.jsx';

  import princeton_cos226_DirectedBreadthFirstSearchExercise from './princeton/cos226/DirectedBreadthFirstSearchExercise/DirectedBreadthFirstSearchExercise.jsx';

  import princeton_cos226_PartialRadixSortingExercise from './princeton/cos226/PartialRadixSortingExercise/PartialRadixSortingExercise.jsx';

  import princeton_cos226_TimingExercise from './princeton/cos226/TimingExercise/TimingExercise.jsx';

  import princeton_cos226_LineSegmentIntersectionExercise from './princeton/cos226/LineSegmentIntersectionExercise/LineSegmentIntersectionExercise.jsx';

  import princeton_cos226_TwoThreeTreeInsertionExercise from './princeton/cos226/TwoThreeTreeInsertionExercise/TwoThreeTreeInsertionExercise.jsx';

  import princeton_cos226_PropertiesOfBSTsExercise from './princeton/cos226/PropertiesOfBSTsExercise/PropertiesOfBSTsExercise.jsx';

  import princeton_cos226_PropertiesOfMaxflowMincutExercise from './princeton/cos226/PropertiesOfMaxflowMincutExercise/PropertiesOfMaxflowMincutExercise.jsx';

  import princeton_cos226_DepthFirstSearchExercise from './princeton/cos226/DepthFirstSearchExercise/DepthFirstSearchExercise.jsx';

  import princeton_cos226_KnuthMorrisPrattConstructionExercise from './princeton/cos226/KnuthMorrisPrattConstructionExercise/KnuthMorrisPrattConstructionExercise.jsx';

  import princeton_cos226_BinarySearchExercise from './princeton/cos226/BinarySearchExercise/BinarySearchExercise.jsx';

  import princeton_cos226_SeparateChainingExercise from './princeton/cos226/SeparateChainingExercise/SeparateChainingExercise.jsx';

  import princeton_cos226_QueueGenerabilityExercise from './princeton/cos226/QueueGenerabilityExercise/QueueGenerabilityExercise.jsx';

  import princeton_cos226_ProblemIdentificationExercise from './princeton/cos226/ProblemIdentificationExercise/ProblemIdentificationExercise.jsx';

  import princeton_cos226_OrderOfGrowthExercise from './princeton/cos226/OrderOfGrowthExercise/OrderOfGrowthExercise.jsx';

  import princeton_cos226_NFAConstructionExercise from './princeton/cos226/NFAConstructionExercise/NFAConstructionExercise.jsx';

  import princeton_cos226_SetAnalysisExercise from './princeton/cos226/SetAnalysisExercise/SetAnalysisExercise.jsx';

  import princeton_cos226_IntervalSearchTreeExercise from './princeton/cos226/IntervalSearchTreeExercise/IntervalSearchTreeExercise.jsx';

  import princeton_cos226_RedBlackTreeInsertionExercise from './princeton/cos226/RedBlackTreeInsertionExercise/RedBlackTreeInsertionExercise.jsx';

  import princeton_cos226_InsertionSortExercise from './princeton/cos226/InsertionSortExercise/InsertionSortExercise.jsx';

  import princeton_cos226_GrahamScanExercise from './princeton/cos226/GrahamScanExercise/GrahamScanExercise.jsx';

  import princeton_cos226_StackGenerabilityExercise from './princeton/cos226/StackGenerabilityExercise/StackGenerabilityExercise.jsx';

  import princeton_cos226_BreadthFirstSearchExercise from './princeton/cos226/BreadthFirstSearchExercise/BreadthFirstSearchExercise.jsx';

  import princeton_cos226_BellmanFordExercise from './princeton/cos226/BellmanFordExercise/BellmanFordExercise.jsx';

  import princeton_cos226_MergesortBUExercise from './princeton/cos226/MergesortBUExercise/MergesortBUExercise.jsx';

  import princeton_cos226_BinarySearchTreeSearchExercise from './princeton/cos226/BinarySearchTreeSearchExercise/BinarySearchTreeSearchExercise.jsx';

  import princeton_cos226_Q226CollaborationPartnering from './princeton/cos226/Q226CollaborationPartnering/Q226CollaborationPartnering.jsx';

  import princeton_cos226_PartitionExercise from './princeton/cos226/PartitionExercise/PartitionExercise.jsx';

  import princeton_cos226_Partition3StringExercise from './princeton/cos226/Partition3StringExercise/Partition3StringExercise.jsx';

  import princeton_cos226_KdTreeConstructionExercise from './princeton/cos226/KdTreeConstructionExercise/KdTreeConstructionExercise.jsx';

  import princeton_cos226_PrimExercise from './princeton/cos226/PrimExercise/PrimExercise.jsx';

  import princeton_cos226_MemoryExercise from './princeton/cos226/MemoryExercise/MemoryExercise.jsx';

  import princeton_cos226_DijkstraExercise from './princeton/cos226/DijkstraExercise/DijkstraExercise.jsx';

  import princeton_cos226_HeapDeleteExercise from './princeton/cos226/HeapDeleteExercise/HeapDeleteExercise.jsx';

  import princeton_cos226_RabinKarpExercise from './princeton/cos226/RabinKarpExercise/RabinKarpExercise.jsx';

  import princeton_cos226_AcyclicShortestPathsExercise from './princeton/cos226/AcyclicShortestPathsExercise/AcyclicShortestPathsExercise.jsx';

  import princeton_cos226_PartialSortingLiteExercise from './princeton/cos226/PartialSortingLiteExercise/PartialSortingLiteExercise.jsx';

  import princeton_cos226_PropertiesOfShortestPathsExercise from './princeton/cos226/PropertiesOfShortestPathsExercise/PropertiesOfShortestPathsExercise.jsx';

  import princeton_cos226_LZWExpandExercise from './princeton/cos226/LZWExpandExercise/LZWExpandExercise.jsx';

  import princeton_cos226_MemoryOfStackOrQueueExercise from './princeton/cos226/MemoryOfStackOrQueueExercise/MemoryOfStackOrQueueExercise.jsx';

  import princeton_cos226_MergesortExercise from './princeton/cos226/MergesortExercise/MergesortExercise.jsx';

  import princeton_cos226_LZWCompressExercise from './princeton/cos226/LZWCompressExercise/LZWCompressExercise.jsx';

  import princeton_cos226_BurrowsWheelerTransformExercise from './princeton/cos226/BurrowsWheelerTransformExercise/BurrowsWheelerTransformExercise.jsx';

  import princeton_cos226_QuickFindExercise from './princeton/cos226/QuickFindExercise/QuickFindExercise.jsx';

  import princeton_cos226_ShellsortExercise from './princeton/cos226/ShellsortExercise/ShellsortExercise.jsx';

  import princeton_cos226_ConnectedComponentsExercise from './princeton/cos226/ConnectedComponentsExercise/ConnectedComponentsExercise.jsx';

  import princeton_cos226_LinearProgrammingModelingExercise from './princeton/cos226/LinearProgrammingModelingExercise/LinearProgrammingModelingExercise.jsx';

  import princeton_cos226_BoyerMooreExercise from './princeton/cos226/BoyerMooreExercise/BoyerMooreExercise.jsx';

  import princeton_cos226_PropertiesOfBalancedSearchTreesExercise from './princeton/cos226/PropertiesOfBalancedSearchTreesExercise/PropertiesOfBalancedSearchTreesExercise.jsx';

  import princeton_cos226_FordFulkersonExercise from './princeton/cos226/FordFulkersonExercise/FordFulkersonExercise.jsx';

  import princeton_cos226_BurrowsWheelerInverseTransformExercise from './princeton/cos226/BurrowsWheelerInverseTransformExercise/BurrowsWheelerInverseTransformExercise.jsx';

  import princeton_cos226_NFASimulationExercise from './princeton/cos226/NFASimulationExercise/NFASimulationExercise.jsx';

  import princeton_cos226_PropertiesOfInsertionSelectionExercise from './princeton/cos226/PropertiesOfInsertionSelectionExercise/PropertiesOfInsertionSelectionExercise.jsx';

  import princeton_cos226_NPCompleteExercise from './princeton/cos226/NPCompleteExercise/NPCompleteExercise.jsx';

  import princeton_cos226_SelectionSortExercise from './princeton/cos226/SelectionSortExercise/SelectionSortExercise.jsx';

  import princeton_cos226_BinarySearchTreeConstructionExercise from './princeton/cos226/BinarySearchTreeConstructionExercise/BinarySearchTreeConstructionExercise.jsx';

  import princeton_cos226_PartialElementarySortsExercise from './princeton/cos226/PartialElementarySortsExercise/PartialElementarySortsExercise.jsx';

  import princeton_cos226_ReductionsDesignExercise from './princeton/cos226/ReductionsDesignExercise/ReductionsDesignExercise.jsx';

  import princeton_cos226_HeapifyExercise from './princeton/cos226/HeapifyExercise/HeapifyExercise.jsx';

  import princeton_cos226_DoublingHypothesisExercise from './princeton/cos226/DoublingHypothesisExercise/DoublingHypothesisExercise.jsx';

  import princeton_cos226_KosarajuSharirExercise from './princeton/cos226/KosarajuSharirExercise/KosarajuSharirExercise.jsx';

  import princeton_cos226_LSDExercise from './princeton/cos226/LSDExercise/LSDExercise.jsx';

  import princeton_cos226_MultiwayTrieExercise from './princeton/cos226/MultiwayTrieExercise/MultiwayTrieExercise.jsx';

  import princeton_cos226_Partition3wayExercise from './princeton/cos226/Partition3wayExercise/Partition3wayExercise.jsx';

  import princeton_cos226_PartitionDegenerateExercise from './princeton/cos226/PartitionDegenerateExercise/PartitionDegenerateExercise.jsx';

  import princeton_cos226_LinearProbingGenerabilityExercise from './princeton/cos226/LinearProbingGenerabilityExercise/LinearProbingGenerabilityExercise.jsx';


var _modules = {};

function load_question_module(provider) {
  /*
  * Load the module located at `provider`. If not found, returns
  * null.
  */
  return _modules[provider] || null;
  }


  _modules['princeton.cos226.PropertiesOfQuicksortExercise'] = princeton_cos226_PropertiesOfQuicksortExercise;

  _modules['princeton.cos226.ReductionsClassifyExercise'] = princeton_cos226_ReductionsClassifyExercise;

  _modules['princeton.cos226.Q226CollaborationCode'] = princeton_cos226_Q226CollaborationCode;

  _modules['princeton.cos226.ProblemIdentificationStringExercise'] = princeton_cos226_ProblemIdentificationStringExercise;

  _modules['princeton.cos226.HuffmanExercise'] = princeton_cos226_HuffmanExercise;

  _modules['princeton.cos226.PvsNPExercise'] = princeton_cos226_PvsNPExercise;

  _modules['princeton.cos226.PropertiesOfHeapsExercise'] = princeton_cos226_PropertiesOfHeapsExercise;

  _modules['princeton.cos226.LinearProgrammingLeavingVariableExercise'] = princeton_cos226_LinearProgrammingLeavingVariableExercise;

  _modules['princeton.cos226.PropertiesOfElementarySortsExercise'] = princeton_cos226_PropertiesOfElementarySortsExercise;

  _modules['princeton.cos226.WeightedQuickUnionGenerabilityExercise'] = princeton_cos226_WeightedQuickUnionGenerabilityExercise;

  _modules['princeton.cos226.HeapConstructionExercise'] = princeton_cos226_HeapConstructionExercise;

  _modules['princeton.cos226.PartialMergesortExercise'] = princeton_cos226_PartialMergesortExercise;

  _modules['princeton.cos226.PropertiesOfMergesortExercise'] = princeton_cos226_PropertiesOfMergesortExercise;

  _modules['princeton.cos226.WeightedQuickUnionExercise'] = princeton_cos226_WeightedQuickUnionExercise;

  _modules['princeton.cos226.SortingAnalysisExercise'] = princeton_cos226_SortingAnalysisExercise;

  _modules['princeton.cos226.SequenceAnalysisExercise'] = princeton_cos226_SequenceAnalysisExercise;

  _modules['princeton.cos226.TopologicalExercise'] = princeton_cos226_TopologicalExercise;

  _modules['princeton.cos226.IntractabilityDefinitionsExercise'] = princeton_cos226_IntractabilityDefinitionsExercise;

  _modules['princeton.cos226.RedBlackTreeColoringExercise'] = princeton_cos226_RedBlackTreeColoringExercise;

  _modules['princeton.cos226.PropertiesOfMSTsExercise'] = princeton_cos226_PropertiesOfMSTsExercise;

  _modules['princeton.cos226.TreeHeightExercise'] = princeton_cos226_TreeHeightExercise;

  _modules['princeton.cos226.MincutExercise'] = princeton_cos226_MincutExercise;

  _modules['princeton.cos226.LinearProgrammingValueOfVariableExercise'] = princeton_cos226_LinearProgrammingValueOfVariableExercise;

  _modules['princeton.cos226.HeapInsertExercise'] = princeton_cos226_HeapInsertExercise;

  _modules['princeton.cos226.LinearProbingExercise'] = princeton_cos226_LinearProbingExercise;

  _modules['princeton.cos226.KruskalExercise'] = princeton_cos226_KruskalExercise;

  _modules['princeton.cos226.TernarySearchTrieExercise'] = princeton_cos226_TernarySearchTrieExercise;

  _modules['princeton.cos226.ProblemIdentificationGraphExercise'] = princeton_cos226_ProblemIdentificationGraphExercise;

  _modules['princeton.cos226.MSDExercise'] = princeton_cos226_MSDExercise;

  _modules['princeton.cos226.PriorityQueueAnalysisExercise'] = princeton_cos226_PriorityQueueAnalysisExercise;

  _modules['princeton.cos226.PartialInsertionSelectionExercise'] = princeton_cos226_PartialInsertionSelectionExercise;

  _modules['princeton.cos226.Q226CollaborationHelp'] = princeton_cos226_Q226CollaborationHelp;

  _modules['princeton.cos226.HibbardDeletionExercise'] = princeton_cos226_HibbardDeletionExercise;

  _modules['princeton.cos226.ReductionsLowerBoundsExercise'] = princeton_cos226_ReductionsLowerBoundsExercise;

  _modules['princeton.cos226.LinearProgrammingEnteringVariableExercise'] = princeton_cos226_LinearProgrammingEnteringVariableExercise;

  _modules['princeton.cos226.DirectedBreadthFirstSearchExercise'] = princeton_cos226_DirectedBreadthFirstSearchExercise;

  _modules['princeton.cos226.PartialRadixSortingExercise'] = princeton_cos226_PartialRadixSortingExercise;

  _modules['princeton.cos226.TimingExercise'] = princeton_cos226_TimingExercise;

  _modules['princeton.cos226.LineSegmentIntersectionExercise'] = princeton_cos226_LineSegmentIntersectionExercise;

  _modules['princeton.cos226.TwoThreeTreeInsertionExercise'] = princeton_cos226_TwoThreeTreeInsertionExercise;

  _modules['princeton.cos226.PropertiesOfBSTsExercise'] = princeton_cos226_PropertiesOfBSTsExercise;

  _modules['princeton.cos226.PropertiesOfMaxflowMincutExercise'] = princeton_cos226_PropertiesOfMaxflowMincutExercise;

  _modules['princeton.cos226.DepthFirstSearchExercise'] = princeton_cos226_DepthFirstSearchExercise;

  _modules['princeton.cos226.KnuthMorrisPrattConstructionExercise'] = princeton_cos226_KnuthMorrisPrattConstructionExercise;

  _modules['princeton.cos226.BinarySearchExercise'] = princeton_cos226_BinarySearchExercise;

  _modules['princeton.cos226.SeparateChainingExercise'] = princeton_cos226_SeparateChainingExercise;

  _modules['princeton.cos226.QueueGenerabilityExercise'] = princeton_cos226_QueueGenerabilityExercise;

  _modules['princeton.cos226.ProblemIdentificationExercise'] = princeton_cos226_ProblemIdentificationExercise;

  _modules['princeton.cos226.OrderOfGrowthExercise'] = princeton_cos226_OrderOfGrowthExercise;

  _modules['princeton.cos226.NFAConstructionExercise'] = princeton_cos226_NFAConstructionExercise;

  _modules['princeton.cos226.SetAnalysisExercise'] = princeton_cos226_SetAnalysisExercise;

  _modules['princeton.cos226.IntervalSearchTreeExercise'] = princeton_cos226_IntervalSearchTreeExercise;

  _modules['princeton.cos226.RedBlackTreeInsertionExercise'] = princeton_cos226_RedBlackTreeInsertionExercise;

  _modules['princeton.cos226.InsertionSortExercise'] = princeton_cos226_InsertionSortExercise;

  _modules['princeton.cos226.GrahamScanExercise'] = princeton_cos226_GrahamScanExercise;

  _modules['princeton.cos226.StackGenerabilityExercise'] = princeton_cos226_StackGenerabilityExercise;

  _modules['princeton.cos226.BreadthFirstSearchExercise'] = princeton_cos226_BreadthFirstSearchExercise;

  _modules['princeton.cos226.BellmanFordExercise'] = princeton_cos226_BellmanFordExercise;

  _modules['princeton.cos226.MergesortBUExercise'] = princeton_cos226_MergesortBUExercise;

  _modules['princeton.cos226.BinarySearchTreeSearchExercise'] = princeton_cos226_BinarySearchTreeSearchExercise;

  _modules['princeton.cos226.Q226CollaborationPartnering'] = princeton_cos226_Q226CollaborationPartnering;

  _modules['princeton.cos226.PartitionExercise'] = princeton_cos226_PartitionExercise;

  _modules['princeton.cos226.Partition3StringExercise'] = princeton_cos226_Partition3StringExercise;

  _modules['princeton.cos226.KdTreeConstructionExercise'] = princeton_cos226_KdTreeConstructionExercise;

  _modules['princeton.cos226.PrimExercise'] = princeton_cos226_PrimExercise;

  _modules['princeton.cos226.MemoryExercise'] = princeton_cos226_MemoryExercise;

  _modules['princeton.cos226.DijkstraExercise'] = princeton_cos226_DijkstraExercise;

  _modules['princeton.cos226.HeapDeleteExercise'] = princeton_cos226_HeapDeleteExercise;

  _modules['princeton.cos226.RabinKarpExercise'] = princeton_cos226_RabinKarpExercise;

  _modules['princeton.cos226.AcyclicShortestPathsExercise'] = princeton_cos226_AcyclicShortestPathsExercise;

  _modules['princeton.cos226.PartialSortingLiteExercise'] = princeton_cos226_PartialSortingLiteExercise;

  _modules['princeton.cos226.PropertiesOfShortestPathsExercise'] = princeton_cos226_PropertiesOfShortestPathsExercise;

  _modules['princeton.cos226.LZWExpandExercise'] = princeton_cos226_LZWExpandExercise;

  _modules['princeton.cos226.MemoryOfStackOrQueueExercise'] = princeton_cos226_MemoryOfStackOrQueueExercise;

  _modules['princeton.cos226.MergesortExercise'] = princeton_cos226_MergesortExercise;

  _modules['princeton.cos226.LZWCompressExercise'] = princeton_cos226_LZWCompressExercise;

  _modules['princeton.cos226.BurrowsWheelerTransformExercise'] = princeton_cos226_BurrowsWheelerTransformExercise;

  _modules['princeton.cos226.QuickFindExercise'] = princeton_cos226_QuickFindExercise;

  _modules['princeton.cos226.ShellsortExercise'] = princeton_cos226_ShellsortExercise;

  _modules['princeton.cos226.ConnectedComponentsExercise'] = princeton_cos226_ConnectedComponentsExercise;

  _modules['princeton.cos226.LinearProgrammingModelingExercise'] = princeton_cos226_LinearProgrammingModelingExercise;

  _modules['princeton.cos226.BoyerMooreExercise'] = princeton_cos226_BoyerMooreExercise;

  _modules['princeton.cos226.PropertiesOfBalancedSearchTreesExercise'] = princeton_cos226_PropertiesOfBalancedSearchTreesExercise;

  _modules['princeton.cos226.FordFulkersonExercise'] = princeton_cos226_FordFulkersonExercise;

  _modules['princeton.cos226.BurrowsWheelerInverseTransformExercise'] = princeton_cos226_BurrowsWheelerInverseTransformExercise;

  _modules['princeton.cos226.NFASimulationExercise'] = princeton_cos226_NFASimulationExercise;

  _modules['princeton.cos226.PropertiesOfInsertionSelectionExercise'] = princeton_cos226_PropertiesOfInsertionSelectionExercise;

  _modules['princeton.cos226.NPCompleteExercise'] = princeton_cos226_NPCompleteExercise;

  _modules['princeton.cos226.SelectionSortExercise'] = princeton_cos226_SelectionSortExercise;

  _modules['princeton.cos226.BinarySearchTreeConstructionExercise'] = princeton_cos226_BinarySearchTreeConstructionExercise;

  _modules['princeton.cos226.PartialElementarySortsExercise'] = princeton_cos226_PartialElementarySortsExercise;

  _modules['princeton.cos226.ReductionsDesignExercise'] = princeton_cos226_ReductionsDesignExercise;

  _modules['princeton.cos226.HeapifyExercise'] = princeton_cos226_HeapifyExercise;

  _modules['princeton.cos226.DoublingHypothesisExercise'] = princeton_cos226_DoublingHypothesisExercise;

  _modules['princeton.cos226.KosarajuSharirExercise'] = princeton_cos226_KosarajuSharirExercise;

  _modules['princeton.cos226.LSDExercise'] = princeton_cos226_LSDExercise;

  _modules['princeton.cos226.MultiwayTrieExercise'] = princeton_cos226_MultiwayTrieExercise;

  _modules['princeton.cos226.Partition3wayExercise'] = princeton_cos226_Partition3wayExercise;

  _modules['princeton.cos226.PartitionDegenerateExercise'] = princeton_cos226_PartitionDegenerateExercise;

  _modules['princeton.cos226.LinearProbingGenerabilityExercise'] = princeton_cos226_LinearProbingGenerabilityExercise;


export default load_question_module;