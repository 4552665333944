/*
* components/CollapsibleCard.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: A card that is collapsible based on a button.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Card, Button, Row, Col } from 'react-bootstrap';

class CollapsibleCard extends React.Component {
  static propTypes = {
    header: PropTypes.node,
    openText: PropTypes.node,
    closedText: PropTypes.node,
    variant: PropTypes.string,
    };

  static defaultProps = {
    header: null,
    openText: 'Close',
    closedText: 'Open',
    variant: 'dark',
    };

  state = {
    display: false,
    everOpened: false,
    };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    }

  onClick() {
    this.setState({display: !this.state.display, everOpened: true});
    }

  render() {
    let text = (this.state.display ?
                this.props.openText : this.props.closedText);

    return (<Card>
      <Card.Header>
        <Row>
          <Col xs={12} md={6}>
            <h5>{this.props.header}</h5>
          </Col>
          <Col xs={12} md={6}>
            <Button
              variant={this.props.variant}
              onClick={this.onClick}>
              {text}
            </Button>
          </Col>
        </Row>
      </Card.Header>

      <Collapse in={this.state.display}>
        <div>
          <Card.Body>
            {this.getChildren()}
          </Card.Body>
        </div>
      </Collapse>
    </Card>);
    }

  getChildren() {
    // Defer rendering the children until the display is first opened.
    if (this.state.everOpened) {
      return this.props.children;
      }

    return null;
    }
  }

export default CollapsibleCard;
