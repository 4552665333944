/*
* questions/static/react/quizgen/ArrayQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import GenericQuizgenQuestion from './GenericQuizgenQuestion.jsx';
import ArrayInput from '../input/ArrayInput.jsx';

/*
* An ArrayQuestion component handles input and submission
* of 'short answer' questions that accept arrays as input.
* Currently, the array input is simply a text input.
*/
class ArrayQuestion extends React.Component {
	render() {
		/* Render the component. */
		return (<GenericQuizgenQuestion input={ArrayInput} inputData={true}
			{...this.props} />);
		}
	}

export default ArrayQuestion;
