/*
* pages/components/DraggableCardCollection.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 17th, 2019
* Description: A vertical collection of cards, in which each card is dragabble
*              within the collection.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function DraggableCardCollection(props) {
  let { onDragEnd, disabled, children, ...other } = props;

  const getDraggableComponent = React.useCallback(
    (child, index) => 
      (<Draggable draggableId={child.key} index={index}>
        {(provided, snapshot) =>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {child}
          </div>
        }
      </Draggable>),
    []);

  const getDroppableComponent = React.useCallback(
    (provided, snapshot) =>
      (<div
        ref={provided.innerRef}
        className={snapshot.isDraggingOver ? 'dragging': '' }
        {...provided.droppableProps}
      >
        {React.Children.map(children, getDraggableComponent)}
        
        {/* Placeholder required by Droppable. */}
        {provided.placeholder}
      </div>),
  [children, getDraggableComponent]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} {...other}>
      <Droppable droppableId='droppable' isDropDisabled={disabled}>
      {getDroppableComponent}
      </Droppable>
    </DragDropContext>
    );
  }

DraggableCardCollection.propTypes = {
  onDragEnd: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  };

DraggableCardCollection.defaultProps = {
  disabled: false,
  };

export default React.memo(DraggableCardCollection);
