/*
* BinarySearchTreeConstructionExercise/BinarySearchTreeConstructionExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import TypedArrayQuestion from '../../quizgen/web/questions/TypedArrayQuestion.jsx';

function BinarySearchTreeConstructionExercise(props) {
  return (<TypedArrayQuestion type={TypedArrayQuestion.types.int} {...props} />);
}

export default BinarySearchTreeConstructionExercise;
