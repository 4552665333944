/*
* ProblemIdentificationGraphExercise/ProblemIdentificationGraphExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function ProblemIdentificationGraphExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default ProblemIdentificationGraphExercise;
