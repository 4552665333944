/*
* pages/admin/settings/SettingsController.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Controller for settings page.
*/

import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Course } from 'lib/api';
import { actions, selectors, types } from 'state/resources';
import { getErrorMsg, urljoin } from 'lib/utils';
import SettingsPage from './SettingsPage';

const CLONE_COURSE_KEY = 'admin-clone-course';

class SettingsController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectAfterDelete: false,
      clonedCourseId: null,
      };

    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.clone = this.clone.bind(this);
    }

  componentDidMount() {
    if (this.props.course === null || this.props.course === undefined) {
      const action = actions.loadResource(Course, this.props.id);
      this.props.dispatch(action);
      }
    }

  render() {
    if (this.state.redirectAfterDelete) {
      return <Redirect to='/' />;
      }
    else if (this.state.clonedCourseId !== null && this.props.cloneComplete) {
      const redirectUrl = urljoin(
        '/admin',
        this.state.clonedCourseId.toString());
      return <Redirect to={redirectUrl} />;
      }

    return (<SettingsPage
        error={getErrorMsg(this.props.error)}
        loading={this.props.loading}
        cloneLoading={this.props.cloneLoading}
        onChange={this.onChange}
        onSave={this.save}
        onClone={this.clone}
        saved={this.props.saved}
        value={this.props.course}
        onDelete={this.delete}
      />);
    }

  onChange(val) {
    const action = actions.editResource(Course, this.props.id, val);
    this.props.dispatch(action);
    }

  save() {
    const action = actions.updateResource(Course,
                                          this.props.id,
                                          this.props.course);
    this.props.dispatch(action);
    }

  delete() {
    const action = actions.deleteResource(Course, this.props.id);
    this.props.dispatch(action)
      .then(() => this.setState({redirectAfterDelete: true}));
    }

  clone() {
    const resource = new Course();
    const p = resource
      .clone(this.props.id)
      .tap(c => this.setState({clonedCourseId: c.id}));
    const action = actions.resolvePromise(
      p,
      resource.type,
      CLONE_COURSE_KEY,
      types.ACTIONS.CREATE_RESOURCE);
    this.props.dispatch(action);
    }
  }

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const courses = selectors.getResource(state, Course);
  const pending = selectors.getPending(courses, id.toString());
  const clonePending = selectors.getPending(courses, CLONE_COURSE_KEY);
  const saved = selectors.isSaved(courses, id);

  return {
    course: selectors.getSingleResource(courses, id),
    saved: saved,
    loading: pending.loading,
    cloneLoading: clonePending.loading,
    cloneComplete: clonePending.completed,
    error: pending.error || clonePending.error || '',
    };
  }

export default connect(mapStateToProps)(SettingsController);
