/*
* princeton/quizgen/web/input/BasicInput.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 11th, 2019
* Description: Implementation of basic question.
*/

import React from 'react';

/*
* The BasicInput component is used as a wrapper for implementing typed inputs.
*/
class BasicInput extends React.Component {
  render() {
    /* Render the component. */
    let { answerPrompt, onChange, value, ...other } = this.props;
    
    return (<React.Fragment>
      <p>{answerPrompt}</p>
      <input
        value={this.parseInputValue(this.props.type, value)}
        onChange={e => onChange(e.target.value)}
        {...other}>
       </input>
      </React.Fragment>);
    }

  parseInputValue(t, repr) {
    /* Parse the input value. */
    if (repr === '') {
      return '';
      }
    if (t === undefined || t === null) {
      return repr;
      }

    if (t === "number") {
      return Number(repr);
      }

    return repr;
    }
  }

BasicInput.defaultProps = {
  answerPrompt: (<p className='aside'>
      To input your answer, simply enter the string. If
      the question requires a specific format for the string, that format will
      be provided.</p>),
  };

export default BasicInput;
