/*
* pages/auth/TokenReceiver.jsx
* Author: Rushy Panchal
* Date: July 29th, 2019
* Description: Receives a JWT token and stores it for the application.
*/

import React from 'react';
import { Redirect } from 'react-router';
import queryString from 'query-string';

import { APIClient } from 'lib/api';

function TokenReceiver({location}) {
  const query = queryString.parse(location.search);
  const auth_token = JSON.parse(query.token);
  const redirectUrl = query.auth_redirect || '/';

  let client = new APIClient();
  client.authenticate_token(auth_token);

  return <Redirect to={redirectUrl} />;
  }

export default TokenReceiver;
