/*
* core/static/core/react/core/components/StatusCheck.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Status checkmark as an icon.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

/*
* A StatusCheck component is a simple icon that displays either a check or a
* cross, depending on the success prop.
*
* Optional Props
* 	Boolean success - (default: true) whether or not the action was successful
*
*/
function StatusCheck(props) {
	var className = "";
	if (props.success) {
		className = "icon icon-success ionicons ion-ios-checkmark";
		}
	else {
		className = "icon icon-failure ionicons ion-ios-close-outline";
		}

	return (<i className={className} style={props.style}></i>);
	}

StatusCheck.propTypes = {
	success: PropTypes.bool,
	style: PropTypes.object,
	};

StatusCheck.defaultProps = {
	success: true,
	style: {},
	};

export default React.memo(StatusCheck);
