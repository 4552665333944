/*
* MultiwayTrieExercise/MultiwayTrieExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import NumericQuestion from '../../quizgen/web/questions/NumericQuestion.jsx';

function MultiwayTrieExercise(props) {
  return (<NumericQuestion {...props} />);
}

export default MultiwayTrieExercise;
