/*
* lib/cache.ts
* Author: Rushy Panchal
* Date: July 14th, 2019
* Description: Local storage library.
*/

import moment from 'moment';

import * as store from './store';

interface CachedData {
  data: any;
  expires: moment.Moment;
  }

function store_key(key: string): string {
  /*
  * Compute the store key for a given key.
  */
  return key.startsWith('cache:') ? key : `cache:${key}`;
  }

function expired(stored: CachedData): boolean {
  /*
  * Check if a stored value is expired.
  */
  return ! moment(stored.expires).isAfter(moment());
  }

function set(key: string, data: any, expiry: moment.Duration) {
  /*
  * Cache a JSON object at `key`, expiring in `expiry` as moment.Duration.
  */
  const to_store: CachedData = {
    data: data,
    expires: moment().add(expiry)
    };

  return store.set_json(store_key(key), to_store);
  }

function update_if_exists(key: string, data: any, expiry?: moment.Duration) {
  /*
  * Update a JSON object at `key`, *only if it already exists.* If the
  * expiry is provided, it will be updated (otherwise, the existing expiry
  * time will be preserved).
  */
  const sk = store_key(key);
  let stored = store.get_json(sk) as CachedData;

  if (stored !== null) {
    stored.data = data;
    if (expiry !== undefined) {
      stored.expires = moment().add(expiry);
      }

    return store.set_json(sk, stored);
    }

  return null;
  }

function get(key: string): any | null {
  /*
  * Retrieve a JSON object at key.
  */
  const stored = store.get_json(store_key(key)) as CachedData;

  if (stored !== null) {
    if (! expired(stored)) return stored.data;
    else remove(key);
    }

  return null;
  }

function clean(): void {
  store
    .keys()
    .filter(key => key.startsWith('cache:'))
    .map(key => {return {key: key, value: store.get_json(key) as CachedData}})
    .filter(kv => kv.value !== null && expired(kv.value))
    .map(kv => kv.key)
    .forEach(remove);
  }

function remove(key: string) {
  /*
  * Delete a key.
  */
  return store.remove(store_key(key));
  }

function promise<T>(
    key: string,
    get_promise: () => Promise<T>,
    cache_duration?: moment.Duration
    ): Promise<T> {
  /*
  * Cache a promise if it does not already exist in the cache. If the duration
  * is undefined, the promise is always executed.
  */
  if (cache_duration === undefined) {
    return get_promise()
      .then(val => {
        update_if_exists(key, val);
        return val;
        });
    }

  const cached = get(key);
  if (cached !== null) {
    return new Promise(resolve => resolve(cached));
    }

  return get_promise()
    .then(val => {
      set(key, val, cache_duration);
      return val;
      });
  }

export {
  set,
  update_if_exists,
  get,
  remove,
  clean,
  promise,
  };
