/*
* Q226CollaborationPartnering/Q226CollaborationPartnering.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import SingleChoiceQuestion from '../../quizgen/web/questions/SingleChoiceQuestion.jsx';

function Q226CollaborationPartnering(props) {
  return (<SingleChoiceQuestion {...props} />);
}

export default Q226CollaborationPartnering;
