/*
* pages/admin/enrollments/EnrollmentsPage.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: View for editing enrollments.
*/

import "styles/custom.scss";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { Drawer, Classes as BlueprintClasses } from '@blueprintjs/core';
import { orderBy } from 'lodash';

import Loader from 'components/Loader';
import APIErrorAlert from 'components/APIErrorAlert';
import TableList from 'components/TableList.jsx';
import StatusCheck from 'components/StatusCheck.jsx';
import BulkEnrollmentEditor from './BulkEnrollmentEditor';

class EnrollmentsPage extends React.PureComponent {
  static propTypes = {
    enrollments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_admin: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        display_name: PropTypes.string.isRequired,
        }).isRequired,
      })),
    onEnroll: PropTypes.func.isRequired,

    loading: PropTypes.bool,
    error: PropTypes.string,
    createLoading: PropTypes.bool,
    createError: PropTypes.string,
    createCompleted: PropTypes.bool,
    };

  static defaultProps = {
    loading: false,
    error: '',
    createLoading: false,
    createError: '',
    createCompleted: false,
    };

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      pendingEnrollments: {
        is_admin: false,
        users: [],
        },
      };

    this.onDrawerToggle = this.onDrawerToggle.bind(this);
    }

  componentDidUpdate(prevProps) {
    if (!prevProps.createCompleted && this.props.createCompleted) {
      this.setState({drawerOpen: false});
      }
    }

  render() {
    return (<div className='container-fluid'>
      <APIErrorAlert msg={this.props.error} />
      <Loader loading={this.props.loading} />

      <Row>
        <Col xs={6} md={8}><h3>Course Enrollments</h3></Col>
        <Col xs={6} md={4}>
          <Button onClick={this.onDrawerToggle} variant='dark'>
            Enroll Users
          </Button>
        </Col>
      </Row>
      <hr/>

      <Drawer
        onClose={this.onDrawerToggle}
        isOpen={this.state.drawerOpen}
        title='Enroll Users'
      >
        <div className={BlueprintClasses.DRAWER_BODY}>
          <div className={BlueprintClasses.DIALOG_BODY}>
            <BulkEnrollmentEditor
              onChange={v => this.setState({pendingEnrollments: v})}
              value={this.state.pendingEnrollments}
              onEnroll={this.props.onEnroll}
              loading={this.props.createLoading}
              error={this.props.createError}
            />
          </div>
        </div>
      </Drawer>

      {this.getEnrollmentTable()}
    </div>);
    }

  onDrawerToggle() {
    this.setState({drawerOpen: !this.state.drawerOpen});
    }

  getEnrollmentTable() {
    const spec = {
      'id': 'id',
      'username': 'User',
      'is_admin': 'Administrator?'
      };
    const specOptions = {
      id: {isKey: true, hidden: true},
      is_admin: {dataFormat: EnrollmentsPage.getIsAdminComponent},
      };
    const tableOptions = {
      striped: true,
      search: true,
      exportCSV: true,
      hover: true,
      page: 1,
      sizePerPage: 25,
      sizePerPageList: [
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: 'All', value: this.props.enrollments.length},
        ]
      };

    const sorted = orderBy(
        this.props.enrollments,
        ['is_admin', 'user.display_name'],
        ['desc', 'asc'])
      .map(x => {return {
        id: x.id,
        username: x.user.display_name,
        is_admin: x.is_admin,
        }});

    return (
      <TableList
        specification={spec}
        specificationOptions={specOptions}
        data={sorted}
        options={tableOptions}
      />);
    }

  static getIsAdminComponent(cell, row) {
    if (row.is_admin) {
      return <StatusCheck success />;
      }

    return null;
    }
  }

export default EnrollmentsPage;
