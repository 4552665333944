/*
* pages/student/question/QuestionController.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Controller for the question attempt page.
*/

import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { QuestionAttempt } from 'lib/api';
import { getErrorMsg, urljoin } from 'lib/utils';
import { actions, selectors } from 'state/resources';
import QuestionPage from './QuestionPage';

const CREATE_ATTEMPT_KEY = 'student-create-attempt';

class QuestionController extends React.Component {
  state = {
    redirectId: null,
    };

  constructor(props) {
    super(props);

    this.newAttempt = this.newAttempt.bind(this);
    this.submit = this.submit.bind(this);
    }

  render() {
    const { match, dispatch, ...other} = this.props;

    if (this.state.redirectId !== null && ! Boolean(this.props.error)) {
      return <Redirect to={this.getRedirectUrl(this.state.redirectId)} />;
      }

    return (<QuestionPage
              onNewAttempt={this.newAttempt}
              onSubmit={this.submit}
              {...other}
               />);
    }

  componentDidMount() {
    const attempt_id = this.props.match.params.attempt;
    this.loadAttempt(attempt_id);
    }

  newAttempt() {
    const data = {course_id: this.props.match.params.course,
                  question_id: this.props.match.params.question};
    const action = actions.createResource(
      QuestionAttempt,
      CREATE_ATTEMPT_KEY,
      data);

    this.props.dispatch(action)
      .tap(data => {
        if (data.id !== null && data.id !== undefined) {
          this.setState({redirectId: data.id});
          }
        });
    }

  loadAttempt(id) {
    const action = actions.loadResource(QuestionAttempt, id);
    this.props.dispatch(action);
    }

  submit(value, id) {
    const data = {course_id: this.props.match.params.course,
                  question_id: this.props.match.params.question,
                  answer: value};
    const action = actions.updateResource(QuestionAttempt, id, data);

    this.props.dispatch(action);
    }

  getRedirectUrl(attempt_id) {
    const { course, quiz, question } = this.props.match.params;
    return urljoin(
      '/courses',
      course.toString(),
      'quiz',
      quiz.toString(),
      'question',
      question.toString(),
      'attempt',
      attempt_id.toString()
      );
    }
  }

function mapStateToProps(state, ownProps) {
  const attempt_id = ownProps.match.params.attempt;
  const attempts = selectors.getResource(state, QuestionAttempt);
  const pending = selectors.getPending(attempts, attempt_id);
  const createPending = selectors.getPending(attempts, CREATE_ATTEMPT_KEY)

  return {
    attempt: selectors.getSingleResource(attempts, attempt_id),
    loading: pending.loading || createPending.loading,
    error: getErrorMsg(pending.error || createPending.error),
    };
  }

export default connect(mapStateToProps)(QuestionController);
