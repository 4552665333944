/*
* princeton/quizgen/web/input/NumberInput.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import BasicInput from './BasicInput.jsx';

/*
* The NumberInput component is used for inputting strings. Currently, it is
* simply a wrapper around a text input.
*/
function NumberInput(props) {
	return <BasicInput type='number' className='input number' {...props} />;
	}

NumberInput.defaultProps = {
	answerPrompt: (<p className='aside'>
		To input your answer, simply enter the number. You
		can enter decimal values just as you would any other number.</p>),
	};

export default NumberInput;
