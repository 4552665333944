/*
* core/static/core/react/core/components/Icon.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Ionicons icon.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

/*
* An Icon component is a wrapper around an icon. It shortens creating
* icons by appending the proper class prefixes.
*
* Required Props
* 	String i - icon name (without the 'ionicons ion-' prefix)
*/
function Icon(props) {
	const { i, className, ...other } = props;

	return (<i
		className={`${className} ionicons ion-${i}`}
		{...other}>
	</i>);
	}

Icon.propTypes = {
	i: PropTypes.string.isRequired,
	fontSize: PropTypes.string,
	};

export default React.memo(Icon);
