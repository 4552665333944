/*
* BinarySearchTreeSearchExercise/BinarySearchTreeSearchExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ArrayInputTree from '../../quizgen/web/questions/ArrayInputTree.jsx';

function BinarySearchTreeSearchExercise(props) {
  return (<ArrayInputTree {...props} />);
}

export default BinarySearchTreeSearchExercise;
