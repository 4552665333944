/*
* lib/loader.jsx
* Author: Rushy Panchal
* Date: June 26th, 2019
* Description: Load the React component for a question module.
*/

import { ENABLE_QUESTION_MODULES } from 'lib/env';

let load_question_module = function() {
  return null;
  }

if (ENABLE_QUESTION_MODULES) {
  load_question_module = require('../modules/quizzera-modules.jsx').default;
  }

function load_module(provider) {
  /*
  * Load the module located at `provider`. If not found, returns
  * null.
  */
  return load_question_module(provider);
  }

export default load_module;
