/* questions/static/react/questions/visuals/Tree.jsx
 * Quizzera
 * Author: Shefali Nayak
 * Description: Tree rendering and manipulation using d3.
 */

import React from 'react';

import * as d3 from 'd3';
import clone from 'clone';

class D3Tree {
  constructor(divID, data, toInsert, maxSelection, isColoring) {
    this.divId = divID;

    this.height = 500;
    this.width = 1000;

    this.i = 0;
    this.duration = 100;

    this.treemap = d3.tree().size([this.width, this.height]);

    var root = d3.hierarchy(data, function(d) {
      return d.children;
    });
    root.x0 = this.width / 2;
    root.y0 = 0;

    this.toRotate = [-1];
    this.onRotate = false;

    this.state = [{
      root: root,
      toInsert: toInsert,
      selected: [-1],
      red: [-1]
    }];

    this.coloring = isColoring;

    // Clear the existing canvas
    var canvas = d3.select(`#${this.divId}`);
    canvas.selectAll('*').remove();

    this.svg = canvas
      .append("svg")
      .classed("svg-canvas", true)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .append("g");

    this.maxSelection = maxSelection + 1;

    this.diagonal = this.diagonal.bind(this);
    this.select = this.select.bind(this);
    this.deselect = this.deselect.bind(this);
    this.color = this.color.bind(this);
    this.uncolor = this.uncolor.bind(this);
    this.insert = this.insert.bind(this);
    this.remove = this.remove.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.onclick = this.onclick.bind(this);
    this.getNodeClass = this.getNodeClass.bind(this);
    this.styleNodes = this.styleNodes.bind(this);
    this.styleLinks = this.styleLinks.bind(this);
    this.getLinkClass = this.getLinkClass.bind(this);
    this.getLevelOrder = this.getLevelOrder.bind(this);
    this.getSelected = this.getSelected.bind(this);
    this.undo = this.undo.bind(this);
    this.toggleColor = this.toggleColor.bind(this);

    this.update(this.state[0].root);
  }

  getSelected() {
    var s = "";
    for (var i = 1; i < this.selected.length; i++) {
      var d = this.selected[i];
      s += d.data.name;
      if (i !== this.selected.length - 1) s += " ";
    }
    return s;
  }

  getLevelOrder() {
    var levelOrder = this.root.data.name;
    var nodes = [this.root];
    while (nodes.length > 0) {
      // Get next node
      var node = nodes.shift();
      // Base case for leaves
      if (!node.children) return;
      // Add children to string and queue
      var left = node.children[0];
      var right = node.children[1];
      if (left.data.name) {
        levelOrder += " " + left.data.name;
        nodes.push(left);
      }
      if (right.data.name) {
        levelOrder += " " + right.data.name;
        nodes.push(right);
      }
    }
    return levelOrder;
  }

  // updates current state
  update(src) {
    var treeObj = this;
    // get reference to current state
    var currentState = this.state.pop();
    this.state.push(currentState);
    // Assign x and y position for the nodes
    var treeData = this.treemap(currentState.root);
    // Compute the new tree layout
    var nodes = treeData.descendants();
    var links = treeData.descendants().slice(1);

    // ********** NODES ********** //
    var node = this.svg.selectAll("g.node").data(nodes, function(d) {
      return d.id || (d.id = ++treeObj.i);
    });
    // Enter new nodes at the parent's previous position
    var nodeEnter = node
    .enter()
    .append("g")
    .attr("class", "node")
    .attr("transform", function(d) {
        return `translate(${src.x0},${src.y0})`;
    })
    .on("click", treeObj.onclick);
    // Add circle for new nodes
    nodeEnter
      .append("circle")
      .attr("class", "node")
      .attr("r", 1e-6)
      .style("fill", "#fff");
    // Add labels for the nodes
    nodeEnter
      .append("text")
      .style("font-size", "2em")
      .attr("dy", ".35em")
      .attr("y", "0")
      .attr("text-anchor", "middle")
      .attr("cursor", "pointer")
      .text(function(d) {
        return d.data.name;
      });

    // Update
    var nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position
    nodeUpdate
      .transition()
      .duration(treeObj.duration)
      .attr("transform", function(d) {
      return `translate(${d.x},${d.y})`;
    });
    // Update the style
    nodeUpdate
      .select("circle.node")
      .attr("cursor", "pointer")
      .attr("class", treeObj.getNodeClass)
      .transition()
      .duration(treeObj.duration)
      .attr("r", function(d) {
        return d.data.children ? "2em" : "0.5em";
      });
    nodeUpdate
      .select("text")
      .text(function(d) {
      return d.data.name;
    });

    treeObj.styleNodes(d3.select(`#${this.divId}`));

    // Exit
    var nodeExit = node.exit().remove();
    // Reduce circle size and label text
    nodeExit.select("circle").attr("r", 1e-6);
    nodeExit.select("text").style("fill-opacity", 1e-6);

    nodes.forEach(function(d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // ********** LINKS ********** //
    var link = this.svg.selectAll("path.link").data(links, function(d) {
      return d.id || (d.id = ++treeObj.i);
    });
    // Enter new links at parent's previous location
    var linkEnter = link
    .enter()
    .insert("path", "g")
    .attr("class", treeObj.getLinkClass)
    .attr("d", function(d) {
      var o = { x: d.parent.x0, y: d.parent.y0 };
      return treeObj.diagonal(o, o);
    })
    .style("fill", "none")
    .style("stroke-width", "2px");

    // Update
    var linkUpdate = linkEnter.merge(link);
    // Transition back to parent position
    linkUpdate
      .transition()
      .duration(treeObj.duration)
      .attr("d", function(d) {
      return treeObj.diagonal(d, d.parent);
    });
    // Update class
    linkUpdate.attr("class", treeObj.getLinkClass);

    treeObj.styleLinks(d3.select(`#${this.divId}`));

    // Exit
    link
    .exit()
    .transition()
    .duration(treeObj.duration)
    .attr("d", function(d) {
      var p = d.parent ? d.parent : treeObj.root;
      var o = { x: p.x, y: p.y };
      return treeObj.diagonal(o, o);
    })
    .remove();

    if (currentState.toInsert) {
      var nextInsert = currentState.toInsert[0];
      d3.select('span').html(nextInsert);
    }
  }

  styleNodes(container) {
    container
      .selectAll(".node .unselected")
      .style("stroke", "black")
      .style("stroke-width", "1px");
    container
      .selectAll(".node .leaf")
      .style("stroke", "gray");
    container
      .selectAll(".node .selected")
      .style("stroke", "turquoise")
      .style("stroke-width", "5px");
  }

  styleLinks(container) {
    container.selectAll(".link").style("stroke", "black");
    container
      .selectAll(".link")
      .filter(".leaf")
      .style("stroke", "gray");
  }

  getNodeClass(d) {
    var currentState = this.state.pop();
    this.state.push(currentState);

    var classes = "node ";
    var ind = currentState.selected.indexOf(d);
    if (ind >= 0) {
      classes += "selected ";
    } else {
      classes += "unselected ";
    }
    if (!d.data.name) {
      classes += "leaf ";
    }
    return classes;
  }

  getLinkClass(d) {
    var classes = "link ";
    if (!d.data.name) {
      classes += "leaf ";
    }
    return classes;
  }

  onclick(d) {
    var currentState = this.state.pop();
    this.state.push(currentState);

    if (currentState.toInsert.length > 0) {
      this.insert(d);
    } else if (this.coloring) {
      this.toggleColor(d);
    } else {
      this.toggleSelect(d);
    }
  }

  toggleSelect(d) {
    var currentState = this.state.pop();
    this.state.push(currentState);

    if (!d.data.name) return;
    console.log(d.data.name);
    var ind = currentState.selected.indexOf(d);
    if (ind < 0) this.select(d);
    else this.deselect(d);
    this.update(d);
  }

  toggleColor(d) {
    console.log("toggling color");
    var currentState = this.state.pop();
    this.state.push(currentState);

    if (!d.data.name) return;
    console.log(d.data.name);
    var ind = currentState.red.indexOf(d);
    if (ind < 0) this.color(d);
    else this.uncolor(d);
    this.update(d);
  }

  // changes state
  insert(d) {
    // peek at top of state stack for current state
    var newState = this.state.pop();
    // duplicate to remember old state
    var oldState = clone(newState);
    this.state.push(oldState);
    this.state.push(newState);

    // only insert if there are items to insert
    if (newState.toInsert.length === 0) return;
    // only insert in empty nodes
    if (d.data.name) {
      console.log("Error: cannot insert in non-empty node");
      return;
    }

    // set value
    d.data.name = newState.toInsert.shift();

    // create children
    var leftNode = {
      type: 'node-type',
      name: "",
    };
    var rightNode = {
      type: 'node-type',
      name: "",
    };

    var childL = d3.hierarchy(leftNode, function(d) {
      return d.children;
    });
    childL.depth = d.depth + 1;
    childL.parent = d;
    var childR = d3.hierarchy(rightNode, function(d) {
      return d.children;
    });
    childR.depth = d.depth + 1;
    childR.parent = d;

    d.children = [];
    d.data.children = [];
    d.children.push(childL);
    d.data.children.push(childL);
    d.children.push(childR);
    d.data.children.push(childR);

    // set as red and deselect
    newState.red.push(d);
    var ind = newState.selected.indexOf(d);
    if (ind >= 0) newState.selected.splice(ind, 1);

    this.update(d);
  }

  // changes state
  remove(d) {
    // peek at top of state stack for current state
    var newState = this.state.pop();
    // duplicate to remember old state
    var oldState = clone(newState);
    this.state.push(oldState);
    this.state.push(newState);

    if (d.children[0].name || d.children[1].name) {
      console.log("Error: cannot remove node with children");
      return;
    }

    var p = d.parent;
    if (p.children[0] === d) {
      p.children[0] = {};
    } else {
      p.children[1] = {};
    }
  }

  // changes state
  select(d) {
    // peek at top of state stack for current state
    var newState = this.state.pop();
    // duplicate to remember old state
    var oldState = clone(newState);
    this.state.push(oldState);
    this.state.push(newState);

    if (!d.data.name) return;
    var ind = newState.selected.indexOf(d);
    if (ind < 0) {
      if (newState.selected.length === this.maxSelection) {
        newState.selected.pop();
      }
      newState.selected.push(d);
    }
  }

  // changes state
  deselect(d) {
    // peek at top of state stack for current state
    var newState = this.state.pop();
    // duplicate to remember old state
    var oldState = clone(newState);
    this.state.push(oldState);
    this.state.push(newState);

    if (!d.data.name) return;
    var ind = newState.selected.indexOf(d);
    if (ind >= 0) newState.selected.splice(ind, 1);
  }

  // changes state
  color(d) {
    // peek at top of state stack for current state
    var newState = this.state.pop();
    // duplicate to remember old state
    var oldState = clone(newState);
    this.state.push(oldState);
    this.state.push(newState);

    if (!d.data.name) return;
    var ind = newState.red.indexOf(d);
    if (ind < 0) newState.red.push(d);
  }

  // changes state
  uncolor(d) {
    // peek at top of state stack for current state
    var newState = this.state.pop();
    // duplicate to remember old state
    var oldState = clone(newState);
    this.state.push(oldState);
    this.state.push(newState);

    if (!d.data.name) return;
    var ind = newState.red.indexOf(d);
    if (ind >= 0) newState.red.splice(ind, 1);
  }

  // reverts to previous state
  undo() {
    if (this.state.length < 2) {
      return;
    }
    this.state.pop();
    var oldState = this.state.pop();
    this.state.push(oldState);
    this.update(oldState.root);
  }

  diagonal(s, d) {
    return `M ${s.x} ${s.y}
    C ${s.x} ${(s.y + d.y) / 2},
    ${d.x} ${(s.y + d.y) / 2},
    ${d.x} ${d.y}`;
  }
}

class Tree extends React.Component {
  render() {
    /* Render the component. */
    return <div id="tree-div"></div>;
    }

  componentDidMount() {
    var maxSelections = this.props.data.split(" ").length,
      hierarchy = Tree.getHierarchy(this.props.data);
    new D3Tree("tree-div", hierarchy, [], maxSelections, false);
    }

  static getHierarchy(data) {
    /* Convert a string of node IDs into a hierarchy, suitable for d3. */
    function insert(x, k) {
      // Base case: insert key at null node
      if (x.name === undefined) {
        var newNode = {
          name: k.toString(),
          children: [{},{}]
        };
        return newNode;
      }

      var xVal = parseInt(x.name);
      if (k < xVal) x.children[0] = insert(x.children[0], k);
      else if (k > xVal) x.children[1] = insert(x.children[1], k);

      return x;
    }

    var parsed = data.split(" ");
    var root = {};
    for (var i = 0; i < data.length; i++) {
      var curVal = parsed[i];
      root = insert(root, curVal);
    }

    return root;
    }
  }

export { Tree, D3Tree };
