/* eslint-disable no-template-curly-in-string */
/*
* lib/api/client.ts
* Author: Rushy Panchal
* Date: June 17th, 2019
* Description: Utilities for accessing the Quizzera API.
*/

import { Client, ErrorHandler, RequestHandler } from 'types/api';

import request from 'request-promise';
import Promise from "bluebird";
import { template, extend, at } from 'lodash';

import * as jwt from 'lib/jwt';
import * as store from 'lib/store';
import { ensure_url, urljoin } from 'lib/utils';
import { API_URL } from 'lib/env';

class APIClient implements Client {
  private static AUTH_KEY = 'authentication';
  private static DEFAULT_TIMEOUT = 1000; // in milliseconds
  private static URL_MAP: {[index: string]: (params: object) => string} = {
    // JWT
    'jwt:obtain': template('token/'),
    'jwt:refresh': template('token/refresh/'),

    // Resources
    'course': template('v1/courses/${ params.id }/'),
    'course:clone': template('v1/courses/${ params.id }/clone/'),
    'quiz': template('v1/quizzes/${ params.id }/'),
    'question': template('v1/questions/${ params.id }/'),
    'question:reorder': template('v1/questions/reorder/'),

    'attempt': template('v1/attempts/${ params.id }/'),
    'attempt:initial': template('v1/attempts/initial/'),
    'extension': template('v1/extensions/${ params.id }/'),
    'extension:list': template('v1/extensions/latest-extensions/'),
    'extension:bulk': template('v1/extensions/bulk-extensions/'),
    'enrollment': template('v1/enrollments/${ params.id }/'),
    'enrollment:bulk': template('v1/enrollments/bulk-enroll/'),
    };

  private client: request.RequestPromiseAPI;
  private errorHandler: ErrorHandler;

  constructor() {
    this.client = request.defaults({
      json: true,
      timeout: APIClient.DEFAULT_TIMEOUT,
      });

    this.errorHandler = null;
    this.get = this.getVerbFunction('GET');
    this.post = this.getVerbFunction('POST');
    this.put = this.getVerbFunction('PUT');
    this.delete = this.getVerbFunction('DELETE');
    }

  get: RequestHandler;
  post: RequestHandler;
  put: RequestHandler;
  delete: RequestHandler;

  request(name: string,
          params?: object,
          options?: request.Options): Promise<any> {
    const url = APIClient.resolve(name, params);
    const opt = extend(options, {uri: url});

    return this
      .refresh_authentication()
      .then(() =>
        this.client
          .defaults(this.auth_options)(opt)
          .promise())
      .catch(e => this.handleError(e)) as Promise<any>;
    }

  private handleError(e: Error | {[index: string]: any}): object | null {
    if (this.errorHandler === null) {
      throw e;
      }
    else {
      var msg = at(e, 'error.error')[0];
      if (msg === undefined || msg === null) {
        msg = e.message;
        }

      this.errorHandler(msg);

      // Break the promise chain.
      return { then: function() {} };
      }
    }

  setErrorHandler(handler: ErrorHandler = null): void {
    this.errorHandler = handler;
    }

  authenticate(username: string, password: string): Promise<void> {
    /* Authenticate to the API and setup for future authentication. */
    const auth_data = {username: username, password: password};
    const url = APIClient.resolve('jwt:obtain');

    return this.client
      .post(url, {body: auth_data})
      .promise()
      .tap(token => this.authenticate_token(token))
      .catch(() => this.logout());
    }

  authenticate_token(token: jwt.Token): void {
    jwt.save_token(token);
    store.set_json(APIClient.AUTH_KEY, {success: true});
    }

  logout(): void {
    /* Logout from the API. */
    jwt.clear_token();
    store.remove(APIClient.AUTH_KEY);
    }

  get authenticated(): boolean {
    /* Check if the user is currently authenticated. */
    const authenticated = store.get_json(APIClient.AUTH_KEY) as AuthenticatedStoreValue;
    return (authenticated != null) && (authenticated.success === true);
    }

  get username(): string {
    /* Get the current authenticated user name. */
    return jwt.get_payload('email') as string;
    }

  get display_name(): string {
    /* Get the current authenticated user name as a display. */
    return jwt.get_payload('email') as string;
    }

  get user_id(): string {
    /* Get the current authenticated user ID. */
    return jwt.get_payload('user_id') as string;
    }

  get is_staff(): boolean {
    /* Get whether or not the current user is a staff member. */
    return jwt.get_payload('is_staff') as boolean;
    }

  get is_superuser(): boolean {
    /* Get whether or not the current user is a superuser. */
    return jwt.get_payload('is_superuser') as boolean;
    }

  private static resolve(name: string, params: object = {}): string {
    /* Resolve a complete API URL. */
    return ensure_url(urljoin(API_URL, APIClient.URL_MAP[name](params)));
    }

  private refresh_authentication(): Promise<any> {
    /* Refresh the authentication. */
    var refresh_token = null;
    try {
      refresh_token = jwt.needs_refresh();
    }
    catch (err) {
      // Refresh token is expired, so we need to force a logout.
      this.logout();
      return Promise.resolve(false);
      }

    if (! refresh_token) {
      return Promise.resolve(true);
      }

    const auth_data = {refresh: refresh_token};
    const url = APIClient.resolve('jwt:refresh');

    return this.client
      .post(url, {body: auth_data})
      .promise()
      .tap(token => jwt.save_token(token, false))
      .tap(() => store.set_json(APIClient.AUTH_KEY, {success: true}))
      .catch(() => this.logout());
    }

  private get auth_options(): request.Options {
    /* Get the authorization options. */
    let options: request.Options = {
      url: '',
      headers: {}
      };

    if (this.authenticated) {
      options.headers![jwt.HEADER] = jwt.get_header();
      }

    return options;
    }

  private getVerbFunction(verb: string): RequestHandler {
    let wrapper = (name: string,
                   params?: object,
                   options?: request.Options): Promise<any> => {
      const extended_options = extend(options, {method: verb});
      return this.request(name, params, extended_options);
      };

    return wrapper;
    }
  };

interface AuthenticatedStoreValue {
  [index: string]: any;
  success: boolean;
  }

export default APIClient;
