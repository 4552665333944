/*
* PropertiesOfMSTsExercise/PropertiesOfMSTsExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function PropertiesOfMSTsExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default PropertiesOfMSTsExercise;
