/*
* components/SideNavbar.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: A side-floating navbar.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';

class SideNavbar extends React.PureComponent {
  static propTypes = {
    header: PropTypes.node,
    links: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      })).isRequired,
    showActive: PropTypes.bool,
    };

  static defaultProps = {
    header: null,
    showActive: false,
    };

  render() {
    return (
      <Card>
        {this.getHeader()}

        <ListGroup className="list-group-flush">
          {this.props.links.map((link, index) => (
            <ListGroupItem key={index}>
              {this.getLink(link)}
            </ListGroupItem>
            ))}
        </ListGroup>
        
        {this.getChildren()}
      </Card>);
    }

  getLink(link) {
    let name = <span>{link.name}</span>;
    if (this.isActive(link.url)) {
      name = <span className='active'>{link.name}</span>;
      }

    return <Link to={link.url}>{name}</Link>;
    }

  getHeader() {
    if (this.props.header !== null) {
      return (<Card.Body>
          <Card.Title>{this.props.header}</Card.Title>
        </Card.Body>);
      }

    return null;
    }

  getChildren() {
    if (React.Children.count(this.props.children) > 0) {
      return (<Card.Body>
          {this.props.children}
        </Card.Body>);
      }

    return null;
    }

  isActive(url) {
    return (this.props.showActive &&
            (url === window.location.toString()
            || url === window.location.pathname));
    }
  }

export default SideNavbar;
