/*
* FordFulkersonExercise/FordFulkersonExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import TypedArrayQuestion from '../../quizgen/web/questions/TypedArrayQuestion.jsx';

function FordFulkersonExercise(props) {
  return (<TypedArrayQuestion type={TypedArrayQuestion.types.char} {...props} />);
}

export default FordFulkersonExercise;
