/*
* pages/students/quiz/Quiz.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Single quiz page display.
*/

import "styles/custom.scss"

import React from 'react';
import { Link } from 'react-router-dom';

import TableList from 'components/TableList.jsx';
import StatusCheck from 'components/StatusCheck.jsx';
import Loader from 'components/Loader.jsx';
import APIErrorAlert from 'components/APIErrorAlert.jsx';
import HoverTooltip from 'components/HoverTooltip.jsx';
import LabelGroup from 'components/LabelGroup.jsx';
import { urljoin } from 'lib/utils';

function QuizPage({match, ...other}) {
  return (<div className="container-fluid">
    <div className="aside">
      <p>The questions in this quiz are listed below. You may answer them
      in any order. You should answer these exercise questions with pen and
      paper (and without writing or executing code).</p>
      <p>Each question has a limited number of attempts, which is listed next
      to the question. Each answer submission decrements the number of attempts
      you have left. Once you are out of attempts, you may no longer submit
      an answer to that particular question. You may, however, go back and view
      any previous answer at any time.</p>
      <p>Questions can be graded in different ways; this is dictated by the
      course instructors. The grading type is listed next to each question.
      <br/>
      'Maximum' questions are graded by taking the maximum score out of all
      your attempts.
      <br/>
      On the other hand, 'Average' questions are graded by taking the average of
      all your attempt scores.</p>
      <p>Your best score for a question is listed as well. If a green check is
      displayed, you have achieved the highest score possible. However, if a red
      cross is displayed, you have not attempted that question.</p>
    </div>
    <QuestionList
      baseUrl={match.url}
      {...other}
      />
    </div>);
  }

class QuestionList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getTitleComponent = this.getTitleComponent.bind(this);
    this.getAttemptComponent = this.getAttemptComponent.bind(this);
    this.getAttemptScoreComponent = this.getAttemptScoreComponent.bind(
      this);
    }

  getTitleComponent(cell, row, extra, index) {
    /* Get the component for the title. */
    const url = urljoin(this.props.baseUrl, 'question', row.id.toString());

    return (<span>
      {index+1}.&nbsp;
      <Link to={url}>{cell}</Link>
      </span>);
    }

  getAttemptComponent(cell, row) {
    /* Get the component for the attempts listing. */
    var remaining = cell - row.used_attempts;
    return (<HoverTooltip tooltip={remaining + ' Remaining'}>
      <span>{row.used_attempts} / {cell}</span>
      </HoverTooltip>);
    }

  getAttemptScoreComponent(cell, row) {
    /* Get the component for the user's maximum attempt score. */
    if (cell === row.max_score) {
      return (<HoverTooltip tooltip='You achieved the maximum score.'>
        <span><StatusCheck success={true} /></span>
        </HoverTooltip>);
      }
    else if (cell == null) {
      return (<HoverTooltip tooltip='You have not attempted this question.'>
        <span><StatusCheck success={false} /></span>
        </HoverTooltip>);
      }
    else return cell;
    }

  getMaxScoreComponent(cell, row) {
    /* Get the component for a question's maximum score. */
    var tags = [],
      grading_display = row.grading_display.toLowerCase();
    
    switch (row.grading) {
      case 1: tags.push(LabelGroup.Info(
        <HoverTooltip tooltip='Average score of all attempts is taken.'>
        <span>{grading_display}</span>
        </HoverTooltip>
        ));
        break;
      case 2: tags.push(LabelGroup.Success(
        <HoverTooltip tooltip='Maximum score of all attempts.'>
        <span>{grading_display}</span>
        </HoverTooltip>
        ));
        break;
      default: tags.push(LabelGroup.Primary(grading_display)); break;
      }

    return <span>{cell} <LabelGroup labels={tags}/></span>;
    }

  render() {
    /* Render the component. */
    var spec = {
      'id': 'id',
      'title': 'Title',
      'max_attempts': 'Attempts',
      'attempt_score': 'Your Score',
      'max_score': 'Best Possible Score',
      };

    return (<div>
      <APIErrorAlert msg={this.props.error} />
      <Loader loading={this.props.loading} />
      <TableList
        specification={spec}
        specificationOptions={{id: {isKey: true, hidden: true},
          title: {dataFormat: this.getTitleComponent},
          max_attempts: {dataFormat: this.getAttemptComponent},
          attempt_score: {dataFormat: this.getAttemptScoreComponent},
          max_score: {dataFormat: this.getMaxScoreComponent}}}
        data={this.props.questions}
        options={{hover: true, striped: true, clearSearch: true,
          search: true}}>
        </TableList>
      </div>);
    }
  }

export default QuizPage;
