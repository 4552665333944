/*
* src/pages/admin/AdminDashboard.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Course administrator dashboard.
*/

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import SideNavbar from 'components/SideNavbar';
import { urljoin } from 'lib/utils';

import SettingsPage from './settings';
import EnrollmentsPage from './enrollments';
import ExtensionsPage from './extensions';
import QuizzesPage from './quizzes';
import PageNotFound from 'pages/404.jsx';

class AdminDashboard extends React.PureComponent {
  render() {
    const { path, url, params } = this.props.match;

    return (<div className='container-fluid'>
    <Row>
      <Col xs={12} md={3} lg={2}>
        <SideNavbar showActive
          links={[
            {url: urljoin(url, 'enrollments'), name: 'Enrollments'},
            {url: urljoin(url, 'quizzes'), name: 'Quizzes'},
            {url: urljoin(url, 'extensions'), name: 'Extensions'},
            {url: urljoin(url, 'settings'), name: 'Settings'}
          ]}
        />
      </Col>

      <Col xs={12} md={9} lg={10}>
        <Switch>
          <Route exact
            path={path}
            component={() => <SettingsPage id={params.course} />} />

          <Route
            exact
            path={urljoin(path, 'quizzes')}
            component={() => <QuizzesPage id={params.course} />} />

          <Route
            exact
            path={urljoin(path, 'enrollments')}
            component={() => <EnrollmentsPage id={params.course} />}
          />

          <Route
            exact
            path={urljoin(path, 'extensions')}
            component={() => <ExtensionsPage id={params.course} />}
          />

          <Route
            exact
            path={urljoin(path, 'settings')}
            component={() => <SettingsPage id={params.course} />}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Col>
    </Row>
    </div>);
    }
  }

export default AdminDashboard;
