/*
* Router.jsx
* Author: Rushy Panchal
* Date: June 17th, 2019
* Description: Main front-end router for Quizzera.
*/

import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Header from "./components/Header.jsx"
import Breadcrumbs from "./components/Breadcrumbs.jsx";
import ResourceLoadable from "components/ResourceLoadable";

import HomePage from './pages/Home';
import DashboardPage from './pages/dashboard';
import AdminPage from './pages/admin';
import get_student_routes from 'pages/student/routes';
import get_auth_routes from 'pages/auth/routes';

import PageNotFound from './pages/404.jsx';

import { APIClient, Course } from 'lib/api';
import * as cache from 'lib/cache';

function get_routes() {
  return [
  {path: "/",
   exact: true,
   component: HomePage},
  
  {path: "/dashboard/",
   exact: true,
   auth: true,
   component: DashboardPage},

  {path: "/admin/:course/",
   auth: true,
   component: AdminPage,
   breadcrumb: ({match}) => (
     <Breadcrumbs crumbs={[
       {name: "Dashboard", url: "/dashboard/"},
       {name: <ResourceLoadable resource={Course} id={match.params.course} />,
        url: match.url,
        active: true}
       ]} />)
   },

  ...get_auth_routes(),
  ...get_student_routes(),
  /* Catch-all for when a page is not found. */
  {path: null,
   component: PageNotFound}
  ];
}

const routes = get_routes();

function QuizzeraRouter(props) {
  let client = new APIClient();
  cache.clean();

  return (
    <BrowserRouter basename='' forceRefresh={true}>
      <div className="container-fluid">
        <Header user={client.username} logout={() => client.logout()} />

      {/* Render breadcrumbs. */}
        {routes
          .filter((route) => route.breadcrumb !== null && route.breadcrumb !== undefined)
          .map((route, index) => {
            var {breadcrumb, auth, ...props} = route;
            props.key = index;
            props.component = breadcrumb;

            if (auth) {
              return <AuthRoute {...props} />;
              }

            return <Route {...props} />
            })}
      </div>

      <div>
        <Switch>
          {routes.map((route, index) => {
            var {breadcrumb, auth, ...props} = route;
            props.key = index;

            if (auth) {
              return <AuthRoute {...props} />;
              }

            return <Route {...props} />
            })}
        </Switch>
      </div>
    </BrowserRouter>
    );
  }

function AuthRoute({ component: Component, ...rest }) {
  /*
  * A route that requires authentication to access.
  */
  let client = new APIClient();
  return (
    <Route
      {...rest}
      render={props =>
        client.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }} />
        )}
    />);
}

export default QuizzeraRouter;
