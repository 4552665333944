/*
* questions/static/react/questions/input/ArrayInput.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import BasicInput from './BasicInput.jsx';

/*
* The ArrayInput component simplifies inputting arrays. Currently, it is
* simply a wrapper around a text input. However, this should be used instead
* of regular text inputs (when inputting arrays, of course) as the input
* format can be easily changed in the future without hassle.
*/
class ArrayInput extends React.Component {
	render() {
		/* Render the component. */
		let { attempt, onChange, ...other } = this.props;

		if (! this.added_validators && attempt) {
			var max_length = attempt.max_length,
				fixed_length = attempt.fixed_length;

			/* At most, one of these should be true. */
			if (fixed_length) {
				this.default_validators.push(
					ArrayInput.validators.fixedLength(fixed_length)
					);
				this.added_validators = true;
				}
			else if (max_length) {
				this.default_validators.push(
					ArrayInput.validators.maxLength(max_length)
					);
				this.added_validators = true;
				}
			}

		return (<BasicInput type='text'
											 className='input array'
											 onChange={v => onChange(v, this.default_validators)}
											 {...other} />);
		}

	static validators = {
		stringNotEmpty: function(s) {
			/* Validate that the string is not empty. */
			if (! s) throw new Error("Input should not be empty!");
			},

		integerArray: function(s) {
			/* Validate that the string is an integer array, delimited by spaces. */
			var re = /^((\d*) ?)*$/;
			if (! re.test(s))
				throw new Error("Input array should be an array of integers!");
			},

		numericArray: function(s) {
			/* Validate that the string is a numeric array, delimited by spaces. */
			var re = /^(([\d.]*) ?)*$/;
			if (! re.test(s))
				throw new Error("Input array should be an array of numbers!");
			},

		charArray: function(s) {
			/* Validate that the string is a character array, delimited by spaces. */
			var upper_re = /^(([A-Z]?) )*([A-Z])?$/,
				lower_re = /^(([a-z]?) )*([a-z])?$/;
			if (! (upper_re.test(s) || lower_re.test(s)))
				throw new Error("Input array should be an array of characters!");
			},

		fixedLength: function(length) {
			/*
			* Validate that the array represented by the string is of a set length.
			* Note: this "validator" takes a length and produces a function
			* validator.
			*/
			return function(s) {
				var arr = s.trim().split(' ');
				if (arr.length !== length) {
					throw new Error("Input array should be of length " + length + "!");
					}
				}
			},

		maxLength: function(length) {
			/*
			* Validate that the array represented by the string is at most a set
			* length.
			* Note: this "validator" takes a length and produces a function
			* validator.
			*/
			return function(s) {
				var arr = s.trim().split(' ');
				if (arr.length > length) {
					throw new Error("Input array should be of length " + length + "!");
					}
				}
			},
		};

	/* This should be a bound property because it is referenced by instance,
	not by class. */
	default_validators = [
		ArrayInput.validators.stringNotEmpty,
		];
	}

ArrayInput.defaultProps = {
	answerPrompt: (<p className='aside'>
			To specify an array or sequence of values in an
			answer, separate the values in the sequence by a space. For example, if
			the question asks for the first ten powers of two (starting at 1), then
			the following answer is acceptable:
			<br/><br/>
		1 2 4 8 16 32 64 128 256 512
		</p>),
}

export default ArrayInput;
