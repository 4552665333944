/*
* questions/static/react/quizgen/NumericQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import GenericQuizgenQuestion from './GenericQuizgenQuestion.jsx';
import NumberInput from '../input/NumberInput.jsx';

/*
* A NumericQuestion component handles input and submission
* of questions that accept numeric values.
*
* Optional Props
* 	Number precision - precision of the input step
*/
class NumericQuestion extends React.Component {
	render() {
		/* Render the component. */
		var inputProps = {};
		if (this.props.precision != null) inputProps.step = this.props.precision;
		return (<GenericQuizgenQuestion input={NumberInput} {...this.props}
			inputProps={inputProps} />);
		}
	}

export default NumericQuestion;
