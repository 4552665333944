/*
* state/resources/selectors.ts
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Resource selectors.
*/

import { Filter, ResourceCreator } from 'types/api';
import Model from 'types/abstract/Model';
import { Id } from 'types/base';
import * as types from './types';
import { initialResourceState } from './initial';

import { get } from 'lodash';
import { isSubset } from 'lib/utils';

export function getResource<T extends Model>(
    state: types.GlobalResourceState,
    t: ResourceCreator<T>)
    : types.ResourceState<T> {
  const resource = new t();
  return state.resources[resource.type] || initialResourceState();
  }

export function getSingleResource<T extends Model>(
    resources: types.ResourceState<T>,
    id: Id,
    ) : T | undefined {

  return get(resources.objects, [id, 'data'], undefined);
  }

export function filterResource<T extends Model>(
    resources: types.ResourceState<T>,
    filter: Filter = {})
    : T[] {

  return (
    Object.values(resources.objects)
    .map(t => t.data)
    .filter(r => isSubset(r, filter))
    );
  }

export function getPending<T extends Model>(
    resources: types.ResourceState<T>,
    key: string)
    : types.PendingResourceRequestState {
  const defaultPending = {loading: false, error: '', completed: false};
  return get(resources, ['pending', key], defaultPending);
  }

export function isSaved<T extends Model>(
    resources: types.ResourceState<T>,
    id: Id)
    : boolean {
  return get(resources.objects, [id, 'initial'], undefined) === undefined;
  }
