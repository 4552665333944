/*
* lib/utils.ts
* Quizzera
* Author: Rushy Panchal
* Description: Utility functions.
*/

import _urljoin from 'url-join';

import StringIndexable from 'types/abstract/StringIndexable';

const ALPHANUM = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function randomString(len : number, charSet : string = ALPHANUM) : string {
	/*
	* Generate a random string of a given length from the charset.
	*
	* Parameters
	* 	Number len - length of random string
	* 	String charSet - set of characters to use in random string
	*
	* Returns
	*  (String) random string
	*/
	var rs = '';
	for (var i = 0; i < len; i++) {
		var randomPoz = Math.floor(Math.random() * charSet.length);
		rs += charSet.substring(randomPoz,randomPoz+1);
		}
	return rs;
	}

function urljoin(...args: string[]): string {
	/*
	* Join multiple URL fragments and ensure the result ends in a slash.
	*/
	const joined = _urljoin(...args);
	if (! joined.endsWith('/')) {
		return joined + '/';
		}

	return joined;
	}

function absolute_url(url: string): string {
	/*
	* Convert a relative URL to be absolute with the current origin.
	*
	* Parameters
	*		String url - relative url
	*
	* Returns
	* 	(String) absolute url
	*/
	return urljoin(window.location.origin, url);
	}

function ensure_url(url : string) : string {
	/*
	* Ensure that a possible URL is a URL, followed by a trailing slash
	* and starting with a protocol. Duplicate forward-slashes are also stripped.
	*
	* Parameters
	* 	String url - possible url
	*
	* Returns
	* 	(String) formatted URL
	*/
	var added_protocol = '',
			added_slash = '';

	if (! url.startsWith('https://') && ! url.startsWith('http://')) {
		added_protocol = 'https://';
		}

	if (! url.endsWith('/')) {
		added_slash = '/';
		}

	const combined = `${added_protocol}${url}${added_slash}`;
	return combined.replace(/([^:])(\/\/+)/g, '$1/');
	}

function isSubset(superset: StringIndexable, subset: StringIndexable) {
	if (
		(typeof superset !== 'object' || superset === null) ||
		(typeof subset !== 'object' || subset === null)
	) return false;

	if (
		(superset instanceof Date || subset instanceof Date)
	) return superset.valueOf() === subset.valueOf();

	return Object.keys(subset).every((key) => {
		if (!superset.propertyIsEnumerable(key)) return false;

		const subsetItem = subset[key];
		const supersetItem = superset[key];
		if (
			(typeof subsetItem === 'object' && subsetItem !== null) ?
			!isSubset(supersetItem, subsetItem) :
			supersetItem !== subsetItem
		) return false;

		return true;
	});
};

const ALLOWED_FIELDS = ['error', 'detail', 'message'];
function getErrorMsg(msg: string | {[index:string]: any} | null): string {
	if (msg === null) {
		return '';
		}
	else if (typeof msg === 'string') {
		return msg;
		}
	else if (typeof msg === 'object') {
		for (var index in ALLOWED_FIELDS) {
			let field = ALLOWED_FIELDS[index];
			if (field in msg && typeof msg[field] === 'string') return msg[field];
			}

		return JSON.stringify(msg);
		}

	return '';
	}

export {
	randomString,
	absolute_url,
	urljoin,
	ensure_url,
	isSubset,
	getErrorMsg,
	};
