/*
* questions/static/react/quizgen/ShortAnswerQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import GenericQuizgenQuestion from './GenericQuizgenQuestion.jsx';
import StringInput from '../input/StringInput.jsx';

/*
* A ShortAnswerQuestion component handles input and submission
* of 'short answer' questions that accept simple text as input.
*/
class ShortAnswerQuestion extends React.Component {
	render() {
		/* Render the component. */
		return (<GenericQuizgenQuestion input={StringInput} {...this.props} />);
		}
	}

export default ShortAnswerQuestion;
