/*
* MemoryOfStackOrQueueExercise/MemoryOfStackOrQueueExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ShortAnswerQuestion from '../../quizgen/web/questions/ShortAnswerQuestion.jsx';

function MemoryOfStackOrQueueExercise(props) {
  return (<ShortAnswerQuestion {...props} />);
}

export default MemoryOfStackOrQueueExercise;
