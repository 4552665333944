/*
* questions/static/react/input/MultipleChoiceInput.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import _ from 'lodash';

import StatusCheck from 'components/StatusCheck.jsx';
import HiddenComponent from 'components/HiddenComponent.jsx';

/*
* The MultipleChoiceInput component simplifies inputting multiple choice
* options. It can be used for radiobutton or checkbox input, depending on the
* 'many' prop.
*
* Optional Props
*   Boolean many - whether to use checkboxes (true) or radiobuttons (false)
*   String choicesField - field in props.attempt to get the list of choices
*   String flagsField - field in props.attempt to get the flags of the correct
*     answer
*/
class MultipleChoiceInput extends React.Component {
  constructor(props) {
    super(props);
    this.buttons = [];
    }

  render() {
    /* Render the component. */
    let {many,
         choicesField,
         flagsField,
         answerPrompt,
         attempt,
         value,
         readOnly,
         onChange,
         ...inputProps} = this.props;

    const inputType = many ? 'checkbox' : 'radio',
          groupName = 'group-' + Math.random(),
          former_values = this.parseInputValue(value);

    /* Get the flags from the current attempt (if viewing a previous question)
    or the current submitted data (if just submitted an answer). */
    var flags = attempt[flagsField];
    var explanations = attempt && attempt.explanations;

    var comp = null;
    if (! _.isEmpty(attempt[choicesField])) {
      /* Render each possible answer. */
      comp = (<div className='input multi' id={groupName}>
        {attempt[choicesField].map((choice, index) => {
          var key = choice,
            currently_checked = false;

          /* If viewing a former answer, then use that state. */
          if (former_values && former_values[index]) {
            currently_checked = true;
            }

          /* If the correct flags are available (i.e viewing a previous
          question or submitted an answer), then display if the user's
          selections are correct. */
          var flag_comp = null;
          var explanation = null;
          if (flags) {
            var success = flags[index] === currently_checked;
            var display = true;
            if (! many) {
              display = flags[index] || currently_checked;
              }
            flag_comp = (<HiddenComponent hidden={!display} visibility>
                <StatusCheck
                  success={success}
                  style={{verticalAlign: "middle"}} />
              </HiddenComponent>);

            if (explanations && explanations[index]) {
              explanation = (<span className="multi-explanation">
                {explanations[index]}
              </span>);
              }
            }

          return (<div key={key}>
              {flag_comp}
              &nbsp;
              <input type={inputType}
              			 name={groupName}
              			 id={key} 
		                 defaultChecked={currently_checked}
		                 disabled={readOnly}
		                 onChange={e => onChange(this.serialize())}
		                 ref={(c) => this.buttons[choice] = c}
		                 {...inputProps} />
              &nbsp;
              <label htmlFor={key} className='input-label'>
                {choice}{explanation}
              </label>
            </div>);
          })}
        </div>);
      }
    else comp = (<HiddenComponent hidden={true}></HiddenComponent>);

    var type_msg = '';
    if (many) {
      type_msg = 'Mark all answers that apply.';
      }
    else {
      type_msg = 'Mark the best answer.';
      }

    var finished_comp = null;
    if (flags) {
      if (many) {
        finished_comp = (<p className="aside">
          <Badge variant="warning">Note</Badge>
          &nbsp;
          A green check indicates that you correctly marked (or left unmarked)
          the option; a red cross indicates that you answered it incorrectly.
          </p>);
        }
      else {
        finished_comp = (<p className="aside">
          <Badge variant="warning">Note</Badge>
          &nbsp;
          If you selected the correct option, it is indicated with a check.
          Otherwise, your selection and the correct answer are indicated with a
          cross.
          </p>);
        }
      
      }

    if (! answerPrompt) {
      answerPrompt = (<p className='aside'>To input your answer, select the option(s) below
      that are correct.
      <br/>
      {type_msg}</p>);
      }

    return (<div>
      {answerPrompt}
      {finished_comp}
      {comp}
      </div>);
    }

  parseInputValue(repr) {
    /* Parse the input value. */
    if (repr === '') {
      return [];
      }

    if (typeof repr === 'string') {
    	return JSON.parse(repr);
    	}

    return repr;
    }

  serialize() {
    /* Serialize the input's value to be sent to the API. The serialized
    value should be a string of booleans - whether or not the option was
    selected. */
    var selected_options = [];

    if (! _.isEmpty(this.props.attempt)) {
      this.props.attempt[this.props.choicesField].forEach((choice, index) => {
        var item_checked = false;
        if (_.has(this.buttons, choice)) {
          item_checked = this.buttons[choice].checked;
          }

        selected_options[index] = item_checked;
        });
      }

    return JSON.stringify(selected_options);
    }
  }


MultipleChoiceInput.propTypes = {
	many: PropTypes.bool,
	choicesField: PropTypes.string,
	flagsField: PropTypes.string,
	};

MultipleChoiceInput.defaultProps = {
	many: true,
	choicesField: 'choices',
	flagsField: 'correct_flags',
	value: '',
	};

export default MultipleChoiceInput;
