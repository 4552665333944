/*
* core/static/react/core/components/Breadcrumbs.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Displays 'breadcrumbs' of navigation.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import HiddenComponent from './HiddenComponent.jsx';

/*
* A `Breadcrumbs` component displays navigation in small tabs.
*
* Required Props
* 	Object crumbs - an array containing objects of shape
*   								{name: string, url: string}
*/
function Breadcrumbs(props) {
	if (props.crumbs.length === 0)
		return (<HiddenComponent hidden={true}></HiddenComponent>);

	return (<div className="breadcrumb">
		{props.crumbs.map((crumb, index) => (
			<li key={index}>
				{getCrumb(crumb)}
			</li>))
		}
	</div>);
	}

function getCrumb(crumb) {
	if (crumb.active) {
		return <span className="active">{crumb.name}</span>;
		}

	return <Link to={crumb.url}>{crumb.name}</Link>;
	}

Breadcrumbs.propTypes = {
	crumbs: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.node,
		url: PropTypes.string,
		active: PropTypes.bool,
		})).isRequired,
	};

export default Breadcrumbs;
