/*
* core/static/react/core/components/HiddenComponent.jsx
* Quizzera
* Author: Rushy Panchal
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';

/*
* A HiddenComponent is a wrapper for components. It will optionally
* hide a component, based on the 'hidden' prop, using inline styling.
* By default, this sets the 'display' style to 'none'. It can also be used
* to set the 'visibility' to 'hidden'.
*
* Optional Props
*   Boolean hidden - whether or not the component is hidden
*   Boolean visibility - use 'visibility:hidden' instead of 'display:none'
*
*/
function HiddenComponent(props) {
  let { hidden, visibility, ...other} = props;

  if (hidden) {
    let style = {},
        comp = null;

    if (visibility) {
      style.visibility = 'hidden';
      comp = props.children;
      }
    else {
      style.display = 'none';
      }
    return <span style={style} {...other}>{comp}</span>;
    }
  return <span {...other}>{props.children}</span>;
  }

HiddenComponent.propTypes = {
  hidden: PropTypes.bool,
  visibility: PropTypes.bool,
  };

HiddenComponent.defaultProps = {
  hidden: false,
  visibility: false
  };

export default React.memo(HiddenComponent);
