/*
* pages/admin/enrollments/BulkEnrollmentEditor.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Bulk-enroll users into a course. This component simply provides
*              the view for editing that data.
*/

import "styles/custom.scss";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Switch, EditableText, Alert, Intent } from '@blueprintjs/core';
import update from 'immutability-helper';

import LoaderButton from 'components/LoaderButton';

class BulkEnrollmentEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
      is_admin: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    onEnroll: PropTypes.func.isRequired,

    loading: PropTypes.bool,
    error: PropTypes.string,
    separator: PropTypes.string,
    maxEditLines: PropTypes.number,
    };

  static defaultProps = {
    loading: false,
    error: '',
    separator: '\n',
    maxEditLines: 10,
    };

  constructor(props) {
    super(props);
    this.state = {
      requireAdminConfirm: false,
      confirmAdmin: false,
      };

    this.onAdminToggle = this.onAdminToggle.bind(this);
    this.onUsersEdit = this.onUsersEdit.bind(this);
    this.onEnroll = this.onEnroll.bind(this);
    }

  render() {
    return (<div>
      <p>
        To enroll users, enter their usernames below, with <b>each user on a
        new line.</b>
      </p>
      <p>
        For users that do not already have an account on Quizzera, they will
        have an account created for them. All users will then be enrolled
        into the course, potentially as admins (as specified by the switch).
      </p>
      <hr/>

      <h4>Users</h4>

      <br/>
      <EditableText
        value={this.props.value.users.join(this.props.separator)}
        onChange={this.onUsersEdit}
        placeholder='Click here to add users.'
        multiline
        minLines={this.props.maxEditLines / 2}
        maxLines={this.props.maxEditLines}
      />
      <br/>

      <Alert
        isOpen={this.state.requireAdminConfirm}
        cancelButtonText='Cancel'
        confirmButtonText='Continue'
        intent={Intent.DANGER}
        onCancel={() => this.setState({requireAdminConfirm: false},
                                      this.onAdminToggle)}
        onConfirm={() =>
          this.setState({confirmAdmin: true, requireAdminConfirm: false},
            this.onEnroll)}>
        <p>
        This will enroll all <b>{this.props.value.users.length}</b> inputted
        users as administrators, which grants them full access to managing
        the course. Are you sure?
        </p>
      </Alert>

      <Row>
        <Col xs={6}>
          <Switch
            checked={this.props.value.is_admin}
            onChange={this.onAdminToggle}
            label='Add Users as Administrators'
            large
          />
        </Col>
        <Col xs={6}>
          <LoaderButton loading={this.props.loading} onClick={this.onEnroll}>
            Enroll
          </LoaderButton>
        </Col>
      </Row>
    </div>);
    }

  onAdminToggle() {
    const newIsAdmin = !this.props.value.is_admin;
    const val = update(this.props.value, {is_admin: {$set: newIsAdmin}});
    this.props.onChange(val);
    }

  onUsersEdit(value) {
    const users = (value
      .split(this.props.separator)
      // Remove any spaces/tabs
      .map(x => x.replace(/[ \t]/g, ''))
      // Remove any blank entries, except for the last.
      .filter((x, index, xs) => index === xs.length - 1 || Boolean(x)));
    const val = update(this.props.value, {users: {$set: users}});
    this.props.onChange(val);
    }

  onEnroll() {
    // Remove the trailing blank users, if one exists.
    const users = this.props.value.users.filter(Boolean);
    if (users.length !== this.props.value.users.length) {
      const val = update(this.props.value, {users: {$set: users}});
      this.props.onChange(val);
      }

    if (this.props.value.is_admin && !this.state.confirmAdmin) {
      this.setState({requireAdminConfirm: true});
      return;
      }

    this.setState({confirmAdmin: false});
    this.props.onEnroll(this.props.value);
    }
  }

export default BulkEnrollmentEditor;
