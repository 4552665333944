/*
* pages/admin/enrollments/EnrollmentsController.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
*/

import React from 'react';
import { connect } from 'react-redux';

import { Enrollment } from 'lib/api';
import { actions, selectors, types } from 'state/resources';
import { getErrorMsg } from 'lib/utils';
import EnrollmentsPage from './EnrollmentsPage';

const ENROLLMENT_LOAD_KEY = 'admin-load-enrollments';
const BULK_ENROLL_KEY = 'admin-bulk-enrollment';

class EnrollmentsController extends React.Component {
  constructor(props) {
    super(props);
    this.onEnroll = this.onEnroll.bind(this);
    }

  componentDidMount() {
    const action = actions.loadResourceList(
      Enrollment,
      ENROLLMENT_LOAD_KEY,
      {course_id: this.props.id});
    this.props.dispatch(action);
    }

  render() {
    const { dispatch, ...other } = this.props;
    return (<EnrollmentsPage
      onEnroll={this.onEnroll}
      {...other}
      />);
    }

  onEnroll(data) {
    const resource = new Enrollment();
    const p = (resource
      .bulkEnroll(this.props.id,
                  data.users.map(u => {return {username: u}}),
                  data.is_admin)
      .then(data => data.enrollments));
    const action = actions.resolvePromise(
      p,
      resource.type,
      BULK_ENROLL_KEY,
      types.ACTIONS.BULK_CREATE_RESOURCE,
      );

    this.props.dispatch(action);
    }
  }

function mapStateToProps(state, ownProps) {
  const enrollments = selectors.getResource(state, Enrollment);
  const course_enrollments = selectors.filterResource(
    enrollments,
    {course_id: Number(ownProps.id)});
  const pending = selectors.getPending(enrollments, ENROLLMENT_LOAD_KEY);
  const createPending = selectors.getPending(enrollments, BULK_ENROLL_KEY);

  return {
    enrollments: course_enrollments,
    loading: pending.loading,
    error: getErrorMsg(pending.error),
    createLoading: createPending.loading,
    createError: getErrorMsg(createPending),
    createCompleted: createPending.completed,
    };
  }

export default connect(mapStateToProps)(EnrollmentsController);
