/*
* pages/Dashboard/index.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Listing of all accessible courses.
*/

import DashboardController from './DashboardController';

export default DashboardController;
