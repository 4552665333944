/*
* pages/admin/extensions/ExtensionsPage.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: View for editing extensions.
*/

import "styles/custom.scss";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { Drawer, Classes as BlueprintClasses } from '@blueprintjs/core';
import { orderBy } from 'lodash';
import moment from 'moment';

import Loader from 'components/Loader';
import APIErrorAlert from 'components/APIErrorAlert';
import TableList from 'components/TableList';
import StatusCheck from 'components/StatusCheck';
import HelpIcon from 'components/HelpIcon';
import HoverTooltip from 'components/HoverTooltip';

import BulkExtensionEditor from './BulkExtensionEditor';

const UserShape = PropTypes.shape({
  username: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  });

const QuizShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  });

class ExtensionsPage extends React.PureComponent {
  static propTypes = {
    extensions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      quiz: QuizShape.isRequired,
      user: UserShape.isRequired,
      granted_by: UserShape.isRequired,
      end_time: PropTypes.string.isRequired,
      added_time: PropTypes.string.isRequired,
      })).isRequired,

    enrollments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_admin: PropTypes.bool.isRequired,
      user: UserShape.isRequired,
      })).isRequired,

    quizzes: PropTypes.arrayOf(QuizShape.isRequired).isRequired,

    onExtend: PropTypes.func.isRequired,

    loading: PropTypes.bool,
    error: PropTypes.string,
    createLoading: PropTypes.bool,
    createError: PropTypes.string,
    createCompleted: PropTypes.bool,
    };

  static defaultProps = {
    loading: false,
    error: '',
    createLoading: false,
    createError: '',
    createCompleted: false,
    };

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      pendingExtensions: {
        quiz: 0,
        users: [],
        end_time: new Date(Date.now()).toISOString(),
        },
      };

    this.onDrawerToggle = this.onDrawerToggle.bind(this);
    }

  componentDidUpdate(prevProps) {
    if (!prevProps.createCompleted && this.props.createCompleted) {
      this.setState({drawerOpen: false});
      }
    }

  render() {
    return (<div className='container-fluid'>
      <APIErrorAlert msg={this.props.error} />
      <Loader loading={this.props.loading} />

      <Row>
        <Col xs={6} md={8}>
          <h3>
            Quiz Extensions&nbsp;
            <HelpIcon>
              Extensions with a green check next to them are currently
              active.
            </HelpIcon>
          </h3>
        </Col>
        <Col xs={6} md={4}>
          <Button onClick={this.onDrawerToggle} variant='dark'>
            Grant Extensions
          </Button>
        </Col>
      </Row>
      <hr/>

      <Drawer
        onClose={this.onDrawerToggle}
        isOpen={this.state.drawerOpen}
        title='Grant Extensions'
      >
        <div className={BlueprintClasses.DRAWER_BODY}>
          <div className={BlueprintClasses.DIALOG_BODY}>
            <BulkExtensionEditor
              onChange={v => this.setState({pendingExtensions: v})}
              value={this.state.pendingExtensions}
              quizzes={this.props.quizzes}
              users={this.props.enrollments.map(e => e.user.username)}
              onExtend={this.props.onExtend}
              loading={this.props.createLoading}
              error={this.props.createError}
            />
          </div>
        </div>
      </Drawer>

      {this.getExtensionTable()}
    </div>);
    }

  onDrawerToggle() {
    this.setState({drawerOpen: !this.state.drawerOpen});
    }

  getExtensionTable() {
    const spec = {
      'id': 'id',
      'quiz_title': 'Quiz',
      'username': 'User',
      'end_time': 'Extension Date',
      'granted_by': 'Granted By',
      'added_time': 'Granted Time',
      };
    const specOptions = {
      id: {isKey: true, hidden: true},
      end_time: {dataFormat: ExtensionsPage.getEndTimeComponent},
      added_time: {dataFormat: ExtensionsPage.getDateComponent},
      };
    const tableOptions = {
      striped: true,
      search: true,
      exportCSV: true,
      hover: true,
      page: 1,
      sizePerPage: 25,
      sizePerPageList: [
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: 'All', value: this.props.extensions.length},
        ]
      };

    const sorted = orderBy(
        this.props.extensions,
        ['quiz.end_time', 'user.username'],
        ['desc', 'asc'])
      .map(x => {return {
        id: x.id,
        quiz_title: x.quiz.title,
        username: x.user.display_name,
        end_time: x.end_time,
        granted_by: x.granted_by.display_name,
        added_time: x.added_time,
        }});

    return (
      <TableList
        specification={spec}
        specificationOptions={specOptions}
        data={sorted}
        options={tableOptions}
      />);
    }

  static getEndTimeComponent(cell, row) {
    const end_time = ExtensionsPage.getDateComponent(cell, row);
    if (moment(cell).isBefore(moment())) {
      return end_time;
      }

    return (<HoverTooltip tooltip='The extension is currently active.'>
      <span>{end_time} <StatusCheck success /></span>
    </HoverTooltip>);
    }

  static getDateComponent(cell, row) {
    return new Date(cell).toLocaleString();
    }
  }

export default ExtensionsPage;
