/*
* lib/api/resources.ts
* Author: Rushy Panchal
* Date: July 20th, 2019
* Description: Resource models for interacting with the Quizzera API.
*/

import Promise from 'bluebird';
import request from 'request';

import Model from 'types/abstract/Model';
import { Id } from 'types/base';
import { Client, Resource, Filter } from  'types/api';

import APIClient from './client';

abstract class BaseResource<T extends Model> implements Resource<T> {
  protected _client: Client = new APIClient();

  abstract getType(): string;

  get(id: Id): Promise<T> {
    return this._client
      .get(this.type, {params: {id: id}});
    }

  create(data: T): Promise<T> {
    return this._client
      .post(this.type, {params: {}}, {body: data} as request.Options);
    }

  list(filter?: Filter): Promise<Array<T>> {
    return this._client
      .get(this.type, {params: {}}, {qs: filter} as request.Options);
    }

  update(id: Id, data: T): Promise<T> {
    return this._client
      .put(this.type,
           {params: {id: id}},
           {body: data} as request.Options);
    }

  delete(id: Id): Promise<boolean> {
    return this._client
      .delete(this.type, {params: {id: id}})
      .then(() => true);
    }

  get type() {
    return this.getType();
    }
  }

export default BaseResource;
