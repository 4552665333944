/* eslint-disable no-template-curly-in-string */
/*
* lib/jwt.ts
* Author: Rushy Panchal
* Date: June 19th, 2019
* Description: JSON Web Tokens functionality.
*/

import moment from 'moment';

import * as store from './store';

const TOKEN_KEY = "jwt-token";
const HEADER = 'Authorization';
const REFRESH_DELTA = moment.duration({hours: 12});

export interface Token {
  access: string;
  refresh: string;
  }

interface TokenPayload {
  [index: string]: any;
  email: string;
  user_id: number;
  exp: number;
  jti: string;
  token_type: string;
  }

interface StoredToken {
  token: Token;
  payload: TokenPayload;
  }

function save_token(token : Token, includes_refresh : boolean = true) : void {
  /* Save the token to local storage. */
  let data = {token: token,
              payload: parse_token(token.access)};
  let existing_token = get_token();

  if (! includes_refresh && existing_token !== null) {
    data.token.refresh = existing_token.refresh;
    }

  store.set_json(TOKEN_KEY, data);
  }

function get_token() : Token | null {
  /* Retrieve the token from local storage. */
  const stored = store.get_json(TOKEN_KEY) as StoredToken;
  if (stored !== null) {
    return stored.token;
    }

  return null;
  }

function get_payload(key : string) : any {
  /*
  Get the payload from the stored token.
  */
  const stored = store.get_json(TOKEN_KEY) as StoredToken;
  if (stored !== null) {
    return stored.payload[key];
    }

  return null;
  }

function clear_token() : void {
  /*
  * Clear any stored token.
  */
  store.remove(TOKEN_KEY);
  }

function get_header() : string | null {
  /*
  * Get the authorization header for use in requests.
  */
  const token = get_token();
  if (token === null) {
    return null;
    }

  return `Bearer ${token.access}`;
  }

function needs_refresh() : boolean | string | null {
  /*
  * Check if the token needs to be refreshed.
  * If it does, returns th e
  */
  const stored = store.get_json(TOKEN_KEY) as StoredToken;
  if (stored === null) {
    return null;
    }

  let access_expiry = moment.unix(stored.payload.exp),
      refresh_expiry = moment.unix(parse_token(stored.token.refresh).exp);

  // Refresh if:
  //   1. now >= expiry
  //   2. (expiry - now) < delta <==> expiry < delta + now
  if (moment().isSameOrAfter(access_expiry)
    || moment().add(REFRESH_DELTA).isAfter(access_expiry)) {
    // Check if it is possible to refresh the token (i.e. is the refresh token
    // valid).
    if (refresh_expiry.isBefore(moment())) {
      throw new Error('refresh token is not valid');
      }

    return stored.token.refresh;
    }

  return false;
  }

function parse_token(token : string) : TokenPayload {
  /* Parse the token as JSON. */
  return JSON.parse(atob(token.split('.')[1]));
  }

export {
  HEADER,
  save_token,
  get_header,
  get_payload,
  clear_token,
  needs_refresh,
  };
