/*
* lib/api/index.ts
* Author: Rushy Panchal
* Date: June 17th, 2019
* Description: Utilities for accessing the Quizzera API.
*/

import Promise from 'bluebird';
import request from 'request';
import { Client, Filter } from 'types/api';
import { Id } from 'types/base';
import * as models from 'types/models';

import APIClient from './client';
import BaseResource from './resources';

class CurrentUser implements models.User {
  private client: Client;

  constructor() {
    this.client = new APIClient();
    }

  get id() {
    return Number(this.client.user_id);
    }

  get username() {
    return this.client.username;
    }

  get display_name() {
    return this.client.display_name;
    }

  get is_staff() {
    return this.client.is_staff;
    }

  get is_superuser() {
    return this.client.is_superuser;
    }
  }

class Course extends BaseResource<models.Course> {
  getType() {
    return 'course';
    }

  clone(id: Id, copy_admins: boolean = false): Promise<models.Course> {
    const data = {copy_admins: copy_admins};

    return this._client
      .post('course:clone',
            {params: {id: id}},
            {body: data} as request.Options);
    }
  }

class Quiz extends BaseResource<models.Quiz> {
  getType() {
    return 'quiz';
    }
  }

type ObjectOrder = {[index: number]: number};

class Question extends BaseResource<models.Question> {
  getType() {
    return 'question';
    }

  reorder(
    course_id: Id,
    quiz_id: Id,
    order: ObjectOrder): Promise<ObjectOrder>  {
    const data = {course_id, quiz_id, order, parent_id: quiz_id};

    return this._client
      .post('question:reorder', {params: {}}, {body: data} as request.Options);
    }
  }

class QuestionAttempt extends BaseResource<models.QuestionAttempt> {
  getType() {
    return 'attempt';
    }

  initial(question: Id, course: Id): Promise<models.QuestionAttempt> {
    const data = {question_id: question, course_id: course};

    return this._client
      .post('attempt:initial', {params: {}}, {body: data} as request.Options);
    }
  }

class Extension extends BaseResource<models.Extension> {
  getType() {
    return 'extension';
    }

  latest(filter?: Filter): Promise<Array<models.Extension>> {
    return this._client
      .get('extension:list', {params: {}}, {qs: filter} as request.Options);
    }

  grantMany(
      course: Id,
      quiz: Id,
      users: models.User[],
      end_time: Date
      ): Promise<Array<models.Extension>> {
    const data = {
      course_id: course,
      quiz_id: quiz,
      users: users.map(x => x.username),
      end_time: end_time.toISOString(),
      };

    return this._client
      .post('extension:bulk', {}, {body: data} as request.Options);
    }
  }

class Enrollment extends BaseResource<models.Extension> {
  getType() {
    return 'enrollment';
    }

  bulkEnroll(
      course: Id,
      users: models.User[],
      is_admin: boolean = false
      ):
      Promise<{users_created: models.User[],
               enrollments: models.Enrollment[]}> {
    const data = {
      course_id: course,
      users: users.map(x => x.username),
      is_admin: is_admin
      };

    return this._client
      .post('enrollment:bulk', {}, {body: data} as request.Options);
    }
  }

export {
  APIClient,
  CurrentUser,
  Course,
  Quiz,
  Question,
  QuestionAttempt,
  Extension,
  Enrollment,
  };
