/*
* pages/admin/extensions/ExtensionsController.jsx
* Author: Rushy Panchal
* Date: July 25th, 2019
*/

import React from 'react';
import { connect } from 'react-redux';

import { Extension, Enrollment, Quiz } from 'lib/api';
import { actions, selectors, types } from 'state/resources';
import { getErrorMsg } from 'lib/utils';
import ExtensionsPage from './ExtensionsPage';

const EXTENSION_LOAD_KEY = 'admin-load-extensions';
const BULK_EXTENSION_KEY = 'admin-bulk-extensions';
const ENROLLMENT_LOAD_KEY = 'admin-load-enrollments';
const QUIZ_LOAD_KEY = 'admin-load-quizzes';

class ExtensionsController extends React.Component {
  constructor(props) {
    super(props);
    this.onExtend = this.onExtend.bind(this);
    }

  componentDidMount() {
    this.props.dispatch(actions.loadResourceList(
      Extension,
      EXTENSION_LOAD_KEY,
      {quiz__course_id: this.props.id}));

    this.props.dispatch(actions.loadResourceList(
      Enrollment,
      ENROLLMENT_LOAD_KEY,
      {course_id: this.props.id}));

    this.props.dispatch(actions.loadResourceList(
      Quiz,
      QUIZ_LOAD_KEY,
      {course_id: this.props.id}));
    }

  render() {
    const { dispatch, ...other } = this.props;
    return (<ExtensionsPage
      onExtend={this.onExtend}
      {...other}
      />);
    }

  onExtend(data) {
    const resource = new Extension();
    const p = (resource
      .grantMany(
        this.props.id,
        data.quiz,
        data.users.map(x => {return {username: x}}),
        new Date(data.end_time),
        ));
    const action = actions.resolvePromise(
      p,
      resource.type,
      BULK_EXTENSION_KEY,
      types.ACTIONS.BULK_CREATE_RESOURCE);

    this.props.dispatch(action);
    }
  }

function mapStateToProps(state, ownProps) {
  const extensions = selectors.getResource(state, Extension);
  const course_extensions = selectors.filterResource(
    extensions,
    {course_id: Number(ownProps.id)});

  const enrollments = selectors.getResource(state, Enrollment);
  const course_enrollments = selectors.filterResource(
    enrollments,
    {course_id: Number(ownProps.id)});

  const quizzes = selectors.getResource(state, Quiz);
  const course_quizzes = selectors.filterResource(
    quizzes,
    {course_id: Number(ownProps.id)}
    );

  const extensionPending = selectors.getPending(
    extensions,
    EXTENSION_LOAD_KEY);
  const enrollmentPending = selectors.getPending(
    enrollments,
    ENROLLMENT_LOAD_KEY);
  const quizPending = selectors.getPending(
    quizzes,
    QUIZ_LOAD_KEY);
  const createPending = selectors.getPending(
    extensions,
    BULK_EXTENSION_KEY);

  return {
    extensions: course_extensions,
    enrollments: course_enrollments,
    quizzes: course_quizzes,
    loading: (extensionPending.loading
              || enrollmentPending.loading
              || quizPending.loading),
    error: getErrorMsg(extensionPending.error
                        || enrollmentPending.error
                        || quizPending.error),
    createLoading: createPending.loading,
    createError: getErrorMsg(createPending.error),
    createCompleted: createPending.completed,
    };
  }

export default connect(mapStateToProps)(ExtensionsController);
