/*
* TimingExercise/TimingExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import NumericQuestion from '../../quizgen/web/questions/NumericQuestion.jsx';

function TimingExercise(props) {
  return (<NumericQuestion {...props} />);
}

export default TimingExercise;
