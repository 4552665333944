/*
* pages/student/question/InitialAttempt.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Controller for the question attempt page.
*/

import React from 'react';
import { Redirect } from 'react-router-dom';

import { QuestionAttempt } from 'lib/api';
import { urljoin } from 'lib/utils';

import Loader from 'components/Loader';

class InitialAttempt extends React.Component {
  state = {
    loading: false,
    attempt: null,
    }

  componentDidMount() {
    const { question, course } = this.props.match.params;
    const resource = new QuestionAttempt();

    this.setState({loading: true});
    resource.initial(question, course)
      .then(data => this.setState({attempt: data}))
      .finally(() => this.setState({loading: false}));
    }

  render() {
    const { loading, attempt } = this.state;
    if (loading || attempt === null) {
      return <Loader loading />;
      }

    return <Redirect to={this.getRedirectUrl(attempt.id)} />;
    }

  getRedirectUrl(attempt_id) {
    return urljoin(this.props.match.url, 'attempt', attempt_id.toString());
    }
  }

export default InitialAttempt;
