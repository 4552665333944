/*
* pages/Dashboard/DashboardPage.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Listing of all accessible courses.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { CardColumns } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { some } from 'lodash';

import Loader from 'components/Loader.jsx';
import APIErrorAlert from 'components/APIErrorAlert.jsx';
import CourseCard from './CourseCard.jsx';
import CreateCourseCard from './CreateCourseCard.jsx';
import { urljoin } from 'lib/utils';
import { CurrentUser } from 'lib/api';

class DashboardPage extends React.PureComponent {
  state = {
    redirectUrl: null,
    };

  static propTypes = {
    courses: PropTypes.arrayOf(PropTypes.object).isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
    createError: PropTypes.string.isRequired,
    createLoading: PropTypes.bool.isRequired,
    };

  getCreateComponent() {
    const user = new CurrentUser();
    if (some(this.props.courses, 'is_admin') || user.is_staff) {
      return (<CreateCourseCard
        onRedirect={url => this.setState({redirectUrl: url})}
        error={this.props.createError}
        loading={this.props.createLoading}
        onCreate={this.props.onCreate}
        />);
      }

    return null;
    }

  render() {
    /* Render the component. */
    let redirect = (this.state.redirectUrl !== null ?
      <Redirect to={this.state.redirectUrl} />: null);

    return (<div className='container-fluid'>
      <APIErrorAlert msg={this.props.error} />
      <Loader loading={this.props.loading} />
      {redirect}

      <h2>Courses</h2>
      <CardColumns>
        {this.props.courses.map(course =>
         <CourseCard
           key={`course-${course.id}`}
           link={urljoin('/courses', course.id.toString())}
           manageLink={urljoin('/admin', course.id.toString())}
           {...course} />
        )}
        
        {this.getCreateComponent()}
      </CardColumns>
      </div>);
    }
  }

export default DashboardPage;
