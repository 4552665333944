/*
* LinearProgrammingValueOfVariableExercise/LinearProgrammingValueOfVariableExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import NumericQuestion from '../../quizgen/web/questions/NumericQuestion.jsx';

function LinearProgrammingValueOfVariableExercise(props) {
  return (<NumericQuestion {...props} />);
}

export default LinearProgrammingValueOfVariableExercise;
