/*
* questions/static/react/quizgen/MultipleChoiceQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import GenericQuizgenQuestion from './GenericQuizgenQuestion.jsx';
import MultipleChoiceInput from '../input/MultipleChoiceInput.jsx';

/*
* An MultipleChoiceQuestion component handles input and submission
* of questions that accept multiple-choice answers for multiple
* answers using checkboxes.
*/
class MultipleChoiceQuestion extends React.Component {
	render() {
		/* Render the component. */

		return (<GenericQuizgenQuestion input={MultipleChoiceInput}
			inputData={true} {...this.props} />);
		}
	}

export default MultipleChoiceQuestion;
