/*
* DijkstraExercise/DijkstraExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import TypedArrayQuestion from '../../quizgen/web/questions/TypedArrayQuestion.jsx';

function DijkstraExercise(props) {
  return (<TypedArrayQuestion type={TypedArrayQuestion.types.int} {...props} />);
}

export default DijkstraExercise;
