/*
* WeightedQuickUnionGenerabilityExercise/WeightedQuickUnionGenerabilityExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import MultipleChoiceQuestion from '../../quizgen/web/questions/MultipleChoiceQuestion.jsx';

function WeightedQuickUnionGenerabilityExercise(props) {
  return (<MultipleChoiceQuestion {...props} />);
}

export default WeightedQuickUnionGenerabilityExercise;
