/*
* pages/admin/QuestionEditor.ts
* Quizzera
* Author: Rushy Panchal
* Date: July 16th, 2019
* Description: Edit the details of a course.
*/

import "styles/custom.scss"

import { Question } from "types/models";

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import update from 'immutability-helper';

import GenericModelEditor from 'components/GenericModelEditor';
import HelpIcon from 'components/HelpIcon';

interface QuestionEditorProps {
  value: Question;
  onChange: (val:Question) => void;
  readOnly?: boolean;
  create?: boolean;
  };

const GRADING:{[index:string]:any} = {
  Average: 1,
  Maximum: 2,
  };

function QuestionEditor(props : QuestionEditorProps) {
  const value = props.value === null ? empty() : props.value;

  const onChange = React.useCallback(e => {
    e.preventDefault();
    let valueUpdate : {[index:string]: any} = {},
        receivedValue : any = e.target.value,
        name : string = e.target.name;

    if (e.target.type === "number" || e.target.type === "select-one") {
      receivedValue = Number(receivedValue);
      }

    valueUpdate[name] = {$set: receivedValue};
    props.onChange(update(value, valueUpdate));
    },
    [props, value]);

  return (<GenericModelEditor {...props}>
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              value={value.title}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="provider">
            <Form.Label>
              Provider&nbsp;
              <HelpIcon>
                The provider of a question cannot be changed once it is
                created, as the attempts on the question rely on this
                provider. To change the provider, create a new question and
                then delete the existing one.
              </HelpIcon>
            </Form.Label>
            <Form.Control
              name="provider"
              type="text"
              value={value.provider}
              readOnly={! props.create}
              onChange={onChange} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={4}>
          <Form.Group controlId="max_score">
            <Form.Label>Max Score</Form.Label>
            <Form.Control
              name="max_score"
              type="text"
              value={value.max_score}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>

        <Col xs={6} md={4}>
          <Form.Group controlId="max_attempts">
            <Form.Label>Max Attempts</Form.Label>
            <Form.Control
              name="max_attempts"
              type="number"
              value={String(value.max_attempts)}
              onChange={onChange}
              readOnly={props.readOnly} />
          </Form.Group>
        </Col>

        <Col xs={6} md={4}>
          <Form.Group controlId="grading">
            <Form.Label>Grading</Form.Label>
            <Form.Control
              as="select"
              name="grading"
              type="number"
              value={String(value.grading)}
              onChange={onChange}
              readOnly={props.readOnly}>
            {Object.keys(GRADING).map(name =>
              <option value={GRADING[name]} key={name}>
              {name}
              </option>
            )}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Form>
    </GenericModelEditor>);
  }

function empty() : Question {
  return {
    id: 0,
    index: 0,
    quiz: 0,
    title: '',
    provider: '',
    max_score: '',
    max_attempts: 0,
    grading: 1,
    };
  };

// TODO: what do we do about these? Ideally, we can dynamically generate them
// using something like
// https://github.com/milesj/babel-plugin-typescript-to-proptypes.
QuestionEditor.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    provider: PropTypes.string,
    max_score: PropTypes.string,
    max_attempts: PropTypes.number,
    grading: PropTypes.number,
    }),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  create: PropTypes.bool,
  };

QuestionEditor.defaultProps = {
  value: null,
  readOnly: false,
  create: false,
  };

export default React.memo(QuestionEditor);
