/*
* PartialInsertionSelectionExercise/PartialInsertionSelectionExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import ArrayQuestion from '../../quizgen/web/questions/ArrayQuestion.jsx';

function PartialInsertionSelectionExercise(props) {
  return (<ArrayQuestion {...props} />);
}

export default PartialInsertionSelectionExercise;
