/*
* state/resources/initial.ts
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Generates the initial resources state.
*/

import * as types from './types';
import Model from 'types/abstract/Model';

export function initialResourceState() {
  return {
    pending: {},
    objects: {},
    };
  }

export function dataToState<T extends Model>(
    data: T): types.ResourceObject<T> {
  return {
    data: data,
    initial: undefined,
    };
  }

export default function(): types.GlobalResourceState {
  return {};
  }
