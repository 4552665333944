/*
* ShellsortExercise/ShellsortExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import TypedArrayQuestion from '../../quizgen/web/questions/TypedArrayQuestion.jsx';

function ShellsortExercise(props) {
  return (<TypedArrayQuestion type={TypedArrayQuestion.types.int} {...props} />);
}

export default ShellsortExercise;
