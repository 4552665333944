/*
* state/resources/actions.ts
* Author: Rushy Panchal
* Date: July 22nd, 2019
* Description: Primary action types for working with resources.
*/

import { Dispatch } from 'redux';
import { Duration } from 'moment';

import { Id } from 'types/base';
import { Filter, ResourceCreator } from 'types/api';
import Model from 'types/abstract/Model';

import * as types from './types';
import * as cache from 'lib/cache';

type Dispatcher<T extends Model> = Dispatch<types.Action<T>>;
type AsyncAction<T extends Model> = (dispatcher: Dispatcher<T>) => Promise<types.ActionResult<T>>;

export function loadResource<T extends Model>(
    t: ResourceCreator<T>,
    id: Id,
    cache_duration?: Duration)
    : AsyncAction<T> {
  const resource = new t();
  const p = cache.promise(
    `${resource.type}-${id}`,
    () => resource.get(id),
    cache_duration,
    );

  return resolvePromise<T>(
    p,
    resource.type,
    id.toString(),
    types.ACTIONS.LOAD_RESOURCE
    );
  }

export function loadResourceList<T extends Model>(
    t: ResourceCreator<T>,
    key: string,
    filter?: Filter,
    cache_duration?: Duration)
    : AsyncAction<T> {
  const resource = new t();
  const p = cache.promise(
    `${resource.type}-${key}`,
    () => resource.list(filter),
    cache_duration)
    .then(xs => {
      // If a cache is requested, also cache each individual item in the
      // resulting list.
      if (cache_duration !== undefined) {
        xs.forEach(
          x => cache.set(`${resource.type}-${x.id}`, x, cache_duration));
        }

      return xs;
      });

  return resolvePromise<T>(
    p,
    resource.type,
    key,
    types.ACTIONS.LOAD_RESOURCE_LIST,
    );
  }

export function createResource<T extends Model>(
    t: ResourceCreator<T>,
    key: string,
    data: T)
    : AsyncAction<T> {
  const resource = new t();

  return resolvePromise<T>(
    resource.create(data),
    resource.type,
    key,
    types.ACTIONS.CREATE_RESOURCE,
    );
  }

export function updateResource<T extends Model>(
    t: ResourceCreator<T>,
    id: Id,
    data: T)
    : AsyncAction<T> {
  const resource = new t();
  const p = resource.update(id, data)
    .tap(x => cache.update_if_exists(`${resource.type}-${x.id}`, x));

  return resolvePromise<T>(
    p,
    resource.type,
    id.toString(),
    types.ACTIONS.UPDATE_RESOURCE,
    );
  }

export function deleteResource<T extends Model>(
    t: ResourceCreator<T>,
    id: Id)
    : AsyncAction<T> {
  const resource = new t();

  return resolvePromise<T>(
    resource.delete(id).then(() => id),
    resource.type,
    id.toString(),
    types.ACTIONS.DELETE_RESOURCE,
    );
  }

export function resolvePromise<T extends Model>(
    p: Promise<types.ActionResult<T>>,
    resource_type: string,
    key: string,
    action_type: types.ACTIONS,
    ) : AsyncAction<T> {

  const pendingAction : types.ResourcePendingAction<T> = {
    type: action_type,
    payload: {
      resource_type,
      key,
      },
    };

  return (dispatch: Dispatcher<T>) => {
    dispatch(pendingAction);
    
    return p
      .then(res => dispatch(finishRequest(pendingAction, res, true)))
      .catch(e => dispatch(finishRequest(pendingAction, e, false)))
      .then(action => action.payload.result);
    }
  }

function finishRequest<T extends Model>(
    pending_action: types.ResourcePendingAction<T>,
    result: types.ActionResult<T>,
    success: boolean)
    : types.ResourceFinishedAction<T> {
  const action_type = (success
    ? types.ASYNC_STATUS_ACTIONS.FINISH_REQUEST
    : types.ASYNC_STATUS_ACTIONS.FAIL_REQUEST);

  return {
    type: action_type,
    payload: {
      resource_type: pending_action.payload.resource_type,
      key: pending_action.payload.key,
      action_intent: pending_action.type,
      result,
      }
    };
  }

export function editResource<T extends Model>(
    t: ResourceCreator<T>,
    id: Id,
    update: Partial<T>)
    : AsyncAction<T> {
  const resource = new t();
  const pendingAction = {
    type: types.LOCAL_ACTIONS.EDIT_RESOURCE,
    payload: {
      id: id,
      resource_type: resource.type,
      update: update,
      },
    };

  return (dispatch: Dispatcher<T>) => {
    dispatch(pendingAction);
    return Promise.resolve(id);
    };
  }
