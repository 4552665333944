/*
* questions/static/react/quizgen/SingleChoiceQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import GenericQuizgenQuestion from './GenericQuizgenQuestion.jsx';
import MultipleChoiceInput from '../input/MultipleChoiceInput.jsx';

/*
* An SingleChoiceQuestion component handles input and submission
* of questions that accept a single choice using radiobuttons.
*/
class SingleChoiceQuestion extends React.Component {
	render() {
		/* Render the component. */

		/* The 'many' value of false dictates whether one or multiple selections
		should be allowed. See MultipleChoiceInput for clarification. */
		return (<GenericQuizgenQuestion input={MultipleChoiceInput}
			inputProps={{many: false}} inputData={true} {...this.props} />);
		}
	}

export default SingleChoiceQuestion;
