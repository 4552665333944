import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux'

import QuizzeraRouter from './Router';
import "./styles/custom.scss"

function App({store}) {
  return (
    <div className="body">
      <Provider store={store}>
        <QuizzeraRouter />
      </Provider>
    </div>
  );
}

App.propType = {
  store: PropTypes.object.isRequired,
  }

export default App;
