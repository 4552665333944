/*
* pages/student/routes.ts
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Routing for student app.
*/

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { RouteConfig } from 'types/routes';

import Breadcrumbs from "components/Breadcrumbs.jsx";
import ResourceLoadable from "components/ResourceLoadable";

import CoursePage from './course';
import QuizPage from './quiz';
import { InitialAttempt, QuestionPage } from './question';

import { Course, Quiz, Question } from 'lib/api';

const get_routes: RouteConfig = (base: string = '') => {
  return [
    {path: `${base}/courses/:course/`,
     exact: true,
     auth: true,
     component: CoursePage,
     breadcrumb: ({match}: RouteComponentProps<{course: string}>) =>
       (<Breadcrumbs crumbs={[
         {name: "Dashboard", url: "/dashboard/"},
         {name: <ResourceLoadable resource={Course} id={match.params.course} />,
          url: match.url,
          active: true}
         ]} />)
     },
    {path: `${base}/courses/:course/quiz/:quiz/`,
     exact: true,
     auth: true,
     component: QuizPage,
     breadcrumb: ({match}: RouteComponentProps<{course: string, quiz: string}>) =>
       (<Breadcrumbs crumbs={[
         {name: "Dashboard", url: "/dashboard/"},
         {name: <ResourceLoadable resource={Course} id={match.params.course} />,
          url: `/courses/${match.params.course}/`},
         {name: <ResourceLoadable resource={Quiz} id={match.params.quiz} />,
          url: match.url,
          active: true},
         ]} />)
      },
    {path: `${base}/courses/:course/quiz/:quiz/question/:question/`,
     exact: true,
     auth: true,
     component: InitialAttempt,
     breadcrumb: ({match}: RouteComponentProps<{course: string, quiz: string, question: string}>) =>
       (<Breadcrumbs crumbs={[
         {name: "Dashboard", url: "/dashboard/"},
         {name: <ResourceLoadable resource={Course} id={match.params.course} />,
          url: `/courses/${match.params.course}/`},
         {name: <ResourceLoadable resource={Quiz} id={match.params.quiz} />,
          url: `/courses/${match.params.course}/quiz/${match.params.quiz}/`},
         {name: <ResourceLoadable resource={Question} id={match.params.question} />,
          url: match.url,
          active: true},
         ]} />)
     },
     {path: `${base}/courses/:course/quiz/:quiz/question/:question/attempt/:attempt/`,
      exact: true,
      auth: true,
      component: QuestionPage,
      breadcrumb: ({match}: RouteComponentProps<{course: string, quiz: string, question: string}>) =>
        (<Breadcrumbs crumbs={[
         {name: "Dashboard", url: "/dashboard/"},
         {name: <ResourceLoadable resource={Course} id={match.params.course} />,
          url: `/courses/${match.params.course}/`},
         {name: <ResourceLoadable resource={Quiz} id={match.params.quiz} />,
          url: `/courses/${match.params.course}/quiz/${match.params.quiz}/`},
         {name: <ResourceLoadable resource={Question} id={match.params.question} />,
          url: `/courses/${match.params.course}/quiz/${match.params.quiz}/question/${match.params.question}`},
         {name: 'Attempt',
          url: match.url,
          active: true},
         ]} />)
     }];
  };

export default get_routes;
