/*
* state/resources/types.ts
* Author: Rushy Panchal
* Date: July 22nd, 2019
* Description: Types for working with resources.
*/

import Model from 'types/abstract/Model';
import { Id, Error } from 'types/base';

// Action types
export enum ACTIONS {
  LOAD_RESOURCE = 'resources/load',
  LOAD_RESOURCE_LIST = 'resources/load-list',
  CREATE_RESOURCE = 'resources/create',
  UPDATE_RESOURCE = 'resources/update',
  DELETE_RESOURCE = 'resources/delete',
  BULK_CREATE_RESOURCE = 'resources/bulk-create',
  BULK_UPDATE_RESOURCE = 'resources/bulk-update',
  };

export enum LOCAL_ACTIONS {
  EDIT_RESOURCE = 'resources/edit',
  }

export enum ASYNC_STATUS_ACTIONS {
  FINISH_REQUEST = 'resources/finish-request',
  FAIL_REQUEST = 'resources/fail-request',
  };

// State
export interface PendingResourceRequestState {
  readonly loading: boolean;
  readonly error?: Error;
  readonly completed: boolean;
  }

export interface ResourceObject<T extends Model> {
  readonly data: T;
  // If initial is set, then the object has changed. 
  readonly initial?: T;
  }

export interface ResourceState<T extends Model> {
  readonly pending: {
    readonly [index: string]: PendingResourceRequestState,
    };
  readonly objects: {
    readonly [index: number]: ResourceObject<T>;
    };
  }

export interface GlobalResourceState {
  readonly [index:string]: any;
  };

// Actions

export type ActionResult<T> = Id | T | Array<T> | Error;

export interface ResourcePendingActionPayload<T extends Model> {
  resource_type: string;
  key: string;
  }

export interface ResourcePendingAction<T extends Model> {
  type: ACTIONS;
  payload: ResourcePendingActionPayload<T>;
  }

export interface ResourceFinishedActionPayload<T extends Model> {
  resource_type: string;
  key: string;
  action_intent: ACTIONS;
  result: ActionResult<T>;  
  }

export interface ResourceFinishedAction<T extends Model> {
  type: ASYNC_STATUS_ACTIONS;
  payload: ResourceFinishedActionPayload<T>;
  }

export interface ResourceEditActionPayload<T extends Model> {
  resource_type: string;
  id: Id;
  update: Partial<T>;
  }

export interface ResourceEditAction<T extends Model> {
  type: LOCAL_ACTIONS.EDIT_RESOURCE;
  payload: ResourceEditActionPayload<T>;
  }

export type Action<T extends Model> = ResourcePendingAction<T> | ResourceFinishedAction<T> | ResourceEditAction<T>;
