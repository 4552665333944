/*
* core/static/core/react/core/components/HoverTooltip.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Wrapper about React Bootstrap's Tooltip and OverlayTrigger
* 	components to provide a simpler way of creating tooltips that active
* 	on hovers.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

/*
* The HoverTooltip component is a simplified wrapper around Tooltip and
* OverlayTrigger.
*
* Required Props
* 	[Element] children - React elements to display as the tooltip
* 	String tooltip - text to display on hover
*
* Optional Props
* 	String placement - where to place the tooltip on hover
*/
function HoverTooltip(props) {
	var tooltip = (<Tooltip id={props.tooltip}>
		<span>{props.tooltip}</span>
		</Tooltip>);

	return (<OverlayTrigger {...props}
		overlay={tooltip}>
		{props.children}
		</OverlayTrigger>);
	}

HoverTooltip.propTypes = {
	tooltip: PropTypes.string.isRequired,
	placement: PropTypes.string,
	};

HoverTooltip.defaultProps = {
	placement: 'bottom',
	};

export default React.memo(HoverTooltip);
