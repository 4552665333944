/*
* pages/admin/quizzes/QuizzesPage.jsx
* Author: Rushy Panchal
* Date: July 31st, 2019
* Description: Quiz editing page.
*/

import 'styles/custom.scss';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { find } from 'lodash';
import * as Promise from 'bluebird';
import { Row, Col, Button } from 'react-bootstrap';
import { Drawer, Classes as BlueprintClasses } from '@blueprintjs/core';
import Calendar from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import Loader from 'components/Loader';
import LoaderButton from 'components/LoaderButton';
import APIErrorAlert from 'components/APIErrorAlert';
import HelpIcon from 'components/HelpIcon';
import QuizEditor from 'components/models/QuizEditor';
import QuestionsController from './QuestionsController';
import { getErrorMsg } from 'lib/utils';

const QuizShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  course_id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
  end_time: PropTypes.string.isRequired,
  });

const DraggableCalendar = withDragAndDrop(Calendar);

class QuizzesPage extends React.PureComponent {
  static propTypes = {
    course_id: PropTypes.number.isRequired,

    quizzes: PropTypes.arrayOf(QuizShape).isRequired,
    saved: PropTypes.object.isRequired,
    quiz_pending: PropTypes.object.isRequired,
    onQuizChange: PropTypes.func.isRequired,
    onQuizSave: PropTypes.func.isRequired,
    onQuizCreate: PropTypes.func.isRequired,
    onQuizDelete: PropTypes.func.isRequired,
    
    loading: PropTypes.bool,
    error: PropTypes.string,

    createLoading: PropTypes.bool,
    createError: PropTypes.string,
    };

  static defaultProps = {
    loading: false,
    error: '',
    createLoading: false,
    createError: '',
    };

  state = {
    drawerOpen: false,
    selectedQuiz: null,

    quizCreate: false,
    pendingQuiz: null,

    savingAll: false,
    };

  render() {
    const allSaved = Object.values(this.props.saved)
      .reduce((prev, curr) => prev && curr, true);

    return (<div className='container-fluid' style={{height: '75vh'}}>
      <APIErrorAlert msg={this.props.error} />
      <Loader loading={this.props.loading} />

      <Row>
        <Col xs={12} md={6}>
          <h3>
            Quizzes&nbsp;
            <HelpIcon>
              Click on a quiz to edit its details and contents. The dates for
              quizzes can also be changed by dragging the quizzes on the
              calendar. In addition, a quiz's duration can be lengthened or
              shorted by pulling on its edges.
            </HelpIcon>
          </h3>
        </Col>

        <Col xs={12} md={3}>
          <LoaderButton
            variant={allSaved ? 'success': 'warning'}
            disabled={allSaved}
            loading={this.state.savingAll && ! allSaved}
            onClick={this.saveAll}
          >
            {allSaved ? 'All Quizzes Saved' : 'Save All Quizzes'}
          </LoaderButton>
        </Col>

        <Col xs={6} md={3}>
          <Button
            onClick={() => this.setState({quizCreate: true, drawerOpen: true})}
            variant='dark'
          >
            Create New Quiz
          </Button>
        </Col>
      </Row>

      <Drawer
        onClose={this.onDrawerToggle}
        isOpen={this.state.drawerOpen}
        size={this.state.quizCreate ? Drawer.SIZE_STANDARD : Drawer.SIZE_LARGE}
        title={this.state.quizCreate ? 'Create Quiz': 'Manage Quiz'}
      >
        <div className={BlueprintClasses.DRAWER_BODY}>
          <div className={BlueprintClasses.DIALOG_BODY}>
            {this.getQuizEditor()}
          </div>
        </div>
      </Drawer>

      <DraggableCalendar
        className='calendar'
        localizer={Calendar.momentLocalizer(moment)}
        defaultView='month'
        defaultDate={new Date()}
        views={['month', 'week']}
        events={this.props.quizzes}
        startAccessor={q => new Date(q.start_time)}
        endAccessor={q => new Date(q.end_time)}
        draggableAccessor={q => ! this.props.quiz_pending[q.id].loading}
        onEventDrop={this.onEventChangeTime}
        onEventResize={this.onEventChangeTime}
        onSelectEvent={this.onSelectEvent}
        eventPropGetter={this.getEventProps}
        allDayAccessor={() => false}
        resizable
        popup
      />
    </div>);
    }

  getQuizEditor() {
    if (this.state.quizCreate) {
      return this.getQuizCreator();
      }

    const id = this.state.selectedQuiz;
    const quiz = find(this.props.quizzes, q => q.id === id);
    if (quiz === null || quiz === undefined) return null;

    const pending = this.props.quiz_pending[id];

    return (<Row>
      <Col xs={12} lg={6}>
        <h4>Quiz</h4>
        <APIErrorAlert msg={getErrorMsg(pending.error)} />

        <QuizEditor
          value={quiz}
          saved={this.props.saved[id] || false}
          onSave={() => this.props.onQuizSave(id)}
          onChange={val => this.props.onQuizChange(id, val)}
          readOnly={pending.loading}

          onDelete={
            () => this.props.onQuizDelete(id).then(
              () => this.onDrawerToggle())}
          deleteWarning={
            <p>
              Deleting the quiz will also delete all its questions, as well
              as all of the student attempts for those questions. This cannot
              be reversed. <b>Are you sure?</b>
            </p>
            }
          allowDelete
        />
      </Col>
      <Col xs={12} lg={6}>
        <h4>
          Questions&nbsp;
          <HelpIcon>
            The display order of the questions can be changed by dragging them
            vertically.
          </HelpIcon>
        </h4>
        <QuestionsController
          quiz_id={id}
          course_id={this.props.course_id}
          disabled={pending.loading}
        />
      </Col>
    </Row>);
    }

  getQuizCreator() {
    return (<div>
      <APIErrorAlert msg={this.props.createError} />
      <QuizEditor
        value={this.state.pendingQuiz}
        onSave={() => this.props.onQuizCreate(this.state.pendingQuiz).then(
          () => this.onDrawerToggle())}
        onChange={val => this.setState({pendingQuiz: val})}
        saved={false}
        readOnly={this.props.createLoading}
        create
      />
    </div>);
    }

  saveAll = () => {
    this.setState({savingAll: true});
    Promise.all(
      Object.keys(this.props.saved)
        .filter(id => ! this.props.saved[id])
        .map(this.props.onQuizSave))
      .then(() => this.setState({savingAll: false}));
    }

  onEventChangeTime = ({ event, start, end }) => {
    this.props.onQuizChange(
      event.id,
      {start_time: start.toISOString(), end_time: end.toISOString()});
    }

  onSelectEvent = (event) => {
    this.setState({selectedQuiz: event.id, drawerOpen: true});
    }

  onDrawerToggle = () => {
    this.setState({drawerOpen: ! this.state.drawerOpen, quizCreate: false});
    }

  getEventProps = (event) => {
    let extra = '';
    if (! this.props.saved[event.id]) {
      extra = 'unsaved';
      }

    return {className: `event ${extra}`};
    }
  }

export default QuizzesPage;
