/*
* pages/student/routes.ts
* Author: Rushy Panchal
* Date: July 25th, 2019
* Description: Routing for student app.
*/

import { RouteConfig } from 'types/routes';

import LoginPage from './Login';
import LogoutPage from './Logout';
import TokenReceiver from './TokenReceiver';

export const RECEIVE_TOKEN_PATH = '/receive-token/';

const get_routes: RouteConfig = (base: string = '') => {
  return [
    {path: `${base}/login/`,
     exact: true,
     component: LoginPage},

    {path: `${base}/logout/`,
     exact: true,
     component: LogoutPage},
    {path: `${RECEIVE_TOKEN_PATH}`,
     exact: true,
     component: TokenReceiver},
   ];
  };

export default get_routes;
