/*
* pages/Home.jsx
* Author: Rushy Panchal
* Date: July 30th, 2019
* Description: Quizzera home page.
*/

import React from 'react';
import { Redirect } from 'react-router-dom';

class HomePage extends React.PureComponent {
  render() {
    return <Redirect to='/dashboard' />;
    }
  }

export default HomePage;
