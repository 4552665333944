/*
* core/static/core/react/core/components/Header.jsx
* Quizzera
* Author: Rushy Panchal
* Description: General page header.
*/

import "../styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HiddenComponent from './HiddenComponent.jsx';
import { APIClient } from 'lib/api';

/*
* A generic page header. Should be used on every page.
*
* Required Props
* 	String user - username to display (if blank, no logout button is displayed)
*   Function logout - action when user logs out
*/
class Header extends React.PureComponent {
	state = {redirect: null};

	client = new APIClient();

	logout = () => this.setState({redirect: '/logout'});
	login = () => this.setState({redirect: '/login'});

	render() {
		const user_provided = this.client.authenticated;

		if (this.state.redirect !== null) {
			return <Redirect to={this.state.redirect} />;
			}

		return (<div className='header'>
			<Row>
				<Col md={9} xs={12}>
					<h1 className="name">
						<Link to="/">Quizzera</Link>
					</h1>
				</Col>
				<Col md={3} xs={12} className="user-logout">
					<Row>
						<Col xs={12}>
							<h3 className="user">{this.props.user}</h3>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<HiddenComponent hidden={user_provided}>
								<Button
									onClick={this.login}
									className='login'
									variant='success'>
									Login
								</Button>
							</HiddenComponent>

							<HiddenComponent hidden={!user_provided}>
								<Button
									onClick={this.logout}
									className='logout'
									variant='danger'>
									Logout
								</Button>
							</HiddenComponent>
						</Col>
					</Row>
				</Col>
			</Row>
			</div>);
		}
	}


Header.propTypes = {
	user: PropTypes.string,
	logout: PropTypes.func.isRequired,
	};

export default Header;
