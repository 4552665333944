/*
* questions/static/react/quizgen/TypedArrayQuestion.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';
import PropTypes from 'prop-types';

import ArrayQuestion from './ArrayQuestion.jsx';
import ArrayInput from '../input/ArrayInput.jsx';

/*
* A TypedArrayQuestion component is a wrapper for an ArrayQuestion
* that provides type-based validation.
*
* Required Props
* 	String type - name of type (see TypedArrayQuestion.types)
*/
class TypedArrayQuestion extends React.Component {
	/* Possible types for array. */
	static types = {
		int: 'int',
		numeric: 'numeric',
		char: 'char',
		};

	render() {
		/* Render the component. */
		var validators = [];

		switch (this.props.type) {
			case TypedArrayQuestion.types.int:
				validators.push(ArrayInput.validators.integerArray);
				break;
			case TypedArrayQuestion.types.numeric:
				validators.push(ArrayInput.validators.numericArray);
				break;
			case TypedArrayQuestion.types.char:
				validators.push(ArrayInput.validators.charArray);
				break;
			default:
				break;
			}

		return (<ArrayQuestion validators={validators} {...this.props} />);
		}

	}

TypedArrayQuestion.propTyps = {
	type: PropTypes.string.isRequired,
	};

export default TypedArrayQuestion;
