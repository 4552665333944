/*
* pages/404.jsx
* Quizzera
* Author: Rushy Panchal
* Description: 404 page display.
*/

import React from 'react';

function PageNotFound() {
  return (<div className="container-fluid">
    <h2>404 | Page Not Found</h2>
    Is this an error on our part? We'll do our best to resolve this quickly.
  </div>);
  }

export default PageNotFound;
