/*
* IntervalSearchTreeExercise/IntervalSearchTreeExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import TypedArrayQuestion from '../../quizgen/web/questions/TypedArrayQuestion.jsx';

function IntervalSearchTreeExercise(props) {
  return (<TypedArrayQuestion type={TypedArrayQuestion.types.char} {...props} />);
}

export default IntervalSearchTreeExercise;
