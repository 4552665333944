/*
* pages/Dashboard/CreateCourseCard.jsx
* Quizzera
* Author: Rushy Panchal
* Description: Card to create courses.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import CourseEditor from 'components/models/CourseEditor.jsx';
import GenericCreationCard from 'components/GenericCreationCard.jsx';

class CreateCourseCard extends React.PureComponent {
  static propTypes = {
    onRedirect: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool,
    };

  static defaultProps = {
    error: '',
    loading: false,
    };

  constructor(props) {
    super(props);
    this.state = {
      course: {
        request: false,
        saved: true,
        data: null,
        },
      };

    this.onChange = this.onChange.bind(this);
    this.createCourse = this.createCourse.bind(this);
    }

  render() {
    return (
      <GenericCreationCard
        header='Create Course'
        editor={CourseEditor}
        error={this.props.error}
        item={merge(this.state.course, {request: this.props.loading})}
        onChange={this.onChange}
        onCreate={this.createCourse}
        description={'Create a new course. After doing so, quizzes and other'
         + ' content can be added.'}>
        <p>
          Fill in course details here. After saving, you will be
          redirected to the course's management page, where content, as
          well as enrolled users, can be added.
        </p>
        <hr/>
      </GenericCreationCard>);
    }

  onChange(course) {
    this.setState({course: course});
    }

  createCourse() {
    return this.props.onCreate(this.state.course.data);
    }
  }

export default CreateCourseCard;
