/*
* pages/student/question/Attempt.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: General attempt display.
*/

import "styles/custom.scss"

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

import CountdownClock from 'components/CountdownClock.jsx';
import APIErrorAlert from 'components/APIErrorAlert.jsx';

import FinishedAttempt from './FinishedAttempt';
import CurrentAttempt from './CurrentAttempt';

class Attempt extends React.PureComponent {
  static propTypes = {
    // Metadata
    metadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      max_attempts: PropTypes.number.isRequired,
      end_time: PropTypes.string.isRequired,
      read_only: PropTypes.bool.isRequired,
      finished_previous: PropTypes.bool.isRequired,
      }).isRequired,

    // Attempt
    data: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    used_attempts: PropTypes.number.isRequired,
    seed: PropTypes.number.isRequired,
    answer: PropTypes.string,
    score: PropTypes.number,
    max_score: PropTypes.number,

    // Callbacks
    onNewAttempt: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    // Misc.
    loading: PropTypes.bool,
    error: PropTypes.string,
    };

  static defaultProps = {
    answer: '',
    score: 0,
    max_score: 0,
    loading: false,
    error: ''
    };

  render() {
    return (<div>
      <APIErrorAlert msg={this.props.error} />
      <h2>{this.props.metadata.title}</h2>
      <h5>
        Seed: <span className='data-label'>{this.props.seed}</span>
        &nbsp;
        (Provider:&nbsp;
          <span className='data-label'>{this.props.metadata.provider}</span>)
      </h5>
      <Row>
        <Col xs={6}>
          <h4>
            Attempts Remaining:&nbsp;
            <span className='data-label'>
              {this.props.metadata.max_attempts - this.props.used_attempts}
             </span>
          </h4>
        </Col>
        <Col xs={6}>
          <h4 style={{float: 'right'}}>
            <CountdownClock
              future_msg='Quiz Ends in'
              past_msg='Quiz has ended'
              end_time={new Date(this.props.metadata.end_time)}
              className='data-label' />.
          </h4>
        </Col>
      </Row>

      <Button disabled={this.props.metadata.read_only
                        || ! this.props.metadata.finished_previous
                        || !this.props.finished
                        || this.props.loading}
              onClick={this.props.onNewAttempt}>
        New Attempt
      </Button>

      <br/><br/>
      {this.getView()}
      </div>);
    }

  getView() {
    if (!this.props.metadata.read_only && !this.props.finished) {
      return (<CurrentAttempt
        provider={this.props.metadata.provider}
        data={this.props.data}
        onSubmit={value => this.props.onSubmit(value, this.props.id)}
        />);
      }

    return (<FinishedAttempt
      provider={this.props.metadata.provider}
      data={this.props.data}
      answer={this.props.answer}
      score={this.props.score}
      max_score={this.props.max_score}
      />);
    }
  }

export default Attempt;
