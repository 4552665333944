/*
* components/ResourceLoader.tsx
* Author: Rushy Panchal
* Date: August 8th, 2019
* Description: Loads a resource from Redux, potentially cached.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import moment from 'moment';

import { connect } from 'react-redux';
import { actions, selectors } from 'state/resources';

class ResourceLoader extends React.PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      ]).isRequired,
    resource: PropTypes.elementType.isRequired,
    cached: PropTypes.bool,
    field: PropTypes.string,
    };

  static defaultProps = {
    cached: true,
    field: 'title'
    };

  componentDidMount() {
    const action = actions.loadResource(
      this.props.resource,
      Number(this.props.id),
      this.props.cached ? moment.duration({days: 1}) : undefined,
      );
    this.props.dispatch(action);
    }

  render() {
    return <span>{at(this.props.data, this.props.field)}</span>;
    }
  }

function mapStateToProps(state, ownProps) {
  const { resource, id } = ownProps;
  const resource_state = selectors.getResource(state, resource);

  return {
    data: selectors.getSingleResource(resource_state, id),
    };
  }

export default connect(mapStateToProps)(ResourceLoader);
