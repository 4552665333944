/*
* MemoryExercise/MemoryExercise.jsx
* Quizzera
* Author: Rushy Panchal
*/

import React from 'react';

import NumericQuestion from '../../quizgen/web/questions/NumericQuestion.jsx';

function MemoryExercise(props) {
  return (<NumericQuestion {...props} />);
}

export default MemoryExercise;
