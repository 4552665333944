/*
* questions/static/react/questions/quizgen/ArrayInputTree.jsx
* Quizzera
* Author: Rushy Panchal
* Date: February 22nd, 2018
* Description: A wrapper around GenericQuizgenQuestion with a rendered tree and
*   an array-based input that accepts integers as input.
*/

import React from 'react';

import GenericQuizgenQuestion from './GenericQuizgenQuestion.jsx';
import ArrayInput from '../input/ArrayInput.jsx';

import { Tree } from '../visuals/Tree.jsx';

class ArrayInputTree extends React.Component {
  render() {
    return (<GenericQuizgenQuestion
      input={ArrayInput} inputData={true}
      validators={new Array(ArrayInput.validators.integerArray)}
      prompt={Tree}
      {...this.props} />);
    }
  }

export default ArrayInputTree;
