/*
* components/HelpIcon.jsx
* Author: Rushy Panchal
* Date: August 2nd, 2019
* Description: Displays an icon with a tooltip.
*/

import React from 'react';

import HoverTooltip from 'components/HoverTooltip';
import Icon from 'components/Icon';

class HelpIcon extends React.PureComponent {
  render() {
    return (<HoverTooltip tooltip={this.props.children}>
      <Icon i='ios-information-outline' className='info' />
    </HoverTooltip>);
    }
  }

export default HelpIcon;
