/*
* pages/student/course/CourseController.jsx
* Quizzera
* Author: Rushy Panchal
* Date: July 23rd, 2019
* Description: Controller for course detail view.
*/

import React from 'react';
import { connect } from 'react-redux';

import { Quiz, Extension, CurrentUser } from 'lib/api';
import { actions, selectors, types } from 'state/resources';
import CoursePage from './CoursePage';

const QUIZ_LIST_KEY = 'student-quiz-list';
const EXTENSION_LIST_KEY = 'student-extension-list';

class CourseController extends React.Component {
  render() {
    return <CoursePage {...this.props} />
    }

  componentDidMount() {
    const user = new CurrentUser();
    const course_id = this.props.match.params.course;

    this.props.dispatch(actions.loadResourceList(
      Quiz, QUIZ_LIST_KEY, {course_id: course_id},
      ));

    const ext = new Extension();
    this.props.dispatch(actions.resolvePromise(
      ext.latest({quiz__course_id: course_id, user_id: user.id}),
      'extension',
      EXTENSION_LIST_KEY,
      types.ACTIONS.LOAD_RESOURCE_LIST,
      ));
    }
  }

function mapStateToProps(state, ownProps) {
  const course_id = Number(ownProps.match.params.course);
  const quizzes = selectors.getResource(state, Quiz);
  const extensions = selectors.getResource(state, Extension);

  const quizPending = selectors.getPending(quizzes, QUIZ_LIST_KEY);
  const extPending = selectors.getPending(extensions, EXTENSION_LIST_KEY);

  let extensionMap = {};
  selectors
    .filterResource(extensions)
    .forEach(e => extensionMap[e.quiz_id] = e);

  return {
    quizzes: selectors.filterResource(quizzes, {course_id: course_id}),
    extensions: extensionMap,
    loading: quizPending.loading || extPending.loading,
    error: quizPending.error || extPending.error || '',
    };
  }

export default connect(mapStateToProps)(CourseController);
